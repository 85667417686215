import { Observable, BehaviorSubject } from 'rxjs'
import { Injectable } from '@angular/core'

export type RaportinVertailusarakeVirallinenTulos = 'koko-tilikausi' | 'osatilikausi'

@Injectable()
export class KirjanpitoRaportitVertailuTiedotService {

  private _vertailuTiedotAjanJaksoSubject = new BehaviorSubject<RaportinVertailusarakeVirallinenTulos>('koko-tilikausi')
  vertailuTiedotAjanJaksoObservable: Observable<RaportinVertailusarakeVirallinenTulos> = this._vertailuTiedotAjanJaksoSubject.asObservable()

  constructor() { }

  setVertailuTiedotKokoTiliKaudenMukaan() {
    this._vertailuTiedotAjanJaksoSubject.next('koko-tilikausi')
  }

  setVertailuTiedotOsatilikaudenMukaan() {
    this._vertailuTiedotAjanJaksoSubject.next('osatilikausi')
  }

}