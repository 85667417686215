import { Injectable } from '@angular/core'
import { AsiakasJaettuService } from '../_jaettu-lemonator/service/asiakas-jaettu.service'
import { DateService } from '../_shared-core/service/date.service'
import { KirjanpitoJaettuService } from '../_jaettu-lemonator/service/kirjanpito-jaettu.service'
import { CurrencyService } from '../_shared-core/service/currency.service'
import { KirjanpitoUriService } from '../_jaettu-lemonator/service/kirjanpito-uri.service'
import { TilikarttaJaettuService } from '../_jaettu-lemonator/service/tilikartta-jaettu.service'
import { YllapitoUriService } from '../_jaettu-lemonator/service/yllapito-uri.service'
import { PyhapaivatService } from '../_shared-core/service/pyhapaiva.service'
import { TyoajanseurantaService } from '../_jaettu-lemonator/service/tyoajanseuranta.service'
import { AsiakasUriService } from 'app/_jaettu-lemonator/service/asiakas-uri.service'
import { VeroilmoitusTranslationService } from '../_jaettu-lemonator/service/veroilmoitus/veroilmoitus-translation.service'
import { KirjanpitajanAllekirjoitusService } from 'app/_jaettu-lemonator/service/kirjanpitajan-allekirjoitus.service'
import { BitService } from 'app/_jaettu/service/bit.service'
import { KirjanpitajanToimipisteService } from '../_jaettu-lemonator/service/kirjanpitajan-toimipiste.service'
import { HolviSharedUriService } from 'app/_jaettu-lemonator/service/holvi-shared-uri.service'
import { TilinpaatosTranslationService } from 'app/_jaettu-lemonator/service/tilinpaatos-translation.service'
import { TilinpaatosLiitetiedotJaettuService } from 'app/_jaettu-lemonator/service/tilinpaatos-liitetiedot-jaettu.service'
import { KirjanpitajanTiimiService } from 'app/_jaettu-lemonator/service/kirjanpitajan-tiimi.service'
import { KopioijaPalvelu } from 'app/_jaettu/service/kopioija.service'
import { AsiakkaanSopimuskausiService } from 'app/_jaettu-lemonator/service/sopimuskausi.service'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'
import { ArkistoService } from 'app/_jaettu-lemonator/service/arkisto.service'
import { EmailTemplatesUriService } from 'app/_jaettu-lemonator/service/email-templates-uri.service'
import { BankConsentUriService } from 'app/_jaettu/service/bank-consent-uri.service'
import { VahvistettuKorkoService } from 'app/_jaettu/service/vahvistettu-korko.service'
import { KirjanpitoReskontraUriService } from 'app/_jaettu-lemonator/service/kirjanpito-reskontra-uri.service'
import { TilinpaatosUsersService } from 'app/_jaettu-lemonator/service/tilinpaatos-users.service'
import { StringService } from 'app/_shared-core/service/string.service'
import { TranslationService } from 'app/_jaettu/service/translation.service'
import { VeroilmoitusLaskentaService } from 'app/_jaettu-lemonator/service/veroilmoitus/veroilmoitus-laskenta.service'
import { CodeCheckService } from 'app/_shared-core/service/code-check.service'


@Injectable() export class KirjanpitajanTiimiServiceAngular extends KirjanpitajanTiimiService {
  constructor(
    dateServic: DateService,
    copyService: KopioijaPalvelu
  ) {
    super(dateServic, copyService)
  }
}
@Injectable() export class BitServiceAngular extends BitService {
  constructor() {
    super()
  }
}

@Injectable() export class AsiakasUriServiceAngular extends AsiakasUriService {
  constructor() {
    super()
  }
}

@Injectable() export class AsiakasJaettuServiceAngular extends AsiakasJaettuService {
  constructor(
    dateService: DateService,
    currencyService: CurrencyService,
    sopimuskausiService: AsiakkaanSopimuskausiService
  ) {
    super(dateService, currencyService, sopimuskausiService)
  }
}

@Injectable() export class KirjanpitoJaettuServiceAngular extends KirjanpitoJaettuService {
  constructor(
    currencyService: CurrencyService,
    dateService: DateService,
    asiakasJaettuService: AsiakasJaettuService,
    pyhapaivatService: PyhapaivatService,
    translationService: TranslationService,
    tilikarttaJaettuService: TilikarttaJaettuService
  ) {
    super(currencyService, dateService, asiakasJaettuService, pyhapaivatService, translationService, tilikarttaJaettuService)
  }
}

@Injectable() export class KirjanpitoUriServiceAngular extends KirjanpitoUriService {
  constructor() {
    super()
  }
}

@Injectable() export class TilikarttaJaettuServiceAngular extends TilikarttaJaettuService {
  constructor(
    kopioijaPalvelu: KopioijaPalvelu
  ) {
    super(kopioijaPalvelu)
  }
}

@Injectable() export class YllapitoUriServiceAngular extends YllapitoUriService {
  constructor() {
    super()
  }
}
@Injectable() export class PyhapaivatServiceAngular extends PyhapaivatService {
  constructor(
    dateService: DateService
  ) {
    super(dateService)
  }
}
@Injectable() export class TyoajanseurantaServiceAngular extends TyoajanseurantaService {
  constructor(
    dateService: DateService,
    pyhapaivatService: PyhapaivatService,
    currencyService: CurrencyService
  ) {
    super(dateService, pyhapaivatService, currencyService)
  }
}
@Injectable() export class VeroilmoitusTranslationServiceAngular extends VeroilmoitusTranslationService {
  constructor() {
    super()
  }
}
@Injectable() export class KirjanpitajanToimipisteServiceAngular extends KirjanpitajanToimipisteService {
  constructor() {
    super()
  }
}

@Injectable() export class KirjanpitajanAllekirjoitusServiceAngular extends KirjanpitajanAllekirjoitusService {
  constructor(
    kirjanpitajanToimipisteService: KirjanpitajanToimipisteService
  ) {
    super(kirjanpitajanToimipisteService)
  }
}
@Injectable() export class HolviSharedUriServiceAngular extends HolviSharedUriService {
  constructor() {
    super()
  }
}

@Injectable() export class TilinpaatosTranslationServiceAngular extends TilinpaatosTranslationService {
  constructor() {
    super()
  }
}


@Injectable() export class TilinpaatosLiitetiedotJaettuServiceAngular extends TilinpaatosLiitetiedotJaettuService {
  constructor(
    dateService: DateService,
    currencyService: CurrencyService,
    timestampService: TimestampService
  ) {
    super(dateService, currencyService, timestampService)
  }
}

@Injectable() export class AsiakkaanSopimuskausiServiceAngular extends AsiakkaanSopimuskausiService {
  constructor(
    dateService: DateService,
    timestampService: TimestampService
  ) {
    super(dateService, timestampService)
  }
}

@Injectable() export class ArkistoServiceAngular extends ArkistoService {
  constructor(
  ) {
    super()
  }
}

@Injectable() export class EmailTemplatesUriServiceAngular extends EmailTemplatesUriService {
  constructor(
  ) {
    super()
  }
}

@Injectable() export class BankConsentUriServiceAngular extends BankConsentUriService {
  constructor(
  ) {
    super()
  }
}

@Injectable() export class VahvistettuKorkoServiceAngular extends VahvistettuKorkoService {
  constructor(
    dateService: DateService,
  ) {
    super(dateService)
  }
}



@Injectable() export class KirjanpitoReskontraUriServiceAngular extends KirjanpitoReskontraUriService {
  constructor(
  ) {
    super()
  }
}

@Injectable() export class TilinpaatosUsersServiceAngular extends TilinpaatosUsersService {
  constructor(
    stringService: StringService,
    translationService: TranslationService
  ) {
    super(stringService, translationService)
  }
}

@Injectable() export class VeroilmoitusLaskentaServiceAngular extends VeroilmoitusLaskentaService {
  constructor(
    dateService: DateService,
    currencyService: CurrencyService,
    codeCheckService: CodeCheckService,
    asiakasJaettuService: AsiakasJaettuService
  ) {
    super(dateService, currencyService, codeCheckService, asiakasJaettuService)
  }
}

