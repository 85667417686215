import { Component, OnInit } from '@angular/core'
import { AsiakasTyojonoLoadingService } from 'app/_angular/service/asiakas/asiakas-tyojono-loading.service'
import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'
import { Asiakas } from 'app/_jaettu-lemonator/model/asiakas'
import { Observable } from 'rxjs'

@Component({
  templateUrl: './asiakas-tyojono-loading-details.component.html'
})
export class AsiakasTyojonoLoadingDetailsComponent implements OnInit {

  asiakasObservable: Observable<Asiakas>
  tyojonolatauksetKeskenCountObservable: Observable<string[]>

  constructor(
    private _asiakasService: AsiakasService,
    private _asiakasTyojonoLoadingService: AsiakasTyojonoLoadingService
  ) {
    this.asiakasObservable = this._asiakasService.nykyinenAsiakasObservable
  }

  ngOnInit(): void {
    this.tyojonolatauksetKeskenCountObservable = this._asiakasTyojonoLoadingService.tyojonolatauksetKeskenCountObservable
  }

}