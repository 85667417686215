import { ErrorHandler, Injectable, OnDestroy } from '@angular/core'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'

import { FirestoreTositteenAlkuperainenTiedosto, FirestoreTositteenKuva, FirestoreTosite } from '../../_jaettu/model/tosite'
import { getPdfRenderScale } from '../../_jaettu/utils/pdf'
import { VirtuaaliviivakoodinOsat, ViitenumeroService } from '../../_shared-core/service/viitenumero.service'
import { PDFiumDocument, PDFiumLibrary, PDFiumPage } from '@lemontree/pdfium-wasm'

import { Observable, ReplaySubject } from 'rxjs'
import { StringService } from '../../_shared-core/service/string.service'

export interface PdfConversionJobSettingsWasm {
  document: PDFiumDocument
}

export interface SivuPdfsta {
  numero: number
  aloitaKuvanLataaminen(): Promise<string>
}

export interface FirestoreTositteenMuutosKuvaMuokkaus extends FirestoreTositteenKuva {
  lokaalikuva: File | null
  nakyvissa: boolean
  ladataan: boolean
  kuvanUrlObservable: ReplaySubject<SafeUrl>
  annaKuvanObservable(): Observable<SafeUrl>
}

export interface FirestoreTositteenMuutosMuokkaus extends FirestoreTosite {
  kuvat: { [key: string]: FirestoreTositteenMuutosKuvaMuokkaus }
}

@Injectable()
export class PdfService implements OnDestroy {

  private _library: Promise<PDFiumLibrary>
  private webpSupported = false

  constructor(
    private errorHandler: ErrorHandler,
    private _domSanitizer: DomSanitizer,
    private viitenumeroService: ViitenumeroService,
    private _stringService: StringService
  ) {
    this._library = PDFiumLibrary.init()
    this.webpSupported = this.isWebpSupported()
  }

  ngOnDestroy(): void {
    if (this._library) {
      this._library.then(lib => {
        lib.destroy()
        this._library = null
      })
    }
  }

  public async yritaParsiaPdf(fileAsUint8Array: Uint8Array): Promise<PdfConversionJobSettingsWasm | null> {
    try {
      // const startInit = Date.now()
      const library = await this._library
      // console.log('Took ' + (Date.now() - startInit) + ' ms to init wasm library.')

      // const startDocumentLoad = Date.now()
      const document = await library.loadDocument(fileAsUint8Array)
      // console.log('Took ' + (Date.now() - startDocumentLoad) + ' ms to to load document.')

      return {
        document: document
      }
    } catch (error) {
      // console.error(error)
      this.errorHandler.handleError(error)
      return null
    }
  }

  public hoidaPdfnRajayttaminenSivuiksi(settings: PdfConversionJobSettingsWasm, kuvienTargetWidth: number): SivuPdfsta[] {

    const sivut: SivuPdfsta[] = []
    for (let kuvanIndeksi = 1; kuvanIndeksi <= settings.document.getPageCount(); kuvanIndeksi++) {
      // console.log('Start render page at width:', kuvienTargetWidth)
      let promise: Promise<string | null> = null
      const sivu: SivuPdfsta = {
        numero: kuvanIndeksi,
        aloitaKuvanLataaminen: (): Promise<string> => {
          if (promise === null) {
            // console.log('Pyydetty kuva ' + kuvanIndeksi)
            const page = settings.document.getPage(kuvanIndeksi)
            promise = this.renderPageToDataUrl(page, kuvienTargetWidth)
            promise.then(() => page.destroy()).catch((err) => { page.destroy(); throw err })
          }
          return promise
        }
      }
      sivut.push(sivu)
    }

    return sivut

  }

  public hoidaPdfnRajayttaminenKuviksi(settings: PdfConversionJobSettingsWasm, alkuperainen: FirestoreTositteenAlkuperainenTiedosto, kuvienTargetWidth: number): FirestoreTositteenMuutosKuvaMuokkaus[] {

    const kasitellytKuvat: FirestoreTositteenMuutosKuvaMuokkaus[] = []
    for (let kuvanIndeksi = 1; kuvanIndeksi <= settings.document.getPageCount(); kuvanIndeksi++) {

      const observable = new ReplaySubject<SafeUrl>(1)
      let promise: Promise<any> = null
      const muutosKuva: FirestoreTositteenMuutosKuvaMuokkaus = {
        avain: alkuperainen.avain + '_' + kuvanIndeksi,
        jarjestys: kuvanIndeksi,
        lokaalikuva: null,
        poistettu: false,
        ladataan: true,
        nakyvissa: false,
        kuvanUrlObservable: observable,
        type: 'jpg',
        alkuperaisenAvain: alkuperainen.avain,
        annaKuvanObservable: () => {
          if (promise === null) {
            // console.log('Pyydetty kuva ' + kuvanIndeksi)
            const page = settings.document.getPage(kuvanIndeksi)
            promise = this.renderPageToDataUrl(page, kuvienTargetWidth).then(dataUrl => {
              muutosKuva.kuvanUrlObservable.next(dataUrl)
              muutosKuva.ladataan = false
              muutosKuva.nakyvissa = true
              page.destroy()
            }).catch(err => {
              this.errorHandler.handleError(err)
              muutosKuva.kuvanUrlObservable.next('/assets/noimage.png')
              muutosKuva.ladataan = false
              muutosKuva.nakyvissa = true
              page.destroy()
            })
          }
          return observable
        }
      }
      kasitellytKuvat.push(muutosKuva)

    }

    return kasitellytKuvat

  }

  private isWebpSupported(): boolean {
    try {
      const elem = document.createElement('canvas')
      if (!!(elem.getContext && elem.getContext('2d'))) {
        return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0
      }
      return false
    } catch (err) {
      console.error(err)
      return false
    }
  }

  public renderPageToDataUrl(page: PDFiumPage, kuvienTargetWidth: number): Promise<string> {

    // const startInit = Date.now()
    const info = page.getSize()
    const scale = getPdfRenderScale(kuvienTargetWidth, 2, info.width)
    // console.log('SCALE', scale)
    const render = page.render(scale)

    const imageData = new ImageData(new Uint8ClampedArray(render.bitmap), render.width, render.height)

    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    canvas.width = render.width
    canvas.height = render.height
    ctx.putImageData(imageData, 0, 0)
    const blob = this._canvasToBlob(canvas)
    // console.log('Took to ' + (Date.now() - start) + 'ms render')
    return blob

  }

  private _canvasToBlob(canvas: HTMLCanvasElement): Promise<string> {
    return new Promise((resolve, reject) => {
      try {

        const mimeType = this.webpSupported ? 'image/webp' : 'image/png'

        if (canvas.toBlob) {
          canvas.toBlob(blob => { resolve(this._domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob)) as string) }, mimeType, 100)
        } else if (canvas.toDataURL) {
          const asDataUrl = this._domSanitizer.bypassSecurityTrustResourceUrl(canvas.toDataURL(mimeType, 100))
          resolve(asDataUrl as string)
        } else {
          reject(new Error('The browser does NOT support HTMLCanvasElement.toBlob() or HTMLCanvasElement.toDataUrl()!!'))
        }

        // This MS specific version does not support quality, so don't use it.
        // Ie and old edge will use toDataUrl method.
        // else if (canvas['msToBlob']) { // IE
        //   resolve((canvas as any).msToBlob())
        // }

      } catch (err) {
        reject(err)
      }
    })
  }

  public async etsiViivakoodiPdfsta(settingsWasm: PdfConversionJobSettingsWasm): Promise<VirtuaaliviivakoodinOsat | null> {
    const virtuaaliviivakoodiRegexp = /([0-9]{20,99})/g
    for (const page of settingsWasm.document.pages()) {
      const str = page.getText()
      const words = str?.replace(/\s+/g, ' ')?.split(' ') ?? []
      for (const word of words) {
        let match: RegExpExecArray | null = null
        while (match = virtuaaliviivakoodiRegexp.exec(word)) {
          if (match.length > 1) {
            const purettuViivakoodi = this.viitenumeroService.parsiSuomalainenVirtuaaliviivakoodi(match[1])
            if (purettuViivakoodi && purettuViivakoodi.summa) {
              return purettuViivakoodi
            }
          }
        }
      }
    }
    return null
  }

  public async extractAllText(settingsWasm: PdfConversionJobSettingsWasm): Promise<string[]> {
    const texts: string[] = []
    for (const page of settingsWasm.document.pages()) {
      texts.push(this._stringService.normalizeWhitespace(page.getText() ?? ''))
    }
    return texts
  }

  public async extractTextForPage(pageNumber: number, settingsWasm: PdfConversionJobSettingsWasm): Promise<string> {
    return this._stringService.normalizeWhitespace(settingsWasm.document.getPage(pageNumber - 1).getText() ?? '')
  }

}
