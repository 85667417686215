import { Component, OnDestroy, ViewEncapsulation, Output, EventEmitter, OnInit } from '@angular/core'
import { FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { MatSelectChange } from '@angular/material/select'
import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'
import { Tilikausi } from 'app/_jaettu-lemonator/model/asiakas'
import { KirjanpitoUriService } from 'app/_jaettu-lemonator/service/kirjanpito-uri.service'
import { Timestamp } from 'app/_shared-core/model/common'
import { DateService } from 'app/_shared-core/service/date.service'

import { BehaviorSubject, combineLatest, filter, map, Observable, of, Subject, switchMap, takeUntil } from 'rxjs'

import { KlikattuKirjaus } from './raportit.component'
import { TilinpaatosStatus } from 'app/_jaettu-lemonator/model/tilinpaatos'
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator'
import { TilinpaatosLiitetiedot } from 'app/_jaettu-lemonator/model/kirjanpito'

export type Valilehti = 'aloitus' | 'tase-erittely' | 'liitetiedot' | 'tilintarkastus' | 'rekisterointi' | 'lahetys'

interface ViewSettings {
  valilehti: Valilehti
  tilinpaatosNotStarted: boolean
}

@Component({
  selector: '[app-kirjanpito-tilinpaatos]',
  templateUrl: './tilinpaatos.component.html',
  styleUrls: ['./tilinpaatos.component.css'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class KirjanpitoTilinpaatosComponent implements OnInit, OnDestroy {

  @Output() kirjaustaKlikattiin: EventEmitter<KlikattuKirjaus> = new EventEmitter()

  private _ngUnsubscribe = new Subject<void>()
  private _selectedTilikausiSubject: BehaviorSubject<Tilikausi> = new BehaviorSubject(null)
  private _viewSettingsSubject: BehaviorSubject<ViewSettings> = new BehaviorSubject({ tilinpaatosNotStarted: true, valilehti: null })

  viewSettingsObservable: Observable<ViewSettings> = this._viewSettingsSubject.asObservable()
  selectedTilikausiObservable: Observable<Tilikausi> = this._selectedTilikausiSubject.asObservable()

  paivitaArvotHiljaisestiObservable: Observable<number>
  tilikaudetObservable: Observable<Tilikausi[]>
  tilinpaatosStatusObservable: Observable<TilinpaatosStatus>
  liitetiedotObservable: Observable<TilinpaatosLiitetiedot>

  constructor(
    private _asiakasService: AsiakasService,
    private _dateService: DateService,
    private _kirjanpitoUriService: KirjanpitoUriService,
    private _firebase: FirebaseLemonator,
  ) {
    this.tilinpaatosStatusObservable = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, this.selectedTilikausiObservable]).pipe(
      switchMap(([asiakas, tilikausi]) => {
        if (asiakas && tilikausi) {
          const uri = 'asiakkaat/' + asiakas.avain + '/tilinpaatos-status/' + tilikausi.avain
          return this._firebase.firestoreDoc<TilinpaatosStatus>(uri).listen()
        }
        return of<TilinpaatosStatus>(null)
      }),
      lemonShare()
    )
  }

  ngOnInit() {

    const latestRegisteredTpObservable: Observable<{ status: TilinpaatosStatus | null } | null> = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (asiakas) {
          const uri = 'asiakkaat/' + asiakas.avain + '/tilinpaatos-status'
          return this._firebase.firestoreCollection<TilinpaatosStatus>(uri)
            .orderBy('rekisteroity', 'desc')
            .limit(1)
            .get()
            .then(latest => {
              if (latest?.length > 0) {
                return { status: latest[0] }
              }
              return { status: null }
            })
        }
        // return null to signal we didn't query yet
        return of<{ status: TilinpaatosStatus }>(null)
      }),
      lemonShare()
    )

    this.tilikaudetObservable = this._asiakasService.nykyisenAsiakkaanTilikaudetObservable

    combineLatest([latestRegisteredTpObservable, this.tilikaudetObservable]).pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(([viimeisinRekisteroity, tilikaudet]) => {
      if (viimeisinRekisteroity && tilikaudet?.length) {
        if (!this._selectedTilikausiSubject.value) {

          // Etsi viimeisintä rekisteröityä seuraava tilikausi
          if (viimeisinRekisteroity.status) {
            const tilikausi = tilikaudet.find(t => t.avain === viimeisinRekisteroity.status.tilikausiAvain)
            if (tilikausi) {
              this._setTilikausi(tilikaudet.find(t => t.loppuu.year === tilikausi.loppuu.year + 1))
            }
          }

          if (!this._selectedTilikausiSubject.value) {
            // Etsi edellisen vuoden tilikausi
            const year = new Date().getFullYear() - 1
            this._setTilikausi(tilikaudet.find(t => t.loppuu.year === year))
            // Etsi ensimmäinen ei lukittu tilikausi
            // this._setTilikausi(tilikaudet.find(t => !t.lukittu))
          }

          if (!this._selectedTilikausiSubject.value) {
            // Ota viimeisin tilikausi
            this._setTilikausi(tilikaudet[tilikaudet.length - 1])
          }

        }
      }
    })

    // This refreshes the tilikausi if it changes.
    this.tilikaudetObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(tilikaudet => {
      if (tilikaudet && this._selectedTilikausiSubject.value) {
        for (const tk of tilikaudet) {
          if (tk.avain === this._selectedTilikausiSubject.value.avain) {
            this._selectedTilikausiSubject.next(tk)
            break
          }
        }
      }
    })

    this.paivitaArvotHiljaisestiObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (!asiakas) {
          return of<{ u: Timestamp }>(null)
        }
        const raporttienUri = this._kirjanpitoUriService.annaRaportointikirjauksetPaivitettyUri(asiakas.avain)
        return this._firebase.firestoreDoc<{ u: Timestamp }>(raporttienUri).listen()
      }),
      map(doc => doc?.u?.toMillis() || Date.now())
    )

    const liitetiedotJaTilikausiObservable: Observable<{ tilikausi: Tilikausi, liitetiedot: TilinpaatosLiitetiedot }> = combineLatest([
      this._asiakasService.nykyinenAsiakasAvainObservable,
      this.selectedTilikausiObservable
    ]).pipe(
      switchMap(([asiakastiedot, tilikausi]) => {
        if (!asiakastiedot?.avain || !tilikausi) {
          return of(null)
        }
        const currentUri = this._kirjanpitoUriService.annaTilinpaatosLiitetiedotUri(asiakastiedot.avain, tilikausi)
        return this._firebase.firestoreDoc<TilinpaatosLiitetiedot>(currentUri).listen().pipe(
          map(liitetiedot => {
            if (liitetiedot) {
              if (!liitetiedot.valitut) { liitetiedot.valitut = {} }
              if (!liitetiedot.tekstit) { liitetiedot.tekstit = {} }
              if (!liitetiedot.numerot) { liitetiedot.numerot = {} }
              if (!liitetiedot.osionLisarivit) { liitetiedot.osionLisarivit = {} }
            }
            return {
              tilikausi: tilikausi,
              liitetiedot: liitetiedot
            }
          })
        )
      })
    )

    this.liitetiedotObservable = liitetiedotJaTilikausiObservable.pipe(
      map(liitetiedotJaTilikausi => liitetiedotJaTilikausi?.liitetiedot ?? null)
    )

    const tilinpaatosStartedObservable = liitetiedotJaTilikausiObservable.pipe(
      // Filter away any null or undefined emits
      filter(liitetiedotJaTilikausi => liitetiedotJaTilikausi !== null && liitetiedotJaTilikausi !== undefined),
      map(liitetiedotJaTilikausi => {
        if (!liitetiedotJaTilikausi.tilikausi.tilinpaatosStarted) {
          return false
        }
        const liitetiedot = liitetiedotJaTilikausi.liitetiedot
        if (!liitetiedot) {
          return true
        }
        /** Special fallback case for customers whose tilinpäätös was started BEFORE the Aloitus tab was added on 27.10.2024 */
        return liitetiedot.tilintarkastetaan !== undefined &&
          liitetiedot.tilintarkastetaan !== null &&
          liitetiedot.allekirjoitetaanLemonaidissa !== undefined &&
          liitetiedot.allekirjoitetaanLemonaidissa !== null
      })
    )

    tilinpaatosStartedObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(tilinpaatosStarted => {

      let change = false
      if (this._viewSettingsSubject.value.tilinpaatosNotStarted !== !tilinpaatosStarted) {
        this._viewSettingsSubject.value.tilinpaatosNotStarted = !tilinpaatosStarted
        change = true
      }

      // Set only if not set yet
      if (!this._viewSettingsSubject.value.valilehti) {
        this._viewSettingsSubject.value.valilehti = tilinpaatosStarted ? 'tase-erittely' : 'aloitus'
        change = true
      }

      if (change) {
        this._viewSettingsSubject.next(this._viewSettingsSubject.value)
      }

    })

  }

  setTilikausiEvent(event: MatSelectChange) {
    this._setTilikausi(event.value as Tilikausi)
  }

  private _setTilikausi(tilikausi: Tilikausi) {
    if (
      tilikausi &&
      (
        !this._selectedTilikausiSubject.value ||
        this._dateService.compareLocalDates(tilikausi.loppuu, '!=', this._selectedTilikausiSubject.value.loppuu)
      )
    ) {
      // Set to null so that the logic will re-calculate the default tab
      this.setSelectedTab(null)
      this._selectedTilikausiSubject.next(tilikausi)
    }
  }


  compareTilikaudet(o1: Tilikausi, o2: Tilikausi): boolean {
    return o1?.avain === o2?.avain
  }

  setSelectedTab(tab: Valilehti) {
    if (this._viewSettingsSubject.value.valilehti !== tab) {
      this._viewSettingsSubject.value.valilehti = tab
      this._viewSettingsSubject.next(this._viewSettingsSubject.value)
    }
  }

  emitKirjausData(klikattu: KlikattuKirjaus) {
    if (klikattu) {
      this.kirjaustaKlikattiin.emit(klikattu)
    }
  }

  jatkaTilinpaatokseen() {

    let change = false
    if (this._viewSettingsSubject.value.tilinpaatosNotStarted !== false) {
      this._viewSettingsSubject.value.tilinpaatosNotStarted = false
      change = true
    }

    // Set only if not set yet
    if (this._viewSettingsSubject.value.valilehti !== 'tase-erittely') {
      this._viewSettingsSubject.value.valilehti = 'tase-erittely'
      change = true
    }

    if (change) {
      this._viewSettingsSubject.next(this._viewSettingsSubject.value)
    }

  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
