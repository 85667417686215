import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { AsiakasService } from 'app/_angular/service/asiakas/asiakas.service'
import { DebugService } from 'app/_angular/service/debug.service'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { Asiakas } from 'app/_jaettu-lemonator/model/asiakas'
import { Observable, Subject, combineLatest } from 'rxjs'
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators'

@Component({
  templateUrl: './ajopaivakirja-container.component.html'
})
export class AjopaivakirjaContainerComponent implements OnInit, AfterViewInit, OnDestroy {

  kirjanpitajaOnDevaajaObservable: Observable<boolean>
  ulkopuolisetKulutUriObservable: Observable<string>
  ulkopuolisetKulutEncodedUriObservable: Observable<string>

  constructor(
    private _asiakasService: AsiakasService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _debugService: DebugService
  ) { }

  selectedYear: number

  yearRange = [2019, 2020, 2021, 2022, 2023, 2024]
  yearControl = new FormControl<number>(0)
  asiakas: Asiakas
  private ngUnsubscribe: Subject<void> = new Subject<void>()

  ngOnInit() {

    // Debug thingies
    this.kirjanpitajaOnDevaajaObservable = this._kirjautunutKayttajaService.kirjanpitajaOnDevaajaObservable
    this.ulkopuolisetKulutUriObservable = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, this.yearControl.valueChanges]).pipe(
      map(([avainTiedot, vuosi]) => {
        if (avainTiedot?.avain) {
          if (vuosi === 2019) {
            return 'customers/' + avainTiedot.avain + '/customer-ajopaivakirja/' + avainTiedot.avain + '_' + vuosi
          }
          return 'customers/' + avainTiedot.avain + '/customer-ulkopuoliset-kulut/' + avainTiedot.avain + '_' + vuosi
        }
        return ''
      })
    )
    this.ulkopuolisetKulutEncodedUriObservable = this.ulkopuolisetKulutUriObservable.pipe(
      map(uri => {
        return this._debugService.createFirestoreLinkLemonaid(uri)
      })
    )

    this._asiakasService.nykyinenAsiakasObservable.pipe(
      distinctUntilChanged((a, b) => a?.avain === b?.avain),
    ).subscribe(asiakas => {
      if (asiakas) {
        this.asiakas = asiakas
      }
    })

    this.yearControl.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(selectedYear => {
      this.selectedYear = selectedYear
    })

  }

  ngAfterViewInit() {
    this.yearControl.setValue(this.yearRange[this.yearRange.length - 1])
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }
}
