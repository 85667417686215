import { Injectable } from '@angular/core'
import { TilitapahtumanTuontityodataJaAsiakasTiedot } from 'app/_jaettu/model/tiliote';
import { combineLatest, map, Observable, of, startWith, switchMap } from 'rxjs';
import { FirebaseLemonaid, FirebaseLemonator } from '../firebase-lemonator.service';
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator';
import { AsiakasService } from './asiakas.service';
import { EraajotYksiKerrallaanSuorittajaData } from 'app/_jaettu/model/eraajot';
import { TimestampProviderBase } from 'app/_shared-core/service/timestamp-provider.interface';
import { KirjanpitoService } from '../kirjanpito/kirjanpito.service';

@Injectable()
export class AsiakasTyojonoLoadingService {

  lemontreeAutomaattiReskontraLatauksiaObservable: Observable<boolean>
  lemontreeAutomaattiReskontraLatauksiaCountObservable: Observable<number>

  holviZipKasittelemattaObservable: Observable<boolean>
  holviApiKasittelemattaObservable: Observable<boolean>
  tilitapahtumiaKasittelemattaObservable: Observable<boolean>
  holviZipDriverExistsObservable: Observable<boolean>
  holviApiDriverExistsObservable: Observable<boolean>

  holviKasittelemattomiaLatauksiaObservable: Observable<boolean>
  holviKasittelemattomiaLatauksiaCountObservable: Observable<number>

  tyojonolatauksetKeskenObservable: Observable<boolean>
  tyojonolatauksetKeskenCountObservable: Observable<string[]>

  constructor(
    private _firebaseLemonaid: FirebaseLemonaid,
    private _firebase: FirebaseLemonator,
    private _asiakasService: AsiakasService,
    private _timestampProvider: TimestampProviderBase,
    private _kirjanpitoService: KirjanpitoService
  ) {

    this.holviApiKasittelemattaObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (asiakas) {
          return this._firebase.firestoreCollection<any>('tyojono_data/' + asiakas.avain + '/holvi_api_import')
            .where('aloitettu', '<=', this._timestampProvider.dateToTimestamp(new Date(Date.now() - 1000 * 60 * 2)))
            .limit(1)
            .listenSnapshots()
            .pipe(
              map(snapshots => snapshots?.length > 0)
            )
        }
        return of(false)
      }),
      startWith(false),
      lemonShare()
    )

    this.holviZipKasittelemattaObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (asiakas) {
          return this._firebase.firestoreCollection<any>('tyojono_data/' + asiakas.avain + '/kirjanpito_holvi_import')
            .where('luotu', '<=', this._timestampProvider.dateToTimestamp(new Date(Date.now() - 1000 * 60 * 2)))
            .limit(1)
            .listenSnapshots()
            .pipe(
              map(snapshots => snapshots?.length > 0)
            )
        }
        return of(false)
      }),
      startWith(false),
      lemonShare()
    )

    this.tilitapahtumiaKasittelemattaObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (asiakas) {
          return this._firebaseLemonaid.firestoreCollection<any>('tyojono_data/' + asiakas.avain + '/tilitapahtuman_tuonti')
            .where('lisatty', '<=', this._timestampProvider.dateToTimestamp(new Date(Date.now() - 1000 * 60 * 2)))
            .limit(1)
            .listenSnapshots()
            .pipe(
              map(snapshots => snapshots?.length > 0)
            )
        }
        return of(false)
      }),
      startWith(false),
      lemonShare()
    )


    this.holviApiDriverExistsObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (!asiakas) {
          return of(false)
        }
        return this._firebase.firestoreDoc<EraajotYksiKerrallaanSuorittajaData>('tyojono_ajurit/holvi_api_import-' + asiakas.avain)
          .listen()
          .pipe(
            map(entry => {
              if (entry?.luotu) {
                return entry.luotu <= this._timestampProvider.dateToTimestamp(new Date(Date.now() - 1000 * 60 * 2))
              }
              return false
            })
          )
      }),
      startWith(false),
      lemonShare()
    )

    this.holviZipDriverExistsObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (!asiakas) {
          return of(false)
        }
        const driverAvain = 'tyojono_ajurit/kirjanpito_holvi_import-' + asiakas.avain
        return this._firebase.firestoreDoc<EraajotYksiKerrallaanSuorittajaData>(driverAvain)
          .listen()
          .pipe(
            map(entry => {
              if (entry?.luotu) {
                return entry.luotu <= this._timestampProvider.dateToTimestamp(new Date(Date.now() - 1000 * 60 * 2))
              }
              return false
            })
          )
      }),
      startWith(false),
      lemonShare()
    )

    this.lemontreeAutomaattiReskontraLatauksiaObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (!asiakas) {
          return of(false)
        }
        const uri = 'tyojono/' + asiakas.avain + '/automaattireskontra-lemontree'
        return this._firebaseLemonaid.firestoreCollection<TilitapahtumanTuontityodataJaAsiakasTiedot>(uri)
          .where('lisatty', '<=', this._timestampProvider.dateToTimestamp(new Date(Date.now() - 1000 * 60 * 2)))
          .limit(1)
          .listen()
          .pipe(
            map(snapshots => snapshots?.length > 0),
            startWith(false)
          )
      }),
      lemonShare()
    )

    this.lemontreeAutomaattiReskontraLatauksiaCountObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (!asiakas) {
          return of(0)
        }
        const uri = 'tyojono/' + asiakas.avain + '/automaattireskontra-lemontree'
        return this._firebaseLemonaid.firestoreCollection<TilitapahtumanTuontityodataJaAsiakasTiedot>(uri)
          .listen()
          .pipe(
            map(snapshots => snapshots?.length ?? 0),
            startWith(0)
          )
      }),
      lemonShare()
    )

    this.holviKasittelemattomiaLatauksiaObservable = combineLatest([
      this.holviZipDriverExistsObservable,
      this.holviZipKasittelemattaObservable,
      this.holviApiDriverExistsObservable,
      this.holviApiKasittelemattaObservable
    ]).pipe(
      map(([zipDriver, zip, apiDriver, api]) => {
        return !!zipDriver || !!zip || !!apiDriver || !!api
      })
    )

    this.holviKasittelemattomiaLatauksiaCountObservable = combineLatest([
      this._kirjanpitoService.holviZipDriverExistsObservable,
      this._kirjanpitoService.holviZipKasittelemattaCountObservable,
      this._kirjanpitoService.holviApiDriverExistsObservable,
      this._kirjanpitoService.holviApiKasittelemattaCountObservable
    ]).pipe(
      map(([zipDriver, zip, apiDriver, api]) => {
        const zdrvr = zipDriver ? 1 : 0
        const adrvr = apiDriver ? 1 : 0
        return zdrvr + zip + adrvr + api
      })
    )

    this.tyojonolatauksetKeskenObservable = combineLatest([
      this.holviKasittelemattomiaLatauksiaObservable,
      this.tilitapahtumiaKasittelemattaObservable,
      this.lemontreeAutomaattiReskontraLatauksiaObservable
    ]).pipe(
      map(([holvi, tilitapahtumat, automaattireskontra]) => {
        return !!holvi || !!tilitapahtumat || !!automaattireskontra
      })
    )

    this.tyojonolatauksetKeskenCountObservable = combineLatest([
      this.holviKasittelemattomiaLatauksiaCountObservable,
      this._kirjanpitoService.tilitapahtumiaKasittelemattaCountObservable,
      this.lemontreeAutomaattiReskontraLatauksiaCountObservable
    ]).pipe(
      map(([holvi, tilitapahtumat, automaattireskontra]) => {
        const kaynnissa: string[] = []
        if (holvi > 0) kaynnissa.push('Holvi: ' + holvi)
        if (tilitapahtumat) kaynnissa.push('Tilitapahtumat: ' + tilitapahtumat)
        if (automaattireskontra) kaynnissa.push('Automaattireskontra: ' + automaattireskontra)
        return kaynnissa
      })
    )

  }

}