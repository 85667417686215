import { AsiakkaanSopimuskaudenLopettamisenSyy, AsiakkaanSopimuskaudenTauko, AsiakkaanSopimuskaudenTila, AsiakkaanSopimuskausi } from '../model/asiakas'
import { SopimuksenHyvaksynnanHistoria } from '../../_jaettu/model/sopimus'
import { Asiakas } from '../../_jaettu-lemonator/model/asiakas'
import { DateService } from '../../_shared-core/service/date.service'
import { TimestampProviderBase } from '../../_shared-core/service/timestamp-provider.interface'
import { LocalDate, Timestamp } from '../../_shared-core/model/common'

export interface LokalisoituLopettamissyy {
  nimi: string
  lisatiedotVinkki: string
  lisatiedotPakollinen: boolean
  arvo: AsiakkaanSopimuskaudenLopettamisenSyy
}

export class AsiakkaanSopimuskausiService {
  lokalisoidutSopimuksenLopettamissyyt: LokalisoituLopettamissyy[] = [
    { nimi: 'Liiketoiminta loppuu', lisatiedotPakollinen: false, lisatiedotVinkki: '', arvo: AsiakkaanSopimuskaudenLopettamisenSyy.LIIKETOIMINTA_LOPPPUU },
    { nimi: 'Liiketoiminta tauolle', lisatiedotPakollinen: false, lisatiedotVinkki: 'Jos tiedät, kirjoita tauon syy ja milloin asiakas palaa.', arvo: AsiakkaanSopimuskaudenLopettamisenSyy.LIIKETOIMINTA_TAUOLLE },
    { nimi: 'Hinnankorotus', lisatiedotPakollinen: false, lisatiedotVinkki: '', arvo: AsiakkaanSopimuskaudenLopettamisenSyy.HINNAN_KOROTUS },
    { nimi: 'Alkaa tekemään kirjanpidon itse', lisatiedotPakollinen: false, lisatiedotVinkki: '', arvo: AsiakkaanSopimuskaudenLopettamisenSyy.ALKAA_TEKEMAAN_ITSE },
    { nimi: 'Vaihtaa tilitoimistoa hinnan vuoksi', lisatiedotPakollinen: true, lisatiedotVinkki: 'Jos tiedossa on, kerro mikä uusi palveluntarjoaja on ja millä hinnalla.', arvo: AsiakkaanSopimuskaudenLopettamisenSyy.VAIHTAA_TILITOIMISTOA_HINNAN_VUOKSI },
    { nimi: 'Vaihtaa tilitoimistoa ohjelmiston vuoksi', lisatiedotPakollinen: true, lisatiedotVinkki: 'Jos tiedossa on, kerro esim. mitä puuttuvia ominaisuuksia asiakas ohjelmistosta tarvitsi.', arvo: AsiakkaanSopimuskaudenLopettamisenSyy.VAIHTAA_TILITOIMISTOA_OHJELMISTON_VUOKSI },
    { nimi: 'Vaihtaa tilitoimistoa muusta syystä', lisatiedotPakollinen: true, lisatiedotVinkki: '', arvo: AsiakkaanSopimuskaudenLopettamisenSyy.VAIHTAA_TILITOIMISTOA_MUUSTA_SYYTÄ },
    { nimi: 'Vaihtaa yhtiömuotoa mutta pysyy asiakkaana', lisatiedotPakollinen: false, lisatiedotVinkki: '', arvo: AsiakkaanSopimuskaudenLopettamisenSyy.VAIHTAA_YHTIOMUOTOA_PYSYY_ASIAKKAANA },
    { nimi: 'Muu syy', lisatiedotPakollinen: true, lisatiedotVinkki: 'Kirjoita mahdollisimman tarkka selvitys syystä', arvo: AsiakkaanSopimuskaudenLopettamisenSyy.MUU_SYY },
    { nimi: 'Purku: Maksamattomat laskut', lisatiedotPakollinen: false, lisatiedotVinkki: '', arvo: AsiakkaanSopimuskaudenLopettamisenSyy.PURKU_MAKSAMATTOMAT_LASKUT },
    { nimi: 'Purku: Toimittamaton aineisto', lisatiedotPakollinen: false, lisatiedotVinkki: '', arvo: AsiakkaanSopimuskaudenLopettamisenSyy.PURKU_TOIMITTAMATON_AINEISTO },
    { nimi: 'Purku: Rahanpesuepäily', lisatiedotPakollinen: false, lisatiedotVinkki: '', arvo: AsiakkaanSopimuskaudenLopettamisenSyy.PURKU_RAHANPESUEPAILY },
    { nimi: 'Purku: Muu syy', lisatiedotPakollinen: true, lisatiedotVinkki: 'Kirjoita mahdollisimman tarkka selvitys syystä', arvo: AsiakkaanSopimuskaudenLopettamisenSyy.PURKU_MUU_SYY },
    { nimi: 'Purku: Ei tullutkaan asiakkaaksi', lisatiedotPakollinen: false, lisatiedotVinkki: '', arvo: AsiakkaanSopimuskaudenLopettamisenSyy.EI_TULLUT_ASIAKKAAKSI }
  ]
  constructor(
    private _dateService: DateService,
    private _timestampService: TimestampProviderBase
  ) { }
  onkoPurku(valittavaSyy: AsiakkaanSopimuskaudenLopettamisenSyy): boolean {
    if (!valittavaSyy) {
      return false
    }
    return valittavaSyy.startsWith('p')
  }
  onkoLopetus(valittavaSyy: AsiakkaanSopimuskaudenLopettamisenSyy): boolean {
    if (!valittavaSyy) {
      return false
    }
    return valittavaSyy.startsWith('l')
  }
  annaLokalisoituSyy(arvo: AsiakkaanSopimuskaudenLopettamisenSyy): LokalisoituLopettamissyy {
    if (!arvo) {
      return null
    }
    const lokalisoitu = this.lokalisoidutSopimuksenLopettamissyyt.find(s => s.arvo === arvo)
    if (lokalisoitu) { return lokalisoitu }
    throw new Error('Lokalisoitu lopettamissyy puuttuu tunnisteelle ' + arvo)
  }
  annaSopimusPaattyyPianEmailDate(sopimuskausi: AsiakkaanSopimuskausi): LocalDate {
    if (!sopimuskausi?.loppuu) { return null }
    const sevenDaysBefore = this._dateService.lisaaPaiviaPaikallinen(sopimuskausi.loppuu, -7)
    const nyt = this._dateService.currentLocalDate()
    return this._dateService.compareLocalDates(nyt, '>', sevenDaysBefore) ? nyt : sevenDaysBefore
  }
  annaSopimusOnPaattynytEmailDate(sopimuskausi: AsiakkaanSopimuskausi): LocalDate {
    if (!sopimuskausi?.loppuu) { return null }
    const nyt = this._dateService.currentLocalDate()
    return this._dateService.compareLocalDates(nyt, '>', sopimuskausi.loppuu) ? nyt : this._dateService.lisaaPaiviaPaikallinen(sopimuskausi.loppuu, 1)
  }
  annaTimestampKayttajienDeaktivoinnille(sopimuskausi: AsiakkaanSopimuskausi): Timestamp {
    if (this.onkoPurku(sopimuskausi.lopettamisenSyy)) {
      const day1 = this._dateService.lisaaPaiviaPaikallinen(sopimuskausi.loppuu, 1)
      return this.annaTimestampRoolienPoistotyolle(day1)
    }
    const day2 = this._dateService.lisaaPaiviaPaikallinen(sopimuskausi.tositteidenToimitusLoppuu, 1)
    return this.annaTimestampRoolienPoistotyolle(day2)
  }
  annaTimestampRoolienPoistotyolle(date: LocalDate): Timestamp {
    return this._timestampService.localDateTimeToTimestamp({
      year: date.year,
      month: date.month,
      day: date.day,
      hour: 3,
      minutes: 30,
      seconds: 30
    })
  }
  annaTimestampSahkopostityolle(date: LocalDate): Timestamp {
    return this._timestampService.localDateTimeToTimestamp({
      year: date.year,
      month: date.month,
      day: date.day,
      hour: 10,
      minutes: 30,
      seconds: 30
    })
  }
  annaSopimuskaudenTila(sopimuskausi: AsiakkaanSopimuskausi, localDate: LocalDate, allekirjoitukset: SopimuksenHyvaksynnanHistoria[]): AsiakkaanSopimuskaudenTila {
    const tila = this.annaSopimuskaudenTilaPoislukienAllekirjoitustaVaativat(sopimuskausi, localDate)
    if (tila) {
      return tila
    }
    return allekirjoitukset.length > 0 ? AsiakkaanSopimuskaudenTila.VOIMASSA : AsiakkaanSopimuskaudenTila.ALLEKIRJOITTAMATTA
  }
  annaEdellinenKausi(sopimuskaudet: AsiakkaanSopimuskausi[], pvm: LocalDate) {
    if (!sopimuskaudet || !pvm) {
      return null
    }
    let edellinen: AsiakkaanSopimuskausi = null
    for (const kausi of sopimuskaudet) {
      if (kausi.loppuu && this._dateService.compareLocalDates(kausi.loppuu, '<', pvm)) {
        if (edellinen === null || this._dateService.compareLocalDates(edellinen.loppuu, '<', kausi.loppuu)) {
          edellinen = kausi
        }
      }
    }
    return edellinen
  }
  annaSopimuskaudenTilaPoislukienAllekirjoitustaVaativat(sopimuskausi: AsiakkaanSopimuskausi, localDate: LocalDate): AsiakkaanSopimuskaudenTila {
    if (sopimuskausi.loppuu) {
      if (this._dateService.compareLocalDates(sopimuskausi.loppuu, '>=', localDate)) {
        return AsiakkaanSopimuskaudenTila.PAATTYMASSA
      } else if (this.onkoPurku(sopimuskausi.lopettamisenSyy)) {
        return AsiakkaanSopimuskaudenTila.PURETTU
      } else if (this.onkoLopetus(sopimuskausi.lopettamisenSyy)) {
        return AsiakkaanSopimuskaudenTila.PAATTYNYT
      } else {
        throw Error('Sopimuskauden syy ei ole purku eikä lopetus?\n\n' + JSON.stringify(sopimuskausi, null, '  '))
      }
    }
    // Tarkista onko tauolla
    if (sopimuskausi.tauot) {
      for (const tauko of sopimuskausi.tauot) {
        if (
          this._dateService.compareLocalDates(tauko.alkaa, '<=', localDate) &&
          (!tauko.loppuu || this._dateService.compareLocalDates(tauko.loppuu, '>=', localDate))
        ) {
          if (tauko.tyyppi === 'poytalaatikko') {
            return AsiakkaanSopimuskaudenTila.POYTALAATIKOSSA
          } else if (tauko.tyyppi === 'tauko') {
            return AsiakkaanSopimuskaudenTila.TAUOLLA
          } else {
            throw new Error('Tuntematon tauon tyyppi: ' + tauko.tyyppi)
          }
        }
      }
    }
    return null
  }

  /**
   *
   * @returns true jos sopimuskausi on VOIMASSSA, ALLEKIRJOITTAMATTA vai PÄÄTTYMÄSSÄ
   */
  onkoSopimuskausiVoimassa(sopimuskausi: AsiakkaanSopimuskausi, localDate: LocalDate): boolean {
    if (!sopimuskausi) {
      return false
    }
    const tila = this.annaSopimuskaudenTilaPoislukienAllekirjoitustaVaativat(sopimuskausi, localDate)

    if (!tila || tila === AsiakkaanSopimuskaudenTila.PAATTYMASSA) {
      // Method above returns null if AsiakkaanSopimuskaudenTila is VOIMASSA or ALLEKIRJOITTAMATTA.
      return true
    }
    return false
  }

  annaSopimuskausienAikaisetAllekirjoitukset(sopimuskaudet: AsiakkaanSopimuskausi[], allekirjoitukset: SopimuksenHyvaksynnanHistoria[]): Map<string, SopimuksenHyvaksynnanHistoria[]> {

    const allekirjoitusMap: Map<string, SopimuksenHyvaksynnanHistoria[]> = new Map()
    for (const sopimuskausi of sopimuskaudet) {
      allekirjoitusMap.set(sopimuskausi.avain, [])
    }

    for (const allekirjoitus of allekirjoitukset) {
      let parasSopimuskausi: AsiakkaanSopimuskausi = null
      for (const sopimuskausi of sopimuskaudet) {
        if (this._dateService.compareTimestamps(sopimuskausi.luotu, '<', allekirjoitus.pvm)) {
          if (!parasSopimuskausi || this._dateService.compareTimestamps(sopimuskausi.luotu, '>', parasSopimuskausi.luotu)) {
            parasSopimuskausi = sopimuskausi
          }
        }
      }
      if (parasSopimuskausi) {
        allekirjoitusMap.get(parasSopimuskausi.avain).push(allekirjoitus)
      }
    }

    return allekirjoitusMap

    // return allekirjoitukset.filter(hyvaksynta => {
    //   // console.log(JSON.stringify(hyvaksynta, null, '  '))
    //   const localDate = this._dateService.timestampToLocalDate(hyvaksynta.pvm)
    //   // console.log(localDate)
    //   if (sopimuskausi.loppuu) {
    //     return this._dateService.compareLocalDates(localDate, '>=', sopimuskausi.alkaa) &&
    //       this._dateService.compareLocalDates(localDate, '<=', sopimuskausi.loppuu)
    //   }
    //   return this._dateService.compareLocalDates(localDate, '>=', sopimuskausi.alkaa)
    // })
  }
  // annaTilanVarikoodi(tila: AsiakkaanSopimuskaudenTila): string {
  //   if (tila === AsiakkaanSopimuskaudenTila.ALLEKIRJOITTAMATTA) {
  //     return '#4997ef'
  //   } else if (tila === AsiakkaanSopimuskaudenTila.PAATTYMASSA) {
  //     return '#b534ea'
  //   } else if (tila === AsiakkaanSopimuskaudenTila.PAATTYNYT) {
  //     return '#b5b5b5'
  //   } else if (tila === AsiakkaanSopimuskaudenTila.PURETTU) {
  //     return '#000000'
  //   } else if (tila === AsiakkaanSopimuskaudenTila.TAUOLLA) {
  //     return '#f9dc3e'
  //   } else if (tila === AsiakkaanSopimuskaudenTila.VOIMASSA) {
  //     return '#76e047'
  //   }
  //   throw new Error('Unknown tila: ' + JSON.stringify(tila))
  // }
  annaTilanNimi(tila: AsiakkaanSopimuskaudenTila): string {
    if (tila === AsiakkaanSopimuskaudenTila.ALLEKIRJOITTAMATTA) {
      return 'Allekirjoittamatta'
    } else if (tila === AsiakkaanSopimuskaudenTila.PAATTYMASSA) {
      return 'Päättymässä'
    } else if (tila === AsiakkaanSopimuskaudenTila.PAATTYNYT) {
      return 'Päättynyt'
    } else if (tila === AsiakkaanSopimuskaudenTila.PURETTU) {
      return 'Purettu'
    } else if (tila === AsiakkaanSopimuskaudenTila.TAUOLLA) {
      return 'Tauolla'
    } else if (tila === AsiakkaanSopimuskaudenTila.VOIMASSA) {
      return 'Voimassa'
    } else if (tila === AsiakkaanSopimuskaudenTila.POYTALAATIKOSSA) {
      return 'Pöytälaatikossa'
    }
    throw new Error('Unknown tila: ' + JSON.stringify(tila))
  }
  annaKausiPaivamaaralle(kaudet: AsiakkaanSopimuskausi[], pvm: LocalDate): AsiakkaanSopimuskausi {
    if (!kaudet) { return null }
    for (const kausi of kaudet) {
      if (kausi.loppuu) {
        if (
          this._dateService.compareLocalDates(kausi.alkaa, '<=', pvm) &&
          this._dateService.compareLocalDates(kausi.loppuu, '>=', pvm)
        ) {
          return kausi
        }
      } else if (this._dateService.compareLocalDates(kausi.alkaa, '<=', pvm)) {
        return kausi
      }
    }
    return null
  }
  onkoTaukoVoimassaKokoPaivamaaravalin(tauko: AsiakkaanSopimuskaudenTauko, alku: LocalDate, loppu: LocalDate): boolean {
    if (tauko.loppuu) {
      return this._dateService.compareLocalDates(tauko.alkaa, '<=', alku) && this._dateService.compareLocalDates(loppu, '<=', tauko.loppuu)
    }
    return this._dateService.compareLocalDates(tauko.alkaa, '<=', alku)
  }
  annaKokoAikavalinVoimassaOlevaTauko(asiakas: Pick<Asiakas, 'sopimuskaudet'>, alku: LocalDate, loppu: LocalDate): AsiakkaanSopimuskaudenTauko {
    if (asiakas?.sopimuskaudet) {
      for (const kausi of asiakas.sopimuskaudet) {
        if (kausi.tauot) {
          for (const tauko of kausi.tauot) {
            if (this.onkoTaukoVoimassaKokoPaivamaaravalin(tauko, alku, loppu)) {
              return tauko
            }
          }
        }
      }
    }
    return null
  }
  annaTaukoJokaOnVoimassaKokoAikavalin(kausi: AsiakkaanSopimuskausi, alku: LocalDate, loppu: LocalDate): AsiakkaanSopimuskaudenTauko {
    if (kausi?.tauot) {
      for (const tauko of kausi.tauot) {
        if (this.onkoTaukoVoimassaKokoPaivamaaravalin(tauko, alku, loppu)) {
          return tauko
        }
      }
    }
    return null
  }
  annaTaukoJokaOnVoimassaPaivamaaralla(kausi: AsiakkaanSopimuskausi, pvm: LocalDate, tyyppi?: AsiakkaanSopimuskaudenTauko['tyyppi']): AsiakkaanSopimuskaudenTauko {
    for (const tauko of (kausi.tauot || [])) {
      if (this._dateService.onkoLocalDateKahdenValissa(pvm, tauko.alkaa, tauko.loppuu ?? pvm) &&
        (!tyyppi || tyyppi === tauko.tyyppi)) {
        return tauko
      }
    }
    return null
  }

  /**
 * Anna kaikki kaudet, jotka ovat voimassa vähintään 1 päivän annetulla aikavälillä
 * @param asiakas
 * @param alku
 * @param loppu
 * @returns
 */
  annaAikavalillaVoimassaolevatSopimuskaudet(asiakas: Pick<Asiakas, 'sopimuskaudet'>, alku: LocalDate | null, loppu: LocalDate | null): AsiakkaanSopimuskausi[] {

    const found: AsiakkaanSopimuskausi[] = []

    // Missing day means infinity, from times beginning or times end,
    // simulate it by replacing it with year 1 or 9999
    const interval1Start = alku ?? { year: 1, month: 1, day: 1 }
    const interval1End = loppu ?? { year: 9999, month: 12, day: 31 }

    for (const kausi of asiakas.sopimuskaudet ?? []) {
      const kausiIntervalStart: LocalDate = kausi.alkaa ?? { year: 1, month: 1, day: 1 }
      const kausiIntervalEnd: LocalDate = kausi.loppuu ?? { year: 9999, month: 12, day: 31 }

      // The reasoning behind this algo: https://stackoverflow.com/questions/13513932/algorithm-to-detect-overlapping-periods
      // a.start < b.end && b.start < a.end;
      if (
        this._dateService.compareLocalDates(kausiIntervalStart, '<=', interval1End) &&
        this._dateService.compareLocalDates(interval1Start, '<=', kausiIntervalEnd)
      ) {
        found.push(kausi)
      }
    }

    return found

  }
}
