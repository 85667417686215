import { LocalDate } from '../model/common'
import { DateService } from './date.service'

// eslint-disable-next-line no-shadow
export enum PyhapaivanTyyppi {

  // https://fi.wikipedia.org/wiki/Suomen_juhlapäivät#Lakis.C3.A4.C3.A4teiset_juhlap.C3.A4iv.C3.A4t
  UUDENVUODENPAIVA = 'up', // 1.1
  LOPPIAINEN = 'lp', // 6.1
  PITKAPERJANTAI = 'pp', // pääsiäispäivää edeltävä perjantai
  PAASIAISPAIVA = 'ps', // ???
  PAASIAISPAIVA_2 = 'pa', // pääsiäispäivää seuraava maanantai
  VAPPU = 'v', // 1.5
  HELATORSTAI = 'ht', // 40. päivänä pääsiäisen jälkeen
  HELLUNTAIPAIVA = 'hp', // 50. päivänä pääsiäisen jälkeen
  JUHANNUSPAIVA = 'j', // 20.6 ja 26.6 päivän välinen lauantai
  PYHAINPAIVA = 'py', // 31.10 ja 6.11 päivän välinen lauantai
  ITSENAISYYSPAIVA = 'ip', // 6.12
  JOULUAATTO = 'joa', // 24.12
  JOULUPAIVA = 'jp', // 25.12
  TAPANINPAIVA = 'tp', // 26.12

  // LOPUT PYHÄPÄIVÄT OVAT AINA SUNNUNTAITA!!!
  KYNTTILAPAIVA = 'kp',
  LASKIAISSUNNUNTAI = 'ls',
  MARIAN_ILMESTYSPAIVA = 'mi',
  TUOMIOSUNNUNTAI = 'ts',
  APOSTOLIEN_PAIVA = 'ap',
  PALMUSUNNUNTAI = 'ps',
  PAASIAISSUNNUNTAI = 'pa',
  KRISTUSSUNNUNTAI = 'ks', // kirkastussunnuntai
  MIKKELINPAIVA = 'mp',
  KAATUNEIDEN_MUISTOPAIVA = 'km',
  PYHAN_KOLMINAISUUDEN_PAIVA = 'pk',

  // EI VIRALLINEN PYHÄPÄIVÄ, MUTTA PÄÄTETTY 18.06.2020
  JUHANNUSAATTO = 'ja'
}

export interface Pyhapaiva {
  tyyppi: PyhapaivanTyyppi
  pvm: LocalDate
}

/**
 * Links worth to look at:
 * https://fi.wikipedia.org/wiki/Pyhäpäivä
 *
 * Vuosilomalaki, Määritelmät
 * http://www.finlex.fi/fi/laki/ajantasa/2005/20050162#L1P4
 *
 * Työaikalaki, Sunnuntaityö + säännöllisen työajan ylittäminen
 * http://www.finlex.fi/fi/laki/ajantasa/2019/20190872#L5
 */
export class PyhapaivatService {
  pyhapaivatViimeisin: LocalDate
  pyhapaivat: Map<string, Pyhapaiva>

  pankkivapaapaivatViimeisin: LocalDate
  pankkivapaapaivat: Map<string, Pyhapaiva>
  constructor(
    private _dateService: DateService
  ) {
    this.pyhapaivat = new Map()
    this.pyhapaivat.set('2018-1-1', { pvm: { year: 2018, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pyhapaivat.set('2018-1-6', { pvm: { year: 2018, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pyhapaivat.set('2018-2-4', { pvm: { year: 2018, month: 2, day: 4 }, tyyppi: PyhapaivanTyyppi.KYNTTILAPAIVA })
    this.pyhapaivat.set('2018-2-11', { pvm: { year: 2018, month: 2, day: 11 }, tyyppi: PyhapaivanTyyppi.KYNTTILAPAIVA })
    this.pyhapaivat.set('2018-3-18', { pvm: { year: 2018, month: 3, day: 18 }, tyyppi: PyhapaivanTyyppi.MARIAN_ILMESTYSPAIVA })
    this.pyhapaivat.set('2018-3-25', { pvm: { year: 2018, month: 3, day: 25 }, tyyppi: PyhapaivanTyyppi.PALMUSUNNUNTAI })
    this.pyhapaivat.set('2018-3-30', { pvm: { year: 2018, month: 3, day: 30 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pyhapaivat.set('2018-4-1', { pvm: { year: 2018, month: 4, day: 1 }, tyyppi: PyhapaivanTyyppi.PAASIAISSUNNUNTAI })
    this.pyhapaivat.set('2018-4-2', { pvm: { year: 2018, month: 4, day: 2 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pyhapaivat.set('2018-5-1', { pvm: { year: 2018, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pyhapaivat.set('2018-5-10', { pvm: { year: 2018, month: 5, day: 10 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pyhapaivat.set('2018-5-20', { pvm: { year: 2018, month: 5, day: 20 }, tyyppi: PyhapaivanTyyppi.HELLUNTAIPAIVA })
    this.pyhapaivat.set('2018-5-20', { pvm: { year: 2018, month: 5, day: 20 }, tyyppi: PyhapaivanTyyppi.KAATUNEIDEN_MUISTOPAIVA })
    this.pyhapaivat.set('2018-5-27', { pvm: { year: 2018, month: 5, day: 27 }, tyyppi: PyhapaivanTyyppi.PYHAN_KOLMINAISUUDEN_PAIVA })
    this.pyhapaivat.set('2018-6-23', { pvm: { year: 2018, month: 6, day: 23 }, tyyppi: PyhapaivanTyyppi.JUHANNUSPAIVA })
    this.pyhapaivat.set('2018-7-1', { pvm: { year: 2018, month: 7, day: 1 }, tyyppi: PyhapaivanTyyppi.APOSTOLIEN_PAIVA })
    this.pyhapaivat.set('2018-7-15', { pvm: { year: 2018, month: 7, day: 15 }, tyyppi: PyhapaivanTyyppi.KRISTUSSUNNUNTAI })
    this.pyhapaivat.set('2018-9-30', { pvm: { year: 2018, month: 9, day: 30 }, tyyppi: PyhapaivanTyyppi.MIKKELINPAIVA })
    this.pyhapaivat.set('2018-11-3', { pvm: { year: 2018, month: 11, day: 3 }, tyyppi: PyhapaivanTyyppi.PYHAINPAIVA })
    this.pyhapaivat.set('2018-11-25', { pvm: { year: 2018, month: 11, day: 25 }, tyyppi: PyhapaivanTyyppi.TUOMIOSUNNUNTAI })
    this.pyhapaivat.set('2018-12-6', { pvm: { year: 2018, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pyhapaivat.set('2018-12-24', { pvm: { year: 2018, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pyhapaivat.set('2018-12-25', { pvm: { year: 2018, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pyhapaivat.set('2018-12-26', { pvm: { year: 2018, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })
    this.pyhapaivat.set('2019-1-1', { pvm: { year: 2019, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pyhapaivat.set('2019-1-6', { pvm: { year: 2019, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pyhapaivat.set('2019-2-3', { pvm: { year: 2019, month: 2, day: 3 }, tyyppi: PyhapaivanTyyppi.KYNTTILAPAIVA })
    this.pyhapaivat.set('2019-3-3', { pvm: { year: 2019, month: 3, day: 3 }, tyyppi: PyhapaivanTyyppi.KYNTTILAPAIVA })
    this.pyhapaivat.set('2019-3-24', { pvm: { year: 2019, month: 3, day: 24 }, tyyppi: PyhapaivanTyyppi.MARIAN_ILMESTYSPAIVA })
    this.pyhapaivat.set('2019-4-14', { pvm: { year: 2019, month: 4, day: 14 }, tyyppi: PyhapaivanTyyppi.PALMUSUNNUNTAI })
    this.pyhapaivat.set('2019-4-19', { pvm: { year: 2019, month: 4, day: 19 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pyhapaivat.set('2019-4-21', { pvm: { year: 2019, month: 4, day: 21 }, tyyppi: PyhapaivanTyyppi.PAASIAISSUNNUNTAI })
    this.pyhapaivat.set('2019-4-22', { pvm: { year: 2019, month: 4, day: 22 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pyhapaivat.set('2019-5-1', { pvm: { year: 2019, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pyhapaivat.set('2019-5-19', { pvm: { year: 2019, month: 5, day: 19 }, tyyppi: PyhapaivanTyyppi.KAATUNEIDEN_MUISTOPAIVA })
    this.pyhapaivat.set('2019-5-30', { pvm: { year: 2019, month: 5, day: 30 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pyhapaivat.set('2019-6-9', { pvm: { year: 2019, month: 6, day: 9 }, tyyppi: PyhapaivanTyyppi.HELLUNTAIPAIVA })
    this.pyhapaivat.set('2019-6-16', { pvm: { year: 2019, month: 6, day: 16 }, tyyppi: PyhapaivanTyyppi.PYHAN_KOLMINAISUUDEN_PAIVA })
    this.pyhapaivat.set('2019-6-22', { pvm: { year: 2019, month: 6, day: 22 }, tyyppi: PyhapaivanTyyppi.JUHANNUSPAIVA })
    this.pyhapaivat.set('2019-7-21', { pvm: { year: 2019, month: 7, day: 21 }, tyyppi: PyhapaivanTyyppi.APOSTOLIEN_PAIVA })
    this.pyhapaivat.set('2019-8-4', { pvm: { year: 2019, month: 8, day: 4 }, tyyppi: PyhapaivanTyyppi.KRISTUSSUNNUNTAI })
    this.pyhapaivat.set('2019-9-29', { pvm: { year: 2019, month: 9, day: 29 }, tyyppi: PyhapaivanTyyppi.MIKKELINPAIVA })
    this.pyhapaivat.set('2019-11-2', { pvm: { year: 2019, month: 11, day: 2 }, tyyppi: PyhapaivanTyyppi.PYHAINPAIVA })
    this.pyhapaivat.set('2019-11-24', { pvm: { year: 2019, month: 11, day: 24 }, tyyppi: PyhapaivanTyyppi.TUOMIOSUNNUNTAI })
    this.pyhapaivat.set('2019-12-6', { pvm: { year: 2019, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pyhapaivat.set('2019-12-24', { pvm: { year: 2019, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pyhapaivat.set('2019-12-25', { pvm: { year: 2019, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pyhapaivat.set('2019-12-26', { pvm: { year: 2019, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })
    this.pyhapaivat.set('2020-1-1', { pvm: { year: 2020, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pyhapaivat.set('2020-1-6', { pvm: { year: 2020, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pyhapaivat.set('2020-2-2', { pvm: { year: 2020, month: 2, day: 2 }, tyyppi: PyhapaivanTyyppi.KYNTTILAPAIVA })
    this.pyhapaivat.set('2020-2-23', { pvm: { year: 2020, month: 2, day: 23 }, tyyppi: PyhapaivanTyyppi.KYNTTILAPAIVA })
    this.pyhapaivat.set('2020-3-22', { pvm: { year: 2020, month: 3, day: 22 }, tyyppi: PyhapaivanTyyppi.MARIAN_ILMESTYSPAIVA })
    this.pyhapaivat.set('2020-4-5', { pvm: { year: 2020, month: 4, day: 5 }, tyyppi: PyhapaivanTyyppi.PALMUSUNNUNTAI })
    this.pyhapaivat.set('2020-4-10', { pvm: { year: 2020, month: 4, day: 10 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pyhapaivat.set('2020-4-12', { pvm: { year: 2020, month: 4, day: 12 }, tyyppi: PyhapaivanTyyppi.PAASIAISSUNNUNTAI })
    this.pyhapaivat.set('2020-4-13', { pvm: { year: 2020, month: 4, day: 13 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pyhapaivat.set('2020-5-1', { pvm: { year: 2020, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pyhapaivat.set('2020-5-17', { pvm: { year: 2020, month: 5, day: 17 }, tyyppi: PyhapaivanTyyppi.KAATUNEIDEN_MUISTOPAIVA })
    this.pyhapaivat.set('2020-5-21', { pvm: { year: 2020, month: 5, day: 21 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pyhapaivat.set('2020-5-31', { pvm: { year: 2020, month: 5, day: 31 }, tyyppi: PyhapaivanTyyppi.HELLUNTAIPAIVA })
    this.pyhapaivat.set('2020-6-7', { pvm: { year: 2020, month: 6, day: 7 }, tyyppi: PyhapaivanTyyppi.PYHAN_KOLMINAISUUDEN_PAIVA })
    this.pyhapaivat.set('2020-6-19', { pvm: { year: 2020, month: 6, day: 19 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pyhapaivat.set('2020-6-20', { pvm: { year: 2020, month: 6, day: 20 }, tyyppi: PyhapaivanTyyppi.JUHANNUSPAIVA })
    this.pyhapaivat.set('2020-7-12', { pvm: { year: 2020, month: 7, day: 12 }, tyyppi: PyhapaivanTyyppi.APOSTOLIEN_PAIVA })
    this.pyhapaivat.set('2020-7-26', { pvm: { year: 2020, month: 7, day: 26 }, tyyppi: PyhapaivanTyyppi.KRISTUSSUNNUNTAI })
    this.pyhapaivat.set('2020-10-4', { pvm: { year: 2020, month: 10, day: 4 }, tyyppi: PyhapaivanTyyppi.MIKKELINPAIVA })
    this.pyhapaivat.set('2020-10-31', { pvm: { year: 2020, month: 10, day: 31 }, tyyppi: PyhapaivanTyyppi.PYHAINPAIVA })
    this.pyhapaivat.set('2020-11-22', { pvm: { year: 2020, month: 11, day: 22 }, tyyppi: PyhapaivanTyyppi.TUOMIOSUNNUNTAI })
    this.pyhapaivat.set('2020-12-6', { pvm: { year: 2020, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pyhapaivat.set('2020-12-24', { pvm: { year: 2020, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pyhapaivat.set('2020-12-25', { pvm: { year: 2020, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pyhapaivat.set('2020-12-26', { pvm: { year: 2020, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })
    this.pyhapaivat.set('2021-1-1', { pvm: { year: 2021, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pyhapaivat.set('2021-1-6', { pvm: { year: 2021, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pyhapaivat.set('2021-2-7', { pvm: { year: 2021, month: 2, day: 7 }, tyyppi: PyhapaivanTyyppi.KYNTTILAPAIVA })
    this.pyhapaivat.set('2021-2-14', { pvm: { year: 2021, month: 2, day: 14 }, tyyppi: PyhapaivanTyyppi.KYNTTILAPAIVA })
    this.pyhapaivat.set('2021-3-21', { pvm: { year: 2021, month: 3, day: 21 }, tyyppi: PyhapaivanTyyppi.MARIAN_ILMESTYSPAIVA })
    this.pyhapaivat.set('2021-3-28', { pvm: { year: 2021, month: 3, day: 28 }, tyyppi: PyhapaivanTyyppi.PALMUSUNNUNTAI })
    this.pyhapaivat.set('2021-4-2', { pvm: { year: 2021, month: 4, day: 2 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pyhapaivat.set('2021-4-4', { pvm: { year: 2021, month: 4, day: 4 }, tyyppi: PyhapaivanTyyppi.PAASIAISSUNNUNTAI })
    this.pyhapaivat.set('2021-4-5', { pvm: { year: 2021, month: 4, day: 5 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pyhapaivat.set('2021-5-1', { pvm: { year: 2021, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pyhapaivat.set('2021-5-13', { pvm: { year: 2021, month: 5, day: 13 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pyhapaivat.set('2021-5-16', { pvm: { year: 2021, month: 5, day: 16 }, tyyppi: PyhapaivanTyyppi.KAATUNEIDEN_MUISTOPAIVA })
    this.pyhapaivat.set('2021-5-23', { pvm: { year: 2021, month: 5, day: 23 }, tyyppi: PyhapaivanTyyppi.HELLUNTAIPAIVA })
    this.pyhapaivat.set('2021-5-30', { pvm: { year: 2021, month: 5, day: 30 }, tyyppi: PyhapaivanTyyppi.PYHAN_KOLMINAISUUDEN_PAIVA })
    this.pyhapaivat.set('2021-6-25', { pvm: { year: 2021, month: 6, day: 25 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pyhapaivat.set('2021-6-26', { pvm: { year: 2021, month: 6, day: 26 }, tyyppi: PyhapaivanTyyppi.JUHANNUSPAIVA })
    this.pyhapaivat.set('2021-7-4', { pvm: { year: 2021, month: 7, day: 4 }, tyyppi: PyhapaivanTyyppi.APOSTOLIEN_PAIVA })
    this.pyhapaivat.set('2021-7-18', { pvm: { year: 2021, month: 7, day: 18 }, tyyppi: PyhapaivanTyyppi.KRISTUSSUNNUNTAI })
    this.pyhapaivat.set('2021-10-3', { pvm: { year: 2021, month: 10, day: 3 }, tyyppi: PyhapaivanTyyppi.MIKKELINPAIVA })
    this.pyhapaivat.set('2021-11-6', { pvm: { year: 2021, month: 11, day: 6 }, tyyppi: PyhapaivanTyyppi.PYHAINPAIVA })
    this.pyhapaivat.set('2021-11-21', { pvm: { year: 2021, month: 11, day: 21 }, tyyppi: PyhapaivanTyyppi.TUOMIOSUNNUNTAI })
    this.pyhapaivat.set('2021-12-6', { pvm: { year: 2021, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pyhapaivat.set('2021-12-24', { pvm: { year: 2021, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pyhapaivat.set('2021-12-25', { pvm: { year: 2021, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pyhapaivat.set('2021-12-26', { pvm: { year: 2021, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })
    this.pyhapaivat.set('2022-1-1', { pvm: { year: 2022, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pyhapaivat.set('2022-1-6', { pvm: { year: 2022, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pyhapaivat.set('2022-2-6', { pvm: { year: 2022, month: 2, day: 6 }, tyyppi: PyhapaivanTyyppi.KYNTTILAPAIVA })
    this.pyhapaivat.set('2022-2-27', { pvm: { year: 2022, month: 2, day: 27 }, tyyppi: PyhapaivanTyyppi.KYNTTILAPAIVA })
    this.pyhapaivat.set('2022-3-27', { pvm: { year: 2022, month: 3, day: 27 }, tyyppi: PyhapaivanTyyppi.MARIAN_ILMESTYSPAIVA })
    this.pyhapaivat.set('2022-4-10', { pvm: { year: 2022, month: 4, day: 10 }, tyyppi: PyhapaivanTyyppi.PALMUSUNNUNTAI })
    this.pyhapaivat.set('2022-4-15', { pvm: { year: 2022, month: 4, day: 15 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pyhapaivat.set('2022-4-17', { pvm: { year: 2022, month: 4, day: 17 }, tyyppi: PyhapaivanTyyppi.PAASIAISSUNNUNTAI })
    this.pyhapaivat.set('2022-4-18', { pvm: { year: 2022, month: 4, day: 18 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pyhapaivat.set('2022-5-1', { pvm: { year: 2022, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pyhapaivat.set('2022-5-15', { pvm: { year: 2022, month: 5, day: 15 }, tyyppi: PyhapaivanTyyppi.KAATUNEIDEN_MUISTOPAIVA })
    this.pyhapaivat.set('2022-5-26', { pvm: { year: 2022, month: 5, day: 26 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pyhapaivat.set('2022-6-5', { pvm: { year: 2022, month: 6, day: 5 }, tyyppi: PyhapaivanTyyppi.HELLUNTAIPAIVA })
    this.pyhapaivat.set('2022-6-12', { pvm: { year: 2022, month: 6, day: 12 }, tyyppi: PyhapaivanTyyppi.PYHAN_KOLMINAISUUDEN_PAIVA })
    this.pyhapaivat.set('2022-6-24', { pvm: { year: 2022, month: 6, day: 24 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pyhapaivat.set('2022-6-25', { pvm: { year: 2022, month: 6, day: 25 }, tyyppi: PyhapaivanTyyppi.JUHANNUSPAIVA })
    this.pyhapaivat.set('2022-7-17', { pvm: { year: 2022, month: 7, day: 17 }, tyyppi: PyhapaivanTyyppi.APOSTOLIEN_PAIVA })
    this.pyhapaivat.set('2022-7-31', { pvm: { year: 2022, month: 7, day: 31 }, tyyppi: PyhapaivanTyyppi.KRISTUSSUNNUNTAI })
    this.pyhapaivat.set('2022-10-2', { pvm: { year: 2022, month: 10, day: 2 }, tyyppi: PyhapaivanTyyppi.MIKKELINPAIVA })
    this.pyhapaivat.set('2022-11-5', { pvm: { year: 2022, month: 11, day: 5 }, tyyppi: PyhapaivanTyyppi.PYHAINPAIVA })
    this.pyhapaivat.set('2022-11-20', { pvm: { year: 2022, month: 11, day: 20 }, tyyppi: PyhapaivanTyyppi.TUOMIOSUNNUNTAI })
    this.pyhapaivat.set('2022-12-6', { pvm: { year: 2022, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pyhapaivat.set('2022-12-24', { pvm: { year: 2022, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pyhapaivat.set('2022-12-25', { pvm: { year: 2022, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pyhapaivat.set('2022-12-26', { pvm: { year: 2022, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })
    this.pyhapaivat.set('2023-1-1', { pvm: { year: 2023, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pyhapaivat.set('2023-1-6', { pvm: { year: 2023, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pyhapaivat.set('2023-2-5', { pvm: { year: 2023, month: 2, day: 5 }, tyyppi: PyhapaivanTyyppi.KYNTTILAPAIVA })
    this.pyhapaivat.set('2023-2-19', { pvm: { year: 2023, month: 2, day: 19 }, tyyppi: PyhapaivanTyyppi.KYNTTILAPAIVA })
    this.pyhapaivat.set('2023-3-26', { pvm: { year: 2023, month: 3, day: 26 }, tyyppi: PyhapaivanTyyppi.MARIAN_ILMESTYSPAIVA })
    this.pyhapaivat.set('2023-4-2', { pvm: { year: 2023, month: 4, day: 2 }, tyyppi: PyhapaivanTyyppi.PALMUSUNNUNTAI })
    this.pyhapaivat.set('2023-4-7', { pvm: { year: 2023, month: 4, day: 7 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pyhapaivat.set('2023-4-9', { pvm: { year: 2023, month: 4, day: 9 }, tyyppi: PyhapaivanTyyppi.PAASIAISSUNNUNTAI })
    this.pyhapaivat.set('2023-4-10', { pvm: { year: 2023, month: 4, day: 10 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pyhapaivat.set('2023-5-1', { pvm: { year: 2023, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pyhapaivat.set('2023-5-18', { pvm: { year: 2023, month: 5, day: 18 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pyhapaivat.set('2023-5-21', { pvm: { year: 2023, month: 5, day: 21 }, tyyppi: PyhapaivanTyyppi.KAATUNEIDEN_MUISTOPAIVA })
    this.pyhapaivat.set('2023-5-28', { pvm: { year: 2023, month: 5, day: 28 }, tyyppi: PyhapaivanTyyppi.HELLUNTAIPAIVA })
    this.pyhapaivat.set('2023-6-4', { pvm: { year: 2023, month: 6, day: 4 }, tyyppi: PyhapaivanTyyppi.PYHAN_KOLMINAISUUDEN_PAIVA })
    this.pyhapaivat.set('2023-6-23', { pvm: { year: 2023, month: 6, day: 23 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pyhapaivat.set('2023-6-24', { pvm: { year: 2023, month: 6, day: 24 }, tyyppi: PyhapaivanTyyppi.JUHANNUSPAIVA })
    this.pyhapaivat.set('2023-7-9', { pvm: { year: 2023, month: 7, day: 9 }, tyyppi: PyhapaivanTyyppi.APOSTOLIEN_PAIVA })
    this.pyhapaivat.set('2023-7-23', { pvm: { year: 2023, month: 7, day: 23 }, tyyppi: PyhapaivanTyyppi.KRISTUSSUNNUNTAI })
    this.pyhapaivat.set('2023-10-1', { pvm: { year: 2023, month: 10, day: 1 }, tyyppi: PyhapaivanTyyppi.MIKKELINPAIVA })
    this.pyhapaivat.set('2023-11-4', { pvm: { year: 2023, month: 11, day: 4 }, tyyppi: PyhapaivanTyyppi.PYHAINPAIVA })
    this.pyhapaivat.set('2023-11-26', { pvm: { year: 2023, month: 11, day: 26 }, tyyppi: PyhapaivanTyyppi.TUOMIOSUNNUNTAI })
    this.pyhapaivat.set('2023-12-6', { pvm: { year: 2023, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pyhapaivat.set('2023-12-24', { pvm: { year: 2023, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pyhapaivat.set('2023-12-25', { pvm: { year: 2023, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pyhapaivat.set('2023-12-26', { pvm: { year: 2023, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })
    this.pyhapaivat.set('2024-1-1', { pvm: { year: 2024, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pyhapaivat.set('2024-1-6', { pvm: { year: 2024, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pyhapaivat.set('2024-2-4', { pvm: { year: 2024, month: 2, day: 4 }, tyyppi: PyhapaivanTyyppi.KYNTTILAPAIVA })
    this.pyhapaivat.set('2024-2-11', { pvm: { year: 2024, month: 2, day: 11 }, tyyppi: PyhapaivanTyyppi.KYNTTILAPAIVA })
    this.pyhapaivat.set('2024-3-17', { pvm: { year: 2024, month: 3, day: 17 }, tyyppi: PyhapaivanTyyppi.MARIAN_ILMESTYSPAIVA })
    this.pyhapaivat.set('2024-3-24', { pvm: { year: 2024, month: 3, day: 24 }, tyyppi: PyhapaivanTyyppi.PALMUSUNNUNTAI })
    this.pyhapaivat.set('2024-3-29', { pvm: { year: 2024, month: 3, day: 29 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pyhapaivat.set('2024-3-31', { pvm: { year: 2024, month: 3, day: 31 }, tyyppi: PyhapaivanTyyppi.PAASIAISSUNNUNTAI })
    this.pyhapaivat.set('2024-4-1', { pvm: { year: 2024, month: 4, day: 1 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pyhapaivat.set('2024-5-1', { pvm: { year: 2024, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pyhapaivat.set('2024-5-9', { pvm: { year: 2024, month: 5, day: 9 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pyhapaivat.set('2024-5-19', { pvm: { year: 2024, month: 5, day: 19 }, tyyppi: PyhapaivanTyyppi.HELLUNTAIPAIVA })
    this.pyhapaivat.set('2024-5-19', { pvm: { year: 2024, month: 5, day: 19 }, tyyppi: PyhapaivanTyyppi.KAATUNEIDEN_MUISTOPAIVA })
    this.pyhapaivat.set('2024-5-26', { pvm: { year: 2024, month: 5, day: 26 }, tyyppi: PyhapaivanTyyppi.PYHAN_KOLMINAISUUDEN_PAIVA })
    this.pyhapaivat.set('2024-6-21', { pvm: { year: 2024, month: 6, day: 21 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pyhapaivat.set('2024-6-22', { pvm: { year: 2024, month: 6, day: 22 }, tyyppi: PyhapaivanTyyppi.JUHANNUSPAIVA })
    this.pyhapaivat.set('2024-6-30', { pvm: { year: 2024, month: 6, day: 30 }, tyyppi: PyhapaivanTyyppi.APOSTOLIEN_PAIVA })
    this.pyhapaivat.set('2024-7-14', { pvm: { year: 2024, month: 7, day: 14 }, tyyppi: PyhapaivanTyyppi.KRISTUSSUNNUNTAI })
    this.pyhapaivat.set('2024-9-29', { pvm: { year: 2024, month: 9, day: 29 }, tyyppi: PyhapaivanTyyppi.MIKKELINPAIVA })
    this.pyhapaivat.set('2024-11-2', { pvm: { year: 2024, month: 11, day: 2 }, tyyppi: PyhapaivanTyyppi.PYHAINPAIVA })
    this.pyhapaivat.set('2024-11-24', { pvm: { year: 2024, month: 11, day: 24 }, tyyppi: PyhapaivanTyyppi.TUOMIOSUNNUNTAI })
    this.pyhapaivat.set('2024-12-6', { pvm: { year: 2024, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pyhapaivat.set('2024-12-24', { pvm: { year: 2024, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pyhapaivat.set('2024-12-25', { pvm: { year: 2024, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pyhapaivat.set('2024-12-26', { pvm: { year: 2024, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })
    this.pyhapaivat.set('2025-1-1', { pvm: { year: 2025, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pyhapaivat.set('2025-1-6', { pvm: { year: 2025, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pyhapaivat.set('2025-2-2', { pvm: { year: 2025, month: 2, day: 2 }, tyyppi: PyhapaivanTyyppi.KYNTTILAPAIVA })
    this.pyhapaivat.set('2025-3-2', { pvm: { year: 2025, month: 3, day: 2 }, tyyppi: PyhapaivanTyyppi.KYNTTILAPAIVA })
    this.pyhapaivat.set('2025-3-23', { pvm: { year: 2025, month: 3, day: 23 }, tyyppi: PyhapaivanTyyppi.MARIAN_ILMESTYSPAIVA })
    this.pyhapaivat.set('2025-4-13', { pvm: { year: 2025, month: 4, day: 13 }, tyyppi: PyhapaivanTyyppi.PALMUSUNNUNTAI })
    this.pyhapaivat.set('2025-4-18', { pvm: { year: 2025, month: 4, day: 18 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pyhapaivat.set('2025-4-20', { pvm: { year: 2025, month: 4, day: 20 }, tyyppi: PyhapaivanTyyppi.PAASIAISSUNNUNTAI })
    this.pyhapaivat.set('2025-4-21', { pvm: { year: 2025, month: 4, day: 21 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pyhapaivat.set('2025-5-1', { pvm: { year: 2025, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pyhapaivat.set('2025-5-18', { pvm: { year: 2025, month: 5, day: 18 }, tyyppi: PyhapaivanTyyppi.KAATUNEIDEN_MUISTOPAIVA })
    this.pyhapaivat.set('2025-5-29', { pvm: { year: 2025, month: 5, day: 29 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pyhapaivat.set('2025-6-8', { pvm: { year: 2025, month: 6, day: 8 }, tyyppi: PyhapaivanTyyppi.HELLUNTAIPAIVA })
    this.pyhapaivat.set('2025-6-15', { pvm: { year: 2025, month: 6, day: 15 }, tyyppi: PyhapaivanTyyppi.PYHAN_KOLMINAISUUDEN_PAIVA })
    this.pyhapaivat.set('2025-6-20', { pvm: { year: 2025, month: 6, day: 20 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pyhapaivat.set('2025-6-21', { pvm: { year: 2025, month: 6, day: 21 }, tyyppi: PyhapaivanTyyppi.JUHANNUSPAIVA })
    this.pyhapaivat.set('2025-7-20', { pvm: { year: 2025, month: 7, day: 20 }, tyyppi: PyhapaivanTyyppi.APOSTOLIEN_PAIVA })
    this.pyhapaivat.set('2025-8-3', { pvm: { year: 2025, month: 8, day: 3 }, tyyppi: PyhapaivanTyyppi.KRISTUSSUNNUNTAI })
    this.pyhapaivat.set('2025-10-5', { pvm: { year: 2025, month: 10, day: 5 }, tyyppi: PyhapaivanTyyppi.MIKKELINPAIVA })
    this.pyhapaivat.set('2025-11-1', { pvm: { year: 2025, month: 11, day: 1 }, tyyppi: PyhapaivanTyyppi.PYHAINPAIVA })
    this.pyhapaivat.set('2025-11-23', { pvm: { year: 2025, month: 11, day: 23 }, tyyppi: PyhapaivanTyyppi.TUOMIOSUNNUNTAI })
    this.pyhapaivat.set('2025-12-6', { pvm: { year: 2025, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pyhapaivat.set('2025-12-24', { pvm: { year: 2025, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pyhapaivat.set('2025-12-25', { pvm: { year: 2025, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pyhapaivat.set('2025-12-26', { pvm: { year: 2025, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })

    // 2026
    this.pyhapaivat.set('2026-1-1', { pvm: { year: 2026, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pyhapaivat.set('2026-1-6', { pvm: { year: 2026, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pyhapaivat.set('2026-2-8', { pvm: { year: 2026, month: 2, day: 8 }, tyyppi: PyhapaivanTyyppi.KYNTTILAPAIVA })
    this.pyhapaivat.set('2026-3-22', { pvm: { year: 2026, month: 3, day: 22 }, tyyppi: PyhapaivanTyyppi.MARIAN_ILMESTYSPAIVA })
    this.pyhapaivat.set('2026-3-29', { pvm: { year: 2026, month: 3, day: 29 }, tyyppi: PyhapaivanTyyppi.PALMUSUNNUNTAI })
    this.pyhapaivat.set('2026-4-3', { pvm: { year: 2026, month: 4, day: 3 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pyhapaivat.set('2026-4-5', { pvm: { year: 2026, month: 4, day: 5 }, tyyppi: PyhapaivanTyyppi.PAASIAISSUNNUNTAI })
    this.pyhapaivat.set('2026-4-6', { pvm: { year: 2026, month: 4, day: 6 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pyhapaivat.set('2026-5-1', { pvm: { year: 2026, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pyhapaivat.set('2026-5-17', { pvm: { year: 2026, month: 5, day: 17 }, tyyppi: PyhapaivanTyyppi.KAATUNEIDEN_MUISTOPAIVA })
    this.pyhapaivat.set('2026-5-14', { pvm: { year: 2026, month: 5, day: 14 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pyhapaivat.set('2026-5-24', { pvm: { year: 2026, month: 5, day: 24 }, tyyppi: PyhapaivanTyyppi.HELLUNTAIPAIVA })
    this.pyhapaivat.set('2026-5-31', { pvm: { year: 2026, month: 5, day: 31 }, tyyppi: PyhapaivanTyyppi.PYHAN_KOLMINAISUUDEN_PAIVA })
    this.pyhapaivat.set('2026-6-19', { pvm: { year: 2026, month: 6, day: 19 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pyhapaivat.set('2026-6-20', { pvm: { year: 2026, month: 6, day: 20 }, tyyppi: PyhapaivanTyyppi.JUHANNUSPAIVA })
    this.pyhapaivat.set('2026-7-5', { pvm: { year: 2026, month: 7, day: 5 }, tyyppi: PyhapaivanTyyppi.APOSTOLIEN_PAIVA })
    this.pyhapaivat.set('2026-7-19', { pvm: { year: 2026, month: 7, day: 19 }, tyyppi: PyhapaivanTyyppi.KRISTUSSUNNUNTAI })
    this.pyhapaivat.set('2026-10-4', { pvm: { year: 2026, month: 10, day: 4 }, tyyppi: PyhapaivanTyyppi.MIKKELINPAIVA })
    this.pyhapaivat.set('2026-11-22', { pvm: { year: 2026, month: 11, day: 22 }, tyyppi: PyhapaivanTyyppi.TUOMIOSUNNUNTAI })
    this.pyhapaivat.set('2026-10-31', { pvm: { year: 2026, month: 10, day: 31 }, tyyppi: PyhapaivanTyyppi.PYHAINPAIVA })
    this.pyhapaivat.set('2026-12-6', { pvm: { year: 2026, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pyhapaivat.set('2026-12-24', { pvm: { year: 2026, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pyhapaivat.set('2026-12-25', { pvm: { year: 2026, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pyhapaivat.set('2026-12-26', { pvm: { year: 2026, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })

    // 2027
    this.pyhapaivat.set('2027-1-1', { pvm: { year: 2027, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pyhapaivat.set('2027-1-6', { pvm: { year: 2027, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pyhapaivat.set('2027-1-31', { pvm: { year: 2027, month: 1, day: 31 }, tyyppi: PyhapaivanTyyppi.KYNTTILAPAIVA })
    this.pyhapaivat.set('2027-3-28', { pvm: { year: 2027, month: 3, day: 28 }, tyyppi: PyhapaivanTyyppi.MARIAN_ILMESTYSPAIVA })
    this.pyhapaivat.set('2027-3-21', { pvm: { year: 2027, month: 3, day: 21 }, tyyppi: PyhapaivanTyyppi.PALMUSUNNUNTAI })
    this.pyhapaivat.set('2027-3-26', { pvm: { year: 2027, month: 3, day: 26 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pyhapaivat.set('2027-3-28', { pvm: { year: 2027, month: 3, day: 28 }, tyyppi: PyhapaivanTyyppi.PAASIAISSUNNUNTAI })
    this.pyhapaivat.set('2027-3-29', { pvm: { year: 2027, month: 3, day: 29 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pyhapaivat.set('2027-5-1', { pvm: { year: 2027, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pyhapaivat.set('2027-5-16', { pvm: { year: 2027, month: 5, day: 16 }, tyyppi: PyhapaivanTyyppi.KAATUNEIDEN_MUISTOPAIVA })
    this.pyhapaivat.set('2027-5-6', { pvm: { year: 2027, month: 5, day: 6 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pyhapaivat.set('2027-5-16', { pvm: { year: 2027, month: 5, day: 16 }, tyyppi: PyhapaivanTyyppi.HELLUNTAIPAIVA })
    this.pyhapaivat.set('2027-5-23', { pvm: { year: 2027, month: 5, day: 23 }, tyyppi: PyhapaivanTyyppi.PYHAN_KOLMINAISUUDEN_PAIVA })
    this.pyhapaivat.set('2027-6-25', { pvm: { year: 2027, month: 6, day: 25 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pyhapaivat.set('2027-6-26', { pvm: { year: 2027, month: 6, day: 26 }, tyyppi: PyhapaivanTyyppi.JUHANNUSPAIVA })
    this.pyhapaivat.set('2027-6-27', { pvm: { year: 2027, month: 6, day: 27 }, tyyppi: PyhapaivanTyyppi.APOSTOLIEN_PAIVA })
    this.pyhapaivat.set('2027-7-11', { pvm: { year: 2027, month: 7, day: 11 }, tyyppi: PyhapaivanTyyppi.KRISTUSSUNNUNTAI })
    this.pyhapaivat.set('2027-10-3', { pvm: { year: 2027, month: 10, day: 3 }, tyyppi: PyhapaivanTyyppi.MIKKELINPAIVA })
    this.pyhapaivat.set('2027-11-6', { pvm: { year: 2027, month: 11, day: 6 }, tyyppi: PyhapaivanTyyppi.PYHAINPAIVA })
    this.pyhapaivat.set('2027-11-21', { pvm: { year: 2027, month: 11, day: 21 }, tyyppi: PyhapaivanTyyppi.TUOMIOSUNNUNTAI })
    this.pyhapaivat.set('2027-12-6', { pvm: { year: 2027, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pyhapaivat.set('2027-12-24', { pvm: { year: 2027, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pyhapaivat.set('2027-12-25', { pvm: { year: 2027, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pyhapaivat.set('2027-12-26', { pvm: { year: 2027, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })

    // 2028
    this.pyhapaivat.set('2028-1-1', { pvm: { year: 2028, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pyhapaivat.set('2028-1-6', { pvm: { year: 2028, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pyhapaivat.set('2028-2-6', { pvm: { year: 2028, month: 2, day: 6 }, tyyppi: PyhapaivanTyyppi.KYNTTILAPAIVA })
    this.pyhapaivat.set('2028-3-26', { pvm: { year: 2028, month: 3, day: 26 }, tyyppi: PyhapaivanTyyppi.MARIAN_ILMESTYSPAIVA })
    this.pyhapaivat.set('2028-4-9', { pvm: { year: 2028, month: 4, day: 9 }, tyyppi: PyhapaivanTyyppi.PALMUSUNNUNTAI })
    this.pyhapaivat.set('2028-4-14', { pvm: { year: 2028, month: 4, day: 14 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pyhapaivat.set('2028-4-16', { pvm: { year: 2028, month: 4, day: 16 }, tyyppi: PyhapaivanTyyppi.PAASIAISSUNNUNTAI })
    this.pyhapaivat.set('2028-4-17', { pvm: { year: 2028, month: 4, day: 17 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pyhapaivat.set('2028-5-1', { pvm: { year: 2028, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pyhapaivat.set('2028-5-21', { pvm: { year: 2028, month: 5, day: 21 }, tyyppi: PyhapaivanTyyppi.KAATUNEIDEN_MUISTOPAIVA })
    this.pyhapaivat.set('2028-5-25', { pvm: { year: 2028, month: 5, day: 25 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pyhapaivat.set('2028-6-4', { pvm: { year: 2028, month: 6, day: 4 }, tyyppi: PyhapaivanTyyppi.HELLUNTAIPAIVA })
    this.pyhapaivat.set('2028-6-11', { pvm: { year: 2028, month: 6, day: 11 }, tyyppi: PyhapaivanTyyppi.PYHAN_KOLMINAISUUDEN_PAIVA })
    this.pyhapaivat.set('2028-6-23', { pvm: { year: 2028, month: 6, day: 23 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pyhapaivat.set('2028-6-24', { pvm: { year: 2028, month: 6, day: 24 }, tyyppi: PyhapaivanTyyppi.JUHANNUSPAIVA })
    this.pyhapaivat.set('2028-7-16', { pvm: { year: 2028, month: 7, day: 16 }, tyyppi: PyhapaivanTyyppi.APOSTOLIEN_PAIVA })
    this.pyhapaivat.set('2028-7-30', { pvm: { year: 2028, month: 7, day: 30 }, tyyppi: PyhapaivanTyyppi.KRISTUSSUNNUNTAI })
    this.pyhapaivat.set('2028-10-1', { pvm: { year: 2028, month: 10, day: 1 }, tyyppi: PyhapaivanTyyppi.MIKKELINPAIVA })
    this.pyhapaivat.set('2028-11-4', { pvm: { year: 2028, month: 11, day: 4 }, tyyppi: PyhapaivanTyyppi.PYHAINPAIVA })
    this.pyhapaivat.set('2028-11-26', { pvm: { year: 2028, month: 11, day: 26 }, tyyppi: PyhapaivanTyyppi.TUOMIOSUNNUNTAI })
    this.pyhapaivat.set('2028-12-6', { pvm: { year: 2028, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pyhapaivat.set('2028-12-24', { pvm: { year: 2028, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pyhapaivat.set('2028-12-25', { pvm: { year: 2028, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pyhapaivat.set('2028-12-26', { pvm: { year: 2028, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })

    // 2029
    this.pyhapaivat.set('2029-1-1', { pvm: { year: 2029, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pyhapaivat.set('2029-1-6', { pvm: { year: 2029, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pyhapaivat.set('2029-2-4', { pvm: { year: 2029, month: 2, day: 4 }, tyyppi: PyhapaivanTyyppi.KYNTTILAPAIVA })
    this.pyhapaivat.set('2029-3-18', { pvm: { year: 2029, month: 3, day: 18 }, tyyppi: PyhapaivanTyyppi.MARIAN_ILMESTYSPAIVA })
    this.pyhapaivat.set('2029-3-25', { pvm: { year: 2029, month: 3, day: 25 }, tyyppi: PyhapaivanTyyppi.PALMUSUNNUNTAI })
    this.pyhapaivat.set('2029-3-30', { pvm: { year: 2029, month: 3, day: 30 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pyhapaivat.set('2029-4-1', { pvm: { year: 2029, month: 4, day: 1 }, tyyppi: PyhapaivanTyyppi.PAASIAISSUNNUNTAI })
    this.pyhapaivat.set('2029-4-2', { pvm: { year: 2029, month: 4, day: 2 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pyhapaivat.set('2029-5-1', { pvm: { year: 2029, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pyhapaivat.set('2029-5-20', { pvm: { year: 2029, month: 5, day: 20 }, tyyppi: PyhapaivanTyyppi.KAATUNEIDEN_MUISTOPAIVA })
    this.pyhapaivat.set('2029-5-10', { pvm: { year: 2029, month: 5, day: 10 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pyhapaivat.set('2029-5-20', { pvm: { year: 2029, month: 5, day: 20 }, tyyppi: PyhapaivanTyyppi.HELLUNTAIPAIVA })
    this.pyhapaivat.set('2029-5-27', { pvm: { year: 2029, month: 5, day: 27 }, tyyppi: PyhapaivanTyyppi.PYHAN_KOLMINAISUUDEN_PAIVA })
    this.pyhapaivat.set('2029-6-22', { pvm: { year: 2029, month: 6, day: 22 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pyhapaivat.set('2029-6-23', { pvm: { year: 2029, month: 6, day: 23 }, tyyppi: PyhapaivanTyyppi.JUHANNUSPAIVA })
    this.pyhapaivat.set('2029-7-1', { pvm: { year: 2029, month: 7, day: 1 }, tyyppi: PyhapaivanTyyppi.APOSTOLIEN_PAIVA })
    this.pyhapaivat.set('2029-7-15', { pvm: { year: 2029, month: 7, day: 15 }, tyyppi: PyhapaivanTyyppi.KRISTUSSUNNUNTAI })
    this.pyhapaivat.set('2029-9-30', { pvm: { year: 2029, month: 9, day: 30 }, tyyppi: PyhapaivanTyyppi.MIKKELINPAIVA })
    this.pyhapaivat.set('2029-11-3', { pvm: { year: 2029, month: 11, day: 3 }, tyyppi: PyhapaivanTyyppi.PYHAINPAIVA })
    this.pyhapaivat.set('2029-11-25', { pvm: { year: 2029, month: 11, day: 25 }, tyyppi: PyhapaivanTyyppi.TUOMIOSUNNUNTAI })
    this.pyhapaivat.set('2029-12-6', { pvm: { year: 2029, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pyhapaivat.set('2029-12-24', { pvm: { year: 2029, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pyhapaivat.set('2029-12-25', { pvm: { year: 2029, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pyhapaivat.set('2029-12-26', { pvm: { year: 2029, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })

    // 2030
    this.pyhapaivat.set('2030-1-1', { pvm: { year: 2030, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pyhapaivat.set('2030-1-6', { pvm: { year: 2030, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pyhapaivat.set('2030-2-3', { pvm: { year: 2030, month: 2, day: 3 }, tyyppi: PyhapaivanTyyppi.KYNTTILAPAIVA })
    this.pyhapaivat.set('2030-3-24', { pvm: { year: 2030, month: 3, day: 24 }, tyyppi: PyhapaivanTyyppi.MARIAN_ILMESTYSPAIVA })
    this.pyhapaivat.set('2030-4-14', { pvm: { year: 2030, month: 4, day: 14 }, tyyppi: PyhapaivanTyyppi.PALMUSUNNUNTAI })
    this.pyhapaivat.set('2030-4-19', { pvm: { year: 2030, month: 4, day: 19 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pyhapaivat.set('2030-4-21', { pvm: { year: 2030, month: 4, day: 21 }, tyyppi: PyhapaivanTyyppi.PAASIAISSUNNUNTAI })
    this.pyhapaivat.set('2030-4-22', { pvm: { year: 2030, month: 4, day: 22 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pyhapaivat.set('2030-5-1', { pvm: { year: 2030, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pyhapaivat.set('2030-5-19', { pvm: { year: 2030, month: 5, day: 19 }, tyyppi: PyhapaivanTyyppi.KAATUNEIDEN_MUISTOPAIVA })
    this.pyhapaivat.set('2030-5-30', { pvm: { year: 2030, month: 5, day: 30 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pyhapaivat.set('2030-6-9', { pvm: { year: 2030, month: 6, day: 9 }, tyyppi: PyhapaivanTyyppi.HELLUNTAIPAIVA })
    this.pyhapaivat.set('2030-6-16', { pvm: { year: 2030, month: 6, day: 16 }, tyyppi: PyhapaivanTyyppi.PYHAN_KOLMINAISUUDEN_PAIVA })
    this.pyhapaivat.set('2030-6-21', { pvm: { year: 2030, month: 6, day: 21 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pyhapaivat.set('2030-6-22', { pvm: { year: 2030, month: 6, day: 22 }, tyyppi: PyhapaivanTyyppi.JUHANNUSPAIVA })
    this.pyhapaivat.set('2030-7-21', { pvm: { year: 2030, month: 7, day: 21 }, tyyppi: PyhapaivanTyyppi.APOSTOLIEN_PAIVA })
    this.pyhapaivat.set('2030-8-4', { pvm: { year: 2030, month: 8, day: 4 }, tyyppi: PyhapaivanTyyppi.KRISTUSSUNNUNTAI })
    this.pyhapaivat.set('2030-9-29', { pvm: { year: 2030, month: 9, day: 29 }, tyyppi: PyhapaivanTyyppi.MIKKELINPAIVA })
    this.pyhapaivat.set('2030-11-2', { pvm: { year: 2030, month: 11, day: 2 }, tyyppi: PyhapaivanTyyppi.PYHAINPAIVA })
    this.pyhapaivat.set('2030-11-24', { pvm: { year: 2030, month: 11, day: 24 }, tyyppi: PyhapaivanTyyppi.TUOMIOSUNNUNTAI })
    this.pyhapaivat.set('2030-12-6', { pvm: { year: 2030, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pyhapaivat.set('2030-12-24', { pvm: { year: 2030, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pyhapaivat.set('2030-12-25', { pvm: { year: 2030, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pyhapaivat.set('2030-12-26', { pvm: { year: 2030, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })

    // 2031
    this.pyhapaivat.set('2031-1-1', { pvm: { year: 2031, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pyhapaivat.set('2031-1-6', { pvm: { year: 2031, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pyhapaivat.set('2031-2-2', { pvm: { year: 2031, month: 2, day: 2 }, tyyppi: PyhapaivanTyyppi.KYNTTILAPAIVA })
    this.pyhapaivat.set('2031-3-2', { pvm: { year: 2031, month: 3, day: 2 }, tyyppi: PyhapaivanTyyppi.KYNTTILAPAIVA })
    this.pyhapaivat.set('2031-3-23', { pvm: { year: 2031, month: 3, day: 23 }, tyyppi: PyhapaivanTyyppi.MARIAN_ILMESTYSPAIVA })
    this.pyhapaivat.set('2031-4-6', { pvm: { year: 2031, month: 4, day: 6 }, tyyppi: PyhapaivanTyyppi.PALMUSUNNUNTAI })
    this.pyhapaivat.set('2031-4-11', { pvm: { year: 2031, month: 4, day: 11 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pyhapaivat.set('2031-4-13', { pvm: { year: 2031, month: 4, day: 13 }, tyyppi: PyhapaivanTyyppi.PAASIAISSUNNUNTAI })
    this.pyhapaivat.set('2031-4-14', { pvm: { year: 2031, month: 4, day: 14 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pyhapaivat.set('2031-5-1', { pvm: { year: 2031, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pyhapaivat.set('2031-5-18', { pvm: { year: 2031, month: 5, day: 18 }, tyyppi: PyhapaivanTyyppi.KAATUNEIDEN_MUISTOPAIVA })
    this.pyhapaivat.set('2031-5-22', { pvm: { year: 2031, month: 5, day: 22 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pyhapaivat.set('2031-6-1', { pvm: { year: 2031, month: 6, day: 1 }, tyyppi: PyhapaivanTyyppi.HELLUNTAIPAIVA })
    this.pyhapaivat.set('2031-6-8', { pvm: { year: 2031, month: 6, day: 8 }, tyyppi: PyhapaivanTyyppi.PYHAN_KOLMINAISUUDEN_PAIVA })
    this.pyhapaivat.set('2031-6-20', { pvm: { year: 2031, month: 6, day: 20 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pyhapaivat.set('2031-6-21', { pvm: { year: 2031, month: 6, day: 21 }, tyyppi: PyhapaivanTyyppi.JUHANNUSPAIVA })
    this.pyhapaivat.set('2031-7-13', { pvm: { year: 2031, month: 7, day: 13 }, tyyppi: PyhapaivanTyyppi.APOSTOLIEN_PAIVA })
    this.pyhapaivat.set('2031-7-27', { pvm: { year: 2031, month: 7, day: 27 }, tyyppi: PyhapaivanTyyppi.KRISTUSSUNNUNTAI })
    this.pyhapaivat.set('2031-10-5', { pvm: { year: 2031, month: 10, day: 5 }, tyyppi: PyhapaivanTyyppi.MIKKELINPAIVA })
    this.pyhapaivat.set('2031-11-1', { pvm: { year: 2031, month: 11, day: 1 }, tyyppi: PyhapaivanTyyppi.PYHAINPAIVA })
    this.pyhapaivat.set('2031-11-23', { pvm: { year: 2031, month: 11, day: 23 }, tyyppi: PyhapaivanTyyppi.TUOMIOSUNNUNTAI })
    this.pyhapaivat.set('2031-12-6', { pvm: { year: 2031, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pyhapaivat.set('2031-12-24', { pvm: { year: 2031, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pyhapaivat.set('2031-12-25', { pvm: { year: 2031, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pyhapaivat.set('2031-12-26', { pvm: { year: 2031, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })

    // 2032
    this.pyhapaivat.set('2032-1-1', { pvm: { year: 2032, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pyhapaivat.set('2032-1-6', { pvm: { year: 2032, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pyhapaivat.set('2032-2-8', { pvm: { year: 2032, month: 2, day: 8 }, tyyppi: PyhapaivanTyyppi.KYNTTILAPAIVA })
    this.pyhapaivat.set('2032-3-28', { pvm: { year: 2032, month: 3, day: 28 }, tyyppi: PyhapaivanTyyppi.MARIAN_ILMESTYSPAIVA })
    this.pyhapaivat.set('2032-3-21', { pvm: { year: 2032, month: 3, day: 21 }, tyyppi: PyhapaivanTyyppi.PALMUSUNNUNTAI })
    this.pyhapaivat.set('2032-3-26', { pvm: { year: 2032, month: 3, day: 26 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pyhapaivat.set('2032-3-28', { pvm: { year: 2032, month: 3, day: 28 }, tyyppi: PyhapaivanTyyppi.PAASIAISSUNNUNTAI })
    this.pyhapaivat.set('2032-3-29', { pvm: { year: 2032, month: 3, day: 29 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pyhapaivat.set('2032-5-1', { pvm: { year: 2032, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pyhapaivat.set('2032-5-16', { pvm: { year: 2032, month: 5, day: 16 }, tyyppi: PyhapaivanTyyppi.KAATUNEIDEN_MUISTOPAIVA })
    this.pyhapaivat.set('2032-5-6', { pvm: { year: 2032, month: 5, day: 6 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pyhapaivat.set('2032-5-16', { pvm: { year: 2032, month: 5, day: 16 }, tyyppi: PyhapaivanTyyppi.HELLUNTAIPAIVA })
    this.pyhapaivat.set('2032-5-23', { pvm: { year: 2032, month: 5, day: 23 }, tyyppi: PyhapaivanTyyppi.PYHAN_KOLMINAISUUDEN_PAIVA })
    this.pyhapaivat.set('2032-6-25', { pvm: { year: 2032, month: 6, day: 25 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pyhapaivat.set('2032-6-26', { pvm: { year: 2032, month: 6, day: 26 }, tyyppi: PyhapaivanTyyppi.JUHANNUSPAIVA })
    this.pyhapaivat.set('2032-6-27', { pvm: { year: 2032, month: 6, day: 27 }, tyyppi: PyhapaivanTyyppi.APOSTOLIEN_PAIVA })
    this.pyhapaivat.set('2032-7-11', { pvm: { year: 2032, month: 7, day: 11 }, tyyppi: PyhapaivanTyyppi.KRISTUSSUNNUNTAI })
    this.pyhapaivat.set('2032-10-3', { pvm: { year: 2032, month: 10, day: 3 }, tyyppi: PyhapaivanTyyppi.MIKKELINPAIVA })
    this.pyhapaivat.set('2032-11-6', { pvm: { year: 2032, month: 11, day: 6 }, tyyppi: PyhapaivanTyyppi.PYHAINPAIVA })
    this.pyhapaivat.set('2032-11-21', { pvm: { year: 2032, month: 11, day: 21 }, tyyppi: PyhapaivanTyyppi.TUOMIOSUNNUNTAI })
    this.pyhapaivat.set('2032-12-6', { pvm: { year: 2032, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pyhapaivat.set('2032-12-24', { pvm: { year: 2032, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pyhapaivat.set('2032-12-25', { pvm: { year: 2032, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pyhapaivat.set('2032-12-26', { pvm: { year: 2032, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })

    // 2033
    this.pyhapaivat.set('2033-1-1', { pvm: { year: 2033, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pyhapaivat.set('2033-1-6', { pvm: { year: 2033, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pyhapaivat.set('2033-2-6', { pvm: { year: 2033, month: 2, day: 6 }, tyyppi: PyhapaivanTyyppi.KYNTTILAPAIVA })
    this.pyhapaivat.set('2033-3-27', { pvm: { year: 2033, month: 3, day: 27 }, tyyppi: PyhapaivanTyyppi.MARIAN_ILMESTYSPAIVA })
    this.pyhapaivat.set('2033-4-10', { pvm: { year: 2033, month: 4, day: 10 }, tyyppi: PyhapaivanTyyppi.PALMUSUNNUNTAI })
    this.pyhapaivat.set('2033-4-15', { pvm: { year: 2033, month: 4, day: 15 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pyhapaivat.set('2033-4-17', { pvm: { year: 2033, month: 4, day: 17 }, tyyppi: PyhapaivanTyyppi.PAASIAISSUNNUNTAI })
    this.pyhapaivat.set('2033-4-18', { pvm: { year: 2033, month: 4, day: 18 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pyhapaivat.set('2033-5-1', { pvm: { year: 2033, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pyhapaivat.set('2033-5-15', { pvm: { year: 2033, month: 5, day: 15 }, tyyppi: PyhapaivanTyyppi.KAATUNEIDEN_MUISTOPAIVA })
    this.pyhapaivat.set('2033-5-26', { pvm: { year: 2033, month: 5, day: 26 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pyhapaivat.set('2033-6-5', { pvm: { year: 2033, month: 6, day: 5 }, tyyppi: PyhapaivanTyyppi.HELLUNTAIPAIVA })
    this.pyhapaivat.set('2033-6-12', { pvm: { year: 2033, month: 6, day: 12 }, tyyppi: PyhapaivanTyyppi.PYHAN_KOLMINAISUUDEN_PAIVA })
    this.pyhapaivat.set('2033-6-24', { pvm: { year: 2033, month: 6, day: 24 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pyhapaivat.set('2033-6-25', { pvm: { year: 2033, month: 6, day: 25 }, tyyppi: PyhapaivanTyyppi.JUHANNUSPAIVA })
    this.pyhapaivat.set('2033-7-17', { pvm: { year: 2033, month: 7, day: 17 }, tyyppi: PyhapaivanTyyppi.APOSTOLIEN_PAIVA })
    this.pyhapaivat.set('2033-7-31', { pvm: { year: 2033, month: 7, day: 31 }, tyyppi: PyhapaivanTyyppi.KRISTUSSUNNUNTAI })
    this.pyhapaivat.set('2033-10-2', { pvm: { year: 2033, month: 10, day: 2 }, tyyppi: PyhapaivanTyyppi.MIKKELINPAIVA })
    this.pyhapaivat.set('2033-11-5', { pvm: { year: 2033, month: 11, day: 5 }, tyyppi: PyhapaivanTyyppi.PYHAINPAIVA })
    this.pyhapaivat.set('2033-11-20', { pvm: { year: 2033, month: 11, day: 20 }, tyyppi: PyhapaivanTyyppi.TUOMIOSUNNUNTAI })
    this.pyhapaivat.set('2033-12-6', { pvm: { year: 2033, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pyhapaivat.set('2033-12-24', { pvm: { year: 2033, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pyhapaivat.set('2033-12-25', { pvm: { year: 2033, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pyhapaivat.set('2033-12-26', { pvm: { year: 2033, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })

    // 2034
    this.pyhapaivat.set('2034-1-1', { pvm: { year: 2034, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pyhapaivat.set('2034-1-6', { pvm: { year: 2034, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pyhapaivat.set('2034-2-5', { pvm: { year: 2034, month: 2, day: 5 }, tyyppi: PyhapaivanTyyppi.KYNTTILAPAIVA })
    this.pyhapaivat.set('2034-3-26', { pvm: { year: 2034, month: 3, day: 26 }, tyyppi: PyhapaivanTyyppi.MARIAN_ILMESTYSPAIVA })
    this.pyhapaivat.set('2034-4-2', { pvm: { year: 2034, month: 4, day: 2 }, tyyppi: PyhapaivanTyyppi.PALMUSUNNUNTAI })
    this.pyhapaivat.set('2034-4-7', { pvm: { year: 2034, month: 4, day: 7 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pyhapaivat.set('2034-4-9', { pvm: { year: 2034, month: 4, day: 9 }, tyyppi: PyhapaivanTyyppi.PAASIAISSUNNUNTAI })
    this.pyhapaivat.set('2034-4-10', { pvm: { year: 2034, month: 4, day: 10 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pyhapaivat.set('2034-5-1', { pvm: { year: 2034, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pyhapaivat.set('2034-5-21', { pvm: { year: 2034, month: 5, day: 21 }, tyyppi: PyhapaivanTyyppi.KAATUNEIDEN_MUISTOPAIVA })
    this.pyhapaivat.set('2034-4-18', { pvm: { year: 2034, month: 4, day: 18 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pyhapaivat.set('2034-5-28', { pvm: { year: 2034, month: 5, day: 28 }, tyyppi: PyhapaivanTyyppi.HELLUNTAIPAIVA })
    this.pyhapaivat.set('2034-6-4', { pvm: { year: 2034, month: 6, day: 4 }, tyyppi: PyhapaivanTyyppi.PYHAN_KOLMINAISUUDEN_PAIVA })
    this.pyhapaivat.set('2034-6-23', { pvm: { year: 2034, month: 6, day: 23 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pyhapaivat.set('2034-6-24', { pvm: { year: 2034, month: 6, day: 24 }, tyyppi: PyhapaivanTyyppi.JUHANNUSPAIVA })
    this.pyhapaivat.set('2034-7-9', { pvm: { year: 2034, month: 7, day: 9 }, tyyppi: PyhapaivanTyyppi.APOSTOLIEN_PAIVA })
    this.pyhapaivat.set('2034-7-23', { pvm: { year: 2034, month: 7, day: 23 }, tyyppi: PyhapaivanTyyppi.KRISTUSSUNNUNTAI })
    this.pyhapaivat.set('2034-10-1', { pvm: { year: 2034, month: 10, day: 1 }, tyyppi: PyhapaivanTyyppi.MIKKELINPAIVA })
    this.pyhapaivat.set('2034-11-4', { pvm: { year: 2034, month: 11, day: 4 }, tyyppi: PyhapaivanTyyppi.PYHAINPAIVA })
    this.pyhapaivat.set('2034-11-26', { pvm: { year: 2034, month: 11, day: 26 }, tyyppi: PyhapaivanTyyppi.TUOMIOSUNNUNTAI })
    this.pyhapaivat.set('2034-12-6', { pvm: { year: 2034, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pyhapaivat.set('2034-12-24', { pvm: { year: 2034, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pyhapaivat.set('2034-12-25', { pvm: { year: 2034, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pyhapaivat.set('2034-12-26', { pvm: { year: 2034, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })

    // 2035
    this.pyhapaivat.set('2035-1-1', { pvm: { year: 2035, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pyhapaivat.set('2035-1-6', { pvm: { year: 2035, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pyhapaivat.set('2035-2-4', { pvm: { year: 2035, month: 2, day: 4 }, tyyppi: PyhapaivanTyyppi.KYNTTILAPAIVA })
    this.pyhapaivat.set('2035-3-25', { pvm: { year: 2035, month: 3, day: 25 }, tyyppi: PyhapaivanTyyppi.MARIAN_ILMESTYSPAIVA })
    this.pyhapaivat.set('2035-3-18', { pvm: { year: 2035, month: 3, day: 18 }, tyyppi: PyhapaivanTyyppi.PALMUSUNNUNTAI })
    this.pyhapaivat.set('2035-3-23', { pvm: { year: 2035, month: 3, day: 23 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pyhapaivat.set('2035-3-25', { pvm: { year: 2035, month: 3, day: 25 }, tyyppi: PyhapaivanTyyppi.PAASIAISSUNNUNTAI })
    this.pyhapaivat.set('2035-3-26', { pvm: { year: 2035, month: 3, day: 26 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pyhapaivat.set('2035-5-1', { pvm: { year: 2035, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pyhapaivat.set('2035-5-20', { pvm: { year: 2035, month: 5, day: 20 }, tyyppi: PyhapaivanTyyppi.KAATUNEIDEN_MUISTOPAIVA })
    this.pyhapaivat.set('2035-5-3', { pvm: { year: 2035, month: 5, day: 3 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pyhapaivat.set('2035-5-13', { pvm: { year: 2035, month: 5, day: 13 }, tyyppi: PyhapaivanTyyppi.HELLUNTAIPAIVA })
    this.pyhapaivat.set('2035-5-20', { pvm: { year: 2035, month: 5, day: 20 }, tyyppi: PyhapaivanTyyppi.PYHAN_KOLMINAISUUDEN_PAIVA })
    this.pyhapaivat.set('2035-6-22', { pvm: { year: 2035, month: 6, day: 22 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pyhapaivat.set('2035-6-23', { pvm: { year: 2035, month: 6, day: 23 }, tyyppi: PyhapaivanTyyppi.JUHANNUSPAIVA })
    this.pyhapaivat.set('2035-6-24', { pvm: { year: 2035, month: 6, day: 24 }, tyyppi: PyhapaivanTyyppi.APOSTOLIEN_PAIVA })
    this.pyhapaivat.set('2035-7-8', { pvm: { year: 2035, month: 7, day: 8 }, tyyppi: PyhapaivanTyyppi.KRISTUSSUNNUNTAI })
    this.pyhapaivat.set('2035-9-30', { pvm: { year: 2035, month: 9, day: 30 }, tyyppi: PyhapaivanTyyppi.MIKKELINPAIVA })
    this.pyhapaivat.set('2035-11-3', { pvm: { year: 2035, month: 11, day: 3 }, tyyppi: PyhapaivanTyyppi.PYHAINPAIVA })
    this.pyhapaivat.set('2035-11-25', { pvm: { year: 2035, month: 11, day: 25 }, tyyppi: PyhapaivanTyyppi.TUOMIOSUNNUNTAI })
    this.pyhapaivat.set('2035-12-6', { pvm: { year: 2035, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pyhapaivat.set('2035-12-24', { pvm: { year: 2035, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pyhapaivat.set('2035-12-25', { pvm: { year: 2035, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pyhapaivat.set('2035-12-26', { pvm: { year: 2035, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })

    // 2036
    this.pyhapaivat.set('2036-1-1', { pvm: { year: 2036, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pyhapaivat.set('2036-1-6', { pvm: { year: 2036, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pyhapaivat.set('2036-2-3', { pvm: { year: 2036, month: 2, day: 3 }, tyyppi: PyhapaivanTyyppi.KYNTTILAPAIVA })
    this.pyhapaivat.set('2036-3-23', { pvm: { year: 2036, month: 3, day: 23 }, tyyppi: PyhapaivanTyyppi.MARIAN_ILMESTYSPAIVA })
    this.pyhapaivat.set('2036-4-6', { pvm: { year: 2036, month: 4, day: 6 }, tyyppi: PyhapaivanTyyppi.PALMUSUNNUNTAI })
    this.pyhapaivat.set('2036-4-11', { pvm: { year: 2036, month: 4, day: 11 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pyhapaivat.set('2036-4-13', { pvm: { year: 2036, month: 4, day: 13 }, tyyppi: PyhapaivanTyyppi.PAASIAISSUNNUNTAI })
    this.pyhapaivat.set('2036-4-14', { pvm: { year: 2036, month: 4, day: 14 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pyhapaivat.set('2036-5-1', { pvm: { year: 2036, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pyhapaivat.set('2036-5-18', { pvm: { year: 2036, month: 5, day: 18 }, tyyppi: PyhapaivanTyyppi.KAATUNEIDEN_MUISTOPAIVA })
    this.pyhapaivat.set('2036-5-22', { pvm: { year: 2036, month: 5, day: 22 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pyhapaivat.set('2036-6-1', { pvm: { year: 2036, month: 6, day: 1 }, tyyppi: PyhapaivanTyyppi.HELLUNTAIPAIVA })
    this.pyhapaivat.set('2036-6-8', { pvm: { year: 2036, month: 6, day: 8 }, tyyppi: PyhapaivanTyyppi.PYHAN_KOLMINAISUUDEN_PAIVA })
    this.pyhapaivat.set('2036-6-20', { pvm: { year: 2036, month: 6, day: 20 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pyhapaivat.set('2036-6-21', { pvm: { year: 2036, month: 6, day: 21 }, tyyppi: PyhapaivanTyyppi.JUHANNUSPAIVA })
    this.pyhapaivat.set('2036-7-13', { pvm: { year: 2036, month: 7, day: 13 }, tyyppi: PyhapaivanTyyppi.APOSTOLIEN_PAIVA })
    this.pyhapaivat.set('2036-7-27', { pvm: { year: 2036, month: 7, day: 27 }, tyyppi: PyhapaivanTyyppi.KRISTUSSUNNUNTAI })
    this.pyhapaivat.set('2036-10-5', { pvm: { year: 2036, month: 10, day: 5 }, tyyppi: PyhapaivanTyyppi.MIKKELINPAIVA })
    this.pyhapaivat.set('2036-11-1', { pvm: { year: 2036, month: 11, day: 1 }, tyyppi: PyhapaivanTyyppi.PYHAINPAIVA })
    this.pyhapaivat.set('2036-11-23', { pvm: { year: 2036, month: 11, day: 23 }, tyyppi: PyhapaivanTyyppi.TUOMIOSUNNUNTAI })
    this.pyhapaivat.set('2036-12-6', { pvm: { year: 2036, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pyhapaivat.set('2036-12-24', { pvm: { year: 2036, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pyhapaivat.set('2036-12-25', { pvm: { year: 2036, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pyhapaivat.set('2036-12-26', { pvm: { year: 2036, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })

    // 2037
    this.pyhapaivat.set('2037-1-1', { pvm: { year: 2037, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pyhapaivat.set('2037-1-6', { pvm: { year: 2037, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pyhapaivat.set('2037-2-8', { pvm: { year: 2037, month: 2, day: 8 }, tyyppi: PyhapaivanTyyppi.KYNTTILAPAIVA })
    this.pyhapaivat.set('2037-3-22', { pvm: { year: 2037, month: 3, day: 22 }, tyyppi: PyhapaivanTyyppi.MARIAN_ILMESTYSPAIVA })
    this.pyhapaivat.set('2037-3-29', { pvm: { year: 2037, month: 3, day: 29 }, tyyppi: PyhapaivanTyyppi.PALMUSUNNUNTAI })
    this.pyhapaivat.set('2037-4-3', { pvm: { year: 2037, month: 4, day: 3 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pyhapaivat.set('2037-4-5', { pvm: { year: 2037, month: 4, day: 5 }, tyyppi: PyhapaivanTyyppi.PAASIAISSUNNUNTAI })
    this.pyhapaivat.set('2037-4-6', { pvm: { year: 2037, month: 4, day: 6 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pyhapaivat.set('2037-5-1', { pvm: { year: 2037, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pyhapaivat.set('2037-5-17', { pvm: { year: 2037, month: 5, day: 17 }, tyyppi: PyhapaivanTyyppi.KAATUNEIDEN_MUISTOPAIVA })
    this.pyhapaivat.set('2037-5-14', { pvm: { year: 2037, month: 5, day: 14 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pyhapaivat.set('2037-5-24', { pvm: { year: 2037, month: 5, day: 24 }, tyyppi: PyhapaivanTyyppi.HELLUNTAIPAIVA })
    this.pyhapaivat.set('2037-5-31', { pvm: { year: 2037, month: 5, day: 31 }, tyyppi: PyhapaivanTyyppi.PYHAN_KOLMINAISUUDEN_PAIVA })
    this.pyhapaivat.set('2037-6-19', { pvm: { year: 2037, month: 6, day: 19 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pyhapaivat.set('2037-6-20', { pvm: { year: 2037, month: 6, day: 20 }, tyyppi: PyhapaivanTyyppi.JUHANNUSPAIVA })
    this.pyhapaivat.set('2037-7-5', { pvm: { year: 2037, month: 7, day: 5 }, tyyppi: PyhapaivanTyyppi.APOSTOLIEN_PAIVA })
    this.pyhapaivat.set('2037-7-19', { pvm: { year: 2037, month: 7, day: 19 }, tyyppi: PyhapaivanTyyppi.KRISTUSSUNNUNTAI })
    this.pyhapaivat.set('2037-10-4', { pvm: { year: 2037, month: 10, day: 4 }, tyyppi: PyhapaivanTyyppi.MIKKELINPAIVA })
    this.pyhapaivat.set('2037-10-31', { pvm: { year: 2037, month: 10, day: 31 }, tyyppi: PyhapaivanTyyppi.PYHAINPAIVA })
    this.pyhapaivat.set('2037-11-22', { pvm: { year: 2037, month: 11, day: 22 }, tyyppi: PyhapaivanTyyppi.TUOMIOSUNNUNTAI })
    this.pyhapaivat.set('2037-12-6', { pvm: { year: 2037, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pyhapaivat.set('2037-12-24', { pvm: { year: 2037, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pyhapaivat.set('2037-12-25', { pvm: { year: 2037, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pyhapaivat.set('2037-12-26', { pvm: { year: 2037, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })

    // 2038
    this.pyhapaivat.set('2038-1-1', { pvm: { year: 2038, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pyhapaivat.set('2038-1-6', { pvm: { year: 2038, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pyhapaivat.set('2038-2-7', { pvm: { year: 2038, month: 2, day: 7 }, tyyppi: PyhapaivanTyyppi.KYNTTILAPAIVA })
    this.pyhapaivat.set('2038-3-28', { pvm: { year: 2038, month: 3, day: 28 }, tyyppi: PyhapaivanTyyppi.MARIAN_ILMESTYSPAIVA })
    this.pyhapaivat.set('2038-4-18', { pvm: { year: 2038, month: 4, day: 18 }, tyyppi: PyhapaivanTyyppi.PALMUSUNNUNTAI })
    this.pyhapaivat.set('2038-4-23', { pvm: { year: 2038, month: 4, day: 23 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pyhapaivat.set('2038-4-25', { pvm: { year: 2038, month: 4, day: 25 }, tyyppi: PyhapaivanTyyppi.PAASIAISSUNNUNTAI })
    this.pyhapaivat.set('2038-4-26', { pvm: { year: 2038, month: 4, day: 26 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pyhapaivat.set('2038-5-1', { pvm: { year: 2038, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pyhapaivat.set('2038-5-16', { pvm: { year: 2038, month: 5, day: 16 }, tyyppi: PyhapaivanTyyppi.KAATUNEIDEN_MUISTOPAIVA })
    this.pyhapaivat.set('2038-6-3', { pvm: { year: 2038, month: 6, day: 3 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pyhapaivat.set('2038-6-13', { pvm: { year: 2038, month: 6, day: 13 }, tyyppi: PyhapaivanTyyppi.HELLUNTAIPAIVA })
    this.pyhapaivat.set('2038-6-20', { pvm: { year: 2038, month: 6, day: 20 }, tyyppi: PyhapaivanTyyppi.PYHAN_KOLMINAISUUDEN_PAIVA })
    this.pyhapaivat.set('2038-6-25', { pvm: { year: 2038, month: 6, day: 25 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pyhapaivat.set('2038-6-26', { pvm: { year: 2038, month: 6, day: 26 }, tyyppi: PyhapaivanTyyppi.JUHANNUSPAIVA })
    this.pyhapaivat.set('2038-7-25', { pvm: { year: 2038, month: 7, day: 25 }, tyyppi: PyhapaivanTyyppi.APOSTOLIEN_PAIVA })
    this.pyhapaivat.set('2038-8-8', { pvm: { year: 2038, month: 8, day: 8 }, tyyppi: PyhapaivanTyyppi.KRISTUSSUNNUNTAI })
    this.pyhapaivat.set('2038-10-3', { pvm: { year: 2038, month: 10, day: 3 }, tyyppi: PyhapaivanTyyppi.MIKKELINPAIVA })
    this.pyhapaivat.set('2038-11-6', { pvm: { year: 2038, month: 11, day: 6 }, tyyppi: PyhapaivanTyyppi.PYHAINPAIVA })
    this.pyhapaivat.set('2038-11-21', { pvm: { year: 2038, month: 11, day: 21 }, tyyppi: PyhapaivanTyyppi.TUOMIOSUNNUNTAI })
    this.pyhapaivat.set('2038-12-6', { pvm: { year: 2038, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pyhapaivat.set('2038-12-24', { pvm: { year: 2038, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pyhapaivat.set('2038-12-25', { pvm: { year: 2038, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pyhapaivat.set('2038-12-26', { pvm: { year: 2038, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })

    // 2039
    this.pyhapaivat.set('2039-1-1', { pvm: { year: 2039, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pyhapaivat.set('2039-1-6', { pvm: { year: 2039, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pyhapaivat.set('2039-2-6', { pvm: { year: 2039, month: 2, day: 6 }, tyyppi: PyhapaivanTyyppi.KYNTTILAPAIVA })
    this.pyhapaivat.set('2039-3-27', { pvm: { year: 2039, month: 3, day: 27 }, tyyppi: PyhapaivanTyyppi.MARIAN_ILMESTYSPAIVA })
    this.pyhapaivat.set('2039-4-3', { pvm: { year: 2039, month: 4, day: 3 }, tyyppi: PyhapaivanTyyppi.PALMUSUNNUNTAI })
    this.pyhapaivat.set('2039-4-8', { pvm: { year: 2039, month: 4, day: 8 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pyhapaivat.set('2039-4-10', { pvm: { year: 2039, month: 4, day: 10 }, tyyppi: PyhapaivanTyyppi.PAASIAISSUNNUNTAI })
    this.pyhapaivat.set('2039-4-11', { pvm: { year: 2039, month: 4, day: 11 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pyhapaivat.set('2039-5-1', { pvm: { year: 2039, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pyhapaivat.set('2039-5-15', { pvm: { year: 2039, month: 5, day: 15 }, tyyppi: PyhapaivanTyyppi.KAATUNEIDEN_MUISTOPAIVA })
    this.pyhapaivat.set('2039-5-19', { pvm: { year: 2039, month: 5, day: 19 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pyhapaivat.set('2039-5-29', { pvm: { year: 2039, month: 5, day: 29 }, tyyppi: PyhapaivanTyyppi.HELLUNTAIPAIVA })
    this.pyhapaivat.set('2039-6-5', { pvm: { year: 2039, month: 6, day: 5 }, tyyppi: PyhapaivanTyyppi.PYHAN_KOLMINAISUUDEN_PAIVA })
    this.pyhapaivat.set('2039-6-24', { pvm: { year: 2039, month: 6, day: 24 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pyhapaivat.set('2039-6-25', { pvm: { year: 2039, month: 6, day: 25 }, tyyppi: PyhapaivanTyyppi.JUHANNUSPAIVA })
    this.pyhapaivat.set('2039-7-10', { pvm: { year: 2039, month: 7, day: 10 }, tyyppi: PyhapaivanTyyppi.APOSTOLIEN_PAIVA })
    this.pyhapaivat.set('2039-7-24', { pvm: { year: 2039, month: 7, day: 24 }, tyyppi: PyhapaivanTyyppi.KRISTUSSUNNUNTAI })
    this.pyhapaivat.set('2039-10-2', { pvm: { year: 2039, month: 10, day: 2 }, tyyppi: PyhapaivanTyyppi.MIKKELINPAIVA })
    this.pyhapaivat.set('2039-11-5', { pvm: { year: 2039, month: 11, day: 5 }, tyyppi: PyhapaivanTyyppi.PYHAINPAIVA })
    this.pyhapaivat.set('2039-11-20', { pvm: { year: 2039, month: 11, day: 20 }, tyyppi: PyhapaivanTyyppi.TUOMIOSUNNUNTAI })
    this.pyhapaivat.set('2039-12-6', { pvm: { year: 2039, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pyhapaivat.set('2039-12-24', { pvm: { year: 2039, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pyhapaivat.set('2039-12-25', { pvm: { year: 2039, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pyhapaivat.set('2039-12-26', { pvm: { year: 2039, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })

    // 2040
    this.pyhapaivat.set('2040-1-1', { pvm: { year: 2040, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pyhapaivat.set('2040-1-6', { pvm: { year: 2040, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pyhapaivat.set('2040-2-5', { pvm: { year: 2040, month: 2, day: 5 }, tyyppi: PyhapaivanTyyppi.KYNTTILAPAIVA })
    this.pyhapaivat.set('2040-3-18', { pvm: { year: 2040, month: 3, day: 18 }, tyyppi: PyhapaivanTyyppi.MARIAN_ILMESTYSPAIVA })
    this.pyhapaivat.set('2040-3-25', { pvm: { year: 2040, month: 3, day: 25 }, tyyppi: PyhapaivanTyyppi.PALMUSUNNUNTAI })
    this.pyhapaivat.set('2040-3-30', { pvm: { year: 2040, month: 3, day: 30 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pyhapaivat.set('2040-4-1', { pvm: { year: 2040, month: 4, day: 1 }, tyyppi: PyhapaivanTyyppi.PAASIAISSUNNUNTAI })
    this.pyhapaivat.set('2040-4-2', { pvm: { year: 2040, month: 4, day: 2 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pyhapaivat.set('2040-5-1', { pvm: { year: 2040, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pyhapaivat.set('2040-5-20', { pvm: { year: 2040, month: 5, day: 20 }, tyyppi: PyhapaivanTyyppi.KAATUNEIDEN_MUISTOPAIVA })
    this.pyhapaivat.set('2040-5-10', { pvm: { year: 2040, month: 5, day: 10 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pyhapaivat.set('2040-5-20', { pvm: { year: 2040, month: 5, day: 20 }, tyyppi: PyhapaivanTyyppi.HELLUNTAIPAIVA })
    this.pyhapaivat.set('2040-5-27', { pvm: { year: 2027, month: 5, day: 27 }, tyyppi: PyhapaivanTyyppi.PYHAN_KOLMINAISUUDEN_PAIVA })
    this.pyhapaivat.set('2040-6-22', { pvm: { year: 2040, month: 6, day: 22 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pyhapaivat.set('2040-6-23', { pvm: { year: 2040, month: 6, day: 23 }, tyyppi: PyhapaivanTyyppi.JUHANNUSPAIVA })
    this.pyhapaivat.set('2040-7-1', { pvm: { year: 2040, month: 7, day: 1 }, tyyppi: PyhapaivanTyyppi.APOSTOLIEN_PAIVA })
    this.pyhapaivat.set('2040-7-15', { pvm: { year: 2040, month: 7, day: 15 }, tyyppi: PyhapaivanTyyppi.KRISTUSSUNNUNTAI })
    this.pyhapaivat.set('2040-9-30', { pvm: { year: 2040, month: 9, day: 30 }, tyyppi: PyhapaivanTyyppi.MIKKELINPAIVA })
    this.pyhapaivat.set('2040-11-3', { pvm: { year: 2040, month: 11, day: 3 }, tyyppi: PyhapaivanTyyppi.PYHAINPAIVA })
    this.pyhapaivat.set('2040-11-25', { pvm: { year: 2040, month: 11, day: 25 }, tyyppi: PyhapaivanTyyppi.TUOMIOSUNNUNTAI })
    this.pyhapaivat.set('2040-12-6', { pvm: { year: 2040, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pyhapaivat.set('2040-12-24', { pvm: { year: 2040, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pyhapaivat.set('2040-12-25', { pvm: { year: 2040, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pyhapaivat.set('2040-12-26', { pvm: { year: 2040, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })


    this.pyhapaivatViimeisin = { year: 2040, month: 12, day: 31 } // Päivitä päiviä lisätessä

    // https://www.suomenpankki.fi/fi/raha-ja-maksaminen/pankkivapaapaivat/
    this.pankkivapaapaivat = new Map()

    // 2024
    this.pankkivapaapaivat.set('2024-12-6', { pvm: { year: 2024, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pankkivapaapaivat.set('2024-12-24', { pvm: { year: 2024, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pankkivapaapaivat.set('2024-12-25', { pvm: { year: 2024, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pankkivapaapaivat.set('2024-12-26', { pvm: { year: 2024, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })

    // 2025
    this.pankkivapaapaivat.set('2025-1-1', { pvm: { year: 2025, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pankkivapaapaivat.set('2025-1-6', { pvm: { year: 2025, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pankkivapaapaivat.set('2025-4-18', { pvm: { year: 2025, month: 4, day: 18 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pankkivapaapaivat.set('2025-4-21', { pvm: { year: 2025, month: 4, day: 21 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pankkivapaapaivat.set('2025-5-1', { pvm: { year: 2025, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pankkivapaapaivat.set('2025-5-29', { pvm: { year: 2025, month: 5, day: 29 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pankkivapaapaivat.set('2025-6-20', { pvm: { year: 2025, month: 6, day: 20 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pankkivapaapaivat.set('2025-12-6', { pvm: { year: 2025, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pankkivapaapaivat.set('2025-12-24', { pvm: { year: 2025, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pankkivapaapaivat.set('2025-12-25', { pvm: { year: 2025, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pankkivapaapaivat.set('2025-12-26', { pvm: { year: 2025, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })

    // 2026
    this.pankkivapaapaivat.set('2026-1-1', { pvm: { year: 2026, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pankkivapaapaivat.set('2026-1-6', { pvm: { year: 2026, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pankkivapaapaivat.set('2026-4-3', { pvm: { year: 2026, month: 4, day: 3 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pankkivapaapaivat.set('2026-4-6', { pvm: { year: 2026, month: 4, day: 6 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pankkivapaapaivat.set('2026-5-1', { pvm: { year: 2026, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pankkivapaapaivat.set('2026-5-14', { pvm: { year: 2026, month: 5, day: 14 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pankkivapaapaivat.set('2026-6-19', { pvm: { year: 2026, month: 6, day: 19 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pankkivapaapaivat.set('2026-12-6', { pvm: { year: 2026, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pankkivapaapaivat.set('2026-12-24', { pvm: { year: 2026, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pankkivapaapaivat.set('2026-12-25', { pvm: { year: 2026, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pankkivapaapaivat.set('2026-12-26', { pvm: { year: 2026, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })

    // 2027
    this.pankkivapaapaivat.set('2027-1-1', { pvm: { year: 2027, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pankkivapaapaivat.set('2027-1-6', { pvm: { year: 2027, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pankkivapaapaivat.set('2027-3-26', { pvm: { year: 2027, month: 3, day: 26 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pankkivapaapaivat.set('2027-3-29', { pvm: { year: 2027, month: 3, day: 29 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pankkivapaapaivat.set('2027-5-1', { pvm: { year: 2027, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pankkivapaapaivat.set('2027-5-6', { pvm: { year: 2027, month: 5, day: 6 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pankkivapaapaivat.set('2027-6-25', { pvm: { year: 2027, month: 6, day: 25 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pankkivapaapaivat.set('2027-12-6', { pvm: { year: 2027, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pankkivapaapaivat.set('2027-12-24', { pvm: { year: 2027, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pankkivapaapaivat.set('2027-12-25', { pvm: { year: 2027, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pankkivapaapaivat.set('2027-12-26', { pvm: { year: 2027, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })

    // 2028
    this.pankkivapaapaivat.set('2028-1-1', { pvm: { year: 2028, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pankkivapaapaivat.set('2028-1-6', { pvm: { year: 2028, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pankkivapaapaivat.set('2028-4-14', { pvm: { year: 2028, month: 4, day: 14 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pankkivapaapaivat.set('2028-4-17', { pvm: { year: 2028, month: 4, day: 17 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pankkivapaapaivat.set('2028-5-1', { pvm: { year: 2028, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pankkivapaapaivat.set('2028-5-25', { pvm: { year: 2028, month: 5, day: 25 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pankkivapaapaivat.set('2028-6-23', { pvm: { year: 2028, month: 6, day: 23 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pankkivapaapaivat.set('2028-12-6', { pvm: { year: 2028, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pankkivapaapaivat.set('2028-12-24', { pvm: { year: 2028, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pankkivapaapaivat.set('2028-12-25', { pvm: { year: 2028, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pankkivapaapaivat.set('2028-12-26', { pvm: { year: 2028, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })

    // 2029
    this.pankkivapaapaivat.set('2029-1-1', { pvm: { year: 2029, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pankkivapaapaivat.set('2029-1-6', { pvm: { year: 2029, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pankkivapaapaivat.set('2029-3-30', { pvm: { year: 2029, month: 3, day: 30 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pankkivapaapaivat.set('2029-4-2', { pvm: { year: 2029, month: 4, day: 2 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pankkivapaapaivat.set('2029-5-1', { pvm: { year: 2029, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pankkivapaapaivat.set('2029-5-10', { pvm: { year: 2029, month: 5, day: 10 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pankkivapaapaivat.set('2029-6-22', { pvm: { year: 2029, month: 6, day: 22 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pankkivapaapaivat.set('2029-12-6', { pvm: { year: 2029, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pankkivapaapaivat.set('2029-12-24', { pvm: { year: 2029, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pankkivapaapaivat.set('2029-12-25', { pvm: { year: 2029, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pankkivapaapaivat.set('2029-12-26', { pvm: { year: 2029, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })

    // 2030
    this.pankkivapaapaivat.set('2030-1-1', { pvm: { year: 2030, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pankkivapaapaivat.set('2030-1-6', { pvm: { year: 2030, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pankkivapaapaivat.set('2030-4-19', { pvm: { year: 2030, month: 4, day: 19 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pankkivapaapaivat.set('2030-4-22', { pvm: { year: 2030, month: 4, day: 22 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pankkivapaapaivat.set('2030-5-1', { pvm: { year: 2030, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pankkivapaapaivat.set('2030-5-30', { pvm: { year: 2030, month: 5, day: 30 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pankkivapaapaivat.set('2030-6-21', { pvm: { year: 2030, month: 6, day: 21 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pankkivapaapaivat.set('2030-12-6', { pvm: { year: 2030, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pankkivapaapaivat.set('2030-12-24', { pvm: { year: 2030, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pankkivapaapaivat.set('2030-12-25', { pvm: { year: 2030, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pankkivapaapaivat.set('2030-12-26', { pvm: { year: 2030, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })

    // 2031
    this.pankkivapaapaivat.set('2031-1-1', { pvm: { year: 2031, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pankkivapaapaivat.set('2031-1-6', { pvm: { year: 2031, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pankkivapaapaivat.set('2031-4-11', { pvm: { year: 2031, month: 4, day: 11 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pankkivapaapaivat.set('2031-4-14', { pvm: { year: 2031, month: 4, day: 14 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pankkivapaapaivat.set('2031-5-1', { pvm: { year: 2031, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pankkivapaapaivat.set('2031-5-22', { pvm: { year: 2031, month: 5, day: 22 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pankkivapaapaivat.set('2031-6-20', { pvm: { year: 2031, month: 6, day: 20 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pankkivapaapaivat.set('2031-12-6', { pvm: { year: 2031, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pankkivapaapaivat.set('2031-12-24', { pvm: { year: 2031, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pankkivapaapaivat.set('2031-12-25', { pvm: { year: 2031, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pankkivapaapaivat.set('2031-12-26', { pvm: { year: 2031, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })

    // 2032
    this.pankkivapaapaivat.set('2032-1-1', { pvm: { year: 2032, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pankkivapaapaivat.set('2032-1-6', { pvm: { year: 2032, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pankkivapaapaivat.set('2032-3-26', { pvm: { year: 2032, month: 3, day: 26 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pankkivapaapaivat.set('2032-3-29', { pvm: { year: 2032, month: 3, day: 29 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pankkivapaapaivat.set('2032-5-1', { pvm: { year: 2032, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pankkivapaapaivat.set('2032-5-6', { pvm: { year: 2032, month: 5, day: 6 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pankkivapaapaivat.set('2032-6-25', { pvm: { year: 2032, month: 6, day: 25 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pankkivapaapaivat.set('2032-12-6', { pvm: { year: 2032, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pankkivapaapaivat.set('2032-12-24', { pvm: { year: 2032, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pankkivapaapaivat.set('2032-12-25', { pvm: { year: 2032, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pankkivapaapaivat.set('2032-12-26', { pvm: { year: 2032, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })

    // 2033
    this.pankkivapaapaivat.set('2033-1-1', { pvm: { year: 2033, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pankkivapaapaivat.set('2033-1-6', { pvm: { year: 2033, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pankkivapaapaivat.set('2033-4-15', { pvm: { year: 2033, month: 4, day: 15 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pankkivapaapaivat.set('2033-4-18', { pvm: { year: 2033, month: 4, day: 18 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pankkivapaapaivat.set('2033-5-1', { pvm: { year: 2033, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pankkivapaapaivat.set('2033-5-26', { pvm: { year: 2033, month: 5, day: 26 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pankkivapaapaivat.set('2033-6-24', { pvm: { year: 2033, month: 6, day: 24 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pankkivapaapaivat.set('2033-12-6', { pvm: { year: 2033, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pankkivapaapaivat.set('2033-12-24', { pvm: { year: 2033, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pankkivapaapaivat.set('2033-12-25', { pvm: { year: 2033, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pankkivapaapaivat.set('2033-12-26', { pvm: { year: 2033, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })

    // 2034
    this.pankkivapaapaivat.set('2034-1-1', { pvm: { year: 2034, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pankkivapaapaivat.set('2034-1-6', { pvm: { year: 2034, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pankkivapaapaivat.set('2034-4-7', { pvm: { year: 2034, month: 4, day: 7 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pankkivapaapaivat.set('2034-4-10', { pvm: { year: 2034, month: 4, day: 10 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pankkivapaapaivat.set('2034-5-1', { pvm: { year: 2034, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pankkivapaapaivat.set('2034-4-18', { pvm: { year: 2034, month: 4, day: 18 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pankkivapaapaivat.set('2034-6-23', { pvm: { year: 2034, month: 6, day: 23 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pankkivapaapaivat.set('2034-12-6', { pvm: { year: 2034, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pankkivapaapaivat.set('2034-12-24', { pvm: { year: 2034, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pankkivapaapaivat.set('2034-12-25', { pvm: { year: 2034, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pankkivapaapaivat.set('2034-12-26', { pvm: { year: 2034, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })

    // 2035
    this.pankkivapaapaivat.set('2035-1-1', { pvm: { year: 2035, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pankkivapaapaivat.set('2035-1-6', { pvm: { year: 2035, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pankkivapaapaivat.set('2035-3-23', { pvm: { year: 2035, month: 3, day: 23 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pankkivapaapaivat.set('2035-3-26', { pvm: { year: 2035, month: 3, day: 26 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pankkivapaapaivat.set('2035-5-1', { pvm: { year: 2035, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pankkivapaapaivat.set('2035-5-3', { pvm: { year: 2035, month: 5, day: 3 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pankkivapaapaivat.set('2035-6-22', { pvm: { year: 2035, month: 6, day: 22 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pankkivapaapaivat.set('2035-12-6', { pvm: { year: 2035, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pankkivapaapaivat.set('2035-12-24', { pvm: { year: 2035, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pankkivapaapaivat.set('2035-12-25', { pvm: { year: 2035, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pankkivapaapaivat.set('2035-12-26', { pvm: { year: 2035, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })

    // 2036
    this.pankkivapaapaivat.set('2036-1-1', { pvm: { year: 2036, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pankkivapaapaivat.set('2036-1-6', { pvm: { year: 2036, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pankkivapaapaivat.set('2036-4-11', { pvm: { year: 2036, month: 4, day: 11 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pankkivapaapaivat.set('2036-4-14', { pvm: { year: 2036, month: 4, day: 14 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pankkivapaapaivat.set('2036-5-1', { pvm: { year: 2036, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pankkivapaapaivat.set('2036-5-22', { pvm: { year: 2036, month: 5, day: 22 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pankkivapaapaivat.set('2036-6-20', { pvm: { year: 2036, month: 6, day: 20 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pankkivapaapaivat.set('2036-12-6', { pvm: { year: 2036, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pankkivapaapaivat.set('2036-12-24', { pvm: { year: 2036, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pankkivapaapaivat.set('2036-12-25', { pvm: { year: 2036, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pankkivapaapaivat.set('2036-12-26', { pvm: { year: 2036, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })

    // 2037
    this.pankkivapaapaivat.set('2037-1-1', { pvm: { year: 2037, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pankkivapaapaivat.set('2037-1-6', { pvm: { year: 2037, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pankkivapaapaivat.set('2037-4-3', { pvm: { year: 2037, month: 4, day: 3 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pankkivapaapaivat.set('2037-4-6', { pvm: { year: 2037, month: 4, day: 6 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pankkivapaapaivat.set('2037-5-1', { pvm: { year: 2037, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pankkivapaapaivat.set('2037-5-14', { pvm: { year: 2037, month: 5, day: 14 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pankkivapaapaivat.set('2037-6-19', { pvm: { year: 2037, month: 6, day: 19 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pankkivapaapaivat.set('2037-12-6', { pvm: { year: 2037, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pankkivapaapaivat.set('2037-12-24', { pvm: { year: 2037, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pankkivapaapaivat.set('2037-12-25', { pvm: { year: 2037, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pankkivapaapaivat.set('2037-12-26', { pvm: { year: 2037, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })

    // 2038
    this.pankkivapaapaivat.set('2038-1-1', { pvm: { year: 2038, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pankkivapaapaivat.set('2038-1-6', { pvm: { year: 2038, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pankkivapaapaivat.set('2038-4-23', { pvm: { year: 2038, month: 4, day: 23 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pankkivapaapaivat.set('2038-4-26', { pvm: { year: 2038, month: 4, day: 26 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pankkivapaapaivat.set('2038-5-1', { pvm: { year: 2038, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pankkivapaapaivat.set('2038-6-3', { pvm: { year: 2038, month: 6, day: 3 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pankkivapaapaivat.set('2038-6-25', { pvm: { year: 2038, month: 6, day: 25 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pankkivapaapaivat.set('2038-12-6', { pvm: { year: 2038, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pankkivapaapaivat.set('2038-12-24', { pvm: { year: 2038, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pankkivapaapaivat.set('2038-12-25', { pvm: { year: 2038, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pankkivapaapaivat.set('2038-12-26', { pvm: { year: 2038, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })

    // 2039
    this.pankkivapaapaivat.set('2039-1-1', { pvm: { year: 2039, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pankkivapaapaivat.set('2039-1-6', { pvm: { year: 2039, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pankkivapaapaivat.set('2039-4-8', { pvm: { year: 2039, month: 4, day: 8 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pankkivapaapaivat.set('2039-4-11', { pvm: { year: 2039, month: 4, day: 11 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pankkivapaapaivat.set('2039-5-1', { pvm: { year: 2039, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pankkivapaapaivat.set('2039-5-19', { pvm: { year: 2039, month: 5, day: 19 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pankkivapaapaivat.set('2039-6-24', { pvm: { year: 2039, month: 6, day: 24 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pankkivapaapaivat.set('2039-12-6', { pvm: { year: 2039, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pankkivapaapaivat.set('2039-12-24', { pvm: { year: 2039, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pankkivapaapaivat.set('2039-12-25', { pvm: { year: 2039, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pankkivapaapaivat.set('2039-12-26', { pvm: { year: 2039, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })

    // 2040
    this.pankkivapaapaivat.set('2040-1-1', { pvm: { year: 2040, month: 1, day: 1 }, tyyppi: PyhapaivanTyyppi.UUDENVUODENPAIVA })
    this.pankkivapaapaivat.set('2040-1-6', { pvm: { year: 2040, month: 1, day: 6 }, tyyppi: PyhapaivanTyyppi.LOPPIAINEN })
    this.pankkivapaapaivat.set('2040-3-30', { pvm: { year: 2040, month: 3, day: 30 }, tyyppi: PyhapaivanTyyppi.PITKAPERJANTAI })
    this.pankkivapaapaivat.set('2040-4-2', { pvm: { year: 2040, month: 4, day: 2 }, tyyppi: PyhapaivanTyyppi.PAASIAISPAIVA_2 })
    this.pankkivapaapaivat.set('2040-5-1', { pvm: { year: 2040, month: 5, day: 1 }, tyyppi: PyhapaivanTyyppi.VAPPU })
    this.pankkivapaapaivat.set('2040-5-10', { pvm: { year: 2040, month: 5, day: 10 }, tyyppi: PyhapaivanTyyppi.HELATORSTAI })
    this.pankkivapaapaivat.set('2040-6-22', { pvm: { year: 2040, month: 6, day: 22 }, tyyppi: PyhapaivanTyyppi.JUHANNUSAATTO })
    this.pankkivapaapaivat.set('2040-12-6', { pvm: { year: 2040, month: 12, day: 6 }, tyyppi: PyhapaivanTyyppi.ITSENAISYYSPAIVA })
    this.pankkivapaapaivat.set('2040-12-24', { pvm: { year: 2040, month: 12, day: 24 }, tyyppi: PyhapaivanTyyppi.JOULUAATTO })
    this.pankkivapaapaivat.set('2040-12-25', { pvm: { year: 2040, month: 12, day: 25 }, tyyppi: PyhapaivanTyyppi.JOULUPAIVA })
    this.pankkivapaapaivat.set('2040-12-26', { pvm: { year: 2040, month: 12, day: 26 }, tyyppi: PyhapaivanTyyppi.TAPANINPAIVA })

    this.pankkivapaapaivatViimeisin = { year: 2040, month: 12, day: 31 } // Päivitä päiviä lisätessä

  }

  private _validoiPaivienSaatavuus(pvm: LocalDate, viimeisin: LocalDate) {
    if (this._dateService.compareLocalDates(pvm, '>', viimeisin)) {
      throw Error(`Päivämäärät rajoittuvat päivämäärään ${JSON.stringify(viimeisin)}, kysyttiin ${JSON.stringify(pvm)}`)
    }
  }

  /**
   * NB! DOES NOT RETURN ANYTHING FOR SUNDAYS AFTER 2025
   */
  public onkoPyhapaiva(pvm: LocalDate): PyhapaivanTyyppi | null {
    if (!pvm) { return null }
    this._validoiPaivienSaatavuus(pvm, this.pyhapaivatViimeisin)
    return this.pyhapaivat.get(pvm.year + '-' + pvm.month + '-' + pvm.day)?.tyyppi ?? null
  }

  /**
   * NB! DOES NOT RETURN ANYTHING FOR SUNDAYS AFTER 2025
   */
  public onkoPyhapaivaDate(pvm: Date): PyhapaivanTyyppi | null {
    if (!pvm) { return null }
    this._validoiPaivienSaatavuus(this._dateService.dateToLocalDate(pvm), this.pyhapaivatViimeisin)
    return this.pyhapaivat.get(pvm.getFullYear() + '-' + (pvm.getMonth() + 1) + '-' + pvm.getDate())?.tyyppi ?? null
  }
  annaEdellinenArkipaivaJosOnPyhaTaiViikonLoppu(pvm: Date): Date {
    const tryPvm = this._dateService.annaEdellinenPerjantaiJosOnViikonloppu(pvm)
    if (this.onkoPyhapaivaDate(tryPvm)) {
      const prevPvm = this._dateService.lisaaPaivia(tryPvm, -1)
      return this.annaEdellinenArkipaivaJosOnPyhaTaiViikonLoppu(prevPvm)
    }
    return tryPvm
  }
  annaSeuraavaArkipaivaJosOnPyhaTaiViikonloppu(pvm: Date): Date {
    const tryPvm = this._dateService.annaSeuraavaMaanantaiJosOnViikonloppu(pvm)
    if (this.onkoPyhapaivaDate(tryPvm)) {
      const nextPvm = this._dateService.lisaaPaivia(tryPvm, 1)
      return this.annaSeuraavaArkipaivaJosOnPyhaTaiViikonloppu(nextPvm)
    }
    return tryPvm
  }

  lisaaPaiviaPaikallinenLaskeVainPankkipaivat(localDate: LocalDate, lisaa: number): LocalDate {
    if (!localDate) {
      return null
    }
    const lisaaAbs = Math.abs(lisaa)
    const lisattava = lisaa > 0 ? 1 : -1
    let lisatty = 0
    let date = localDate
    while (lisatty < lisaaAbs) {
      date = this._dateService.lisaaPaiviaPaikallinen(date, lisattava)
      // console.log(this._dateService.muotoilePaikallinenPaiva(date, 'fi'), this.onkoPankkivapaapaiva(date))
      if (this.onkoPankkiPaiva(date)) {
        lisatty++
      }
    }
    return date
  }

  onkoPyhaTaiViikonloppu(pvm: LocalDate) {
    const paivanNimi = this.onkoPyhapaiva(pvm) ? 'Pyhä' : this._dateService.annaPaivanNimiPaikallinen(pvm, 'fi')
    return ['Sunnuntai', 'Lauantai', 'Pyhä'].includes(paivanNimi)
  }

  onkoPankkiPaiva(pvm: LocalDate): boolean {
    const d = this._dateService.annaPaivanNimiPaikallinen(pvm, 'fi')
    if (d === 'Sunnuntai' || d === 'Lauantai') {
      return false
    }
    if (this.onkoPankkivapaapaiva(pvm)) {
      return false
    }
    return true
  }

  public onkoPankkivapaapaiva(pvm: LocalDate): PyhapaivanTyyppi | null {
    if (!pvm) { return null }
    this._validoiPaivienSaatavuus(pvm, this.pankkivapaapaivatViimeisin)
    return this.pankkivapaapaivat.get(pvm.year + '-' + pvm.month + '-' + pvm.day)?.tyyppi ?? null
  }

  public onkoPankkivapaapaivaDate(pvm: Date): PyhapaivanTyyppi | null {
    if (!pvm) { return null }
    this._validoiPaivienSaatavuus(this._dateService.dateToLocalDate(pvm), this.pankkivapaapaivatViimeisin)
    return this.pankkivapaapaivat.get(pvm.getFullYear() + '-' + (pvm.getMonth() + 1) + '-' + pvm.getDate())?.tyyppi ?? null
  }
}
