import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { NumberDate } from 'app/_shared-core/model/common'

type ValilehdenTunnus = 'tositteet' | 'raportit-uusi' | 'tiedot' | 'tilikartta' | 'projektit' | 'tilinpaatos' | 'reskontra'

@Injectable()
export class KirjanpitoNavigationService {

  private currentMainTabSubject = new BehaviorSubject<ValilehdenTunnus>(null)
  currentMainTabObservable = this.currentMainTabSubject.asObservable()

  private kirjanpidonTiliSubject = new BehaviorSubject<string>(null)
  kirjanpidonTiliObservable = this.kirjanpidonTiliSubject.asObservable()

  private raporttiValinPaattymispaivaSubject = new BehaviorSubject<NumberDate>(null)
  raporttiValinPaattymispaivaObservable = this.raporttiValinPaattymispaivaSubject.asObservable()

  constructor() { }

  vaihdaPaaTasonValilehti(valilehti: ValilehdenTunnus) {
    this.currentMainTabSubject.next(valilehti)
  }

  valittuKirjanpidonTili(tili: string) {
    this.kirjanpidonTiliSubject.next(tili)
  }

  valittuRaporttiValinPaattymispaiva(paattymispaiva: NumberDate) {
    this.raporttiValinPaattymispaivaSubject.next(paattymispaiva)
  }

}