<div class="list-page kapea" style="max-width: 1500px;">

  <app-asiakas-otsikko otsikko="Keskeneräiset työt" paluuUrl="/asiakkaat" [asiakas]="asiakasObservable | async"></app-asiakas-otsikko>

  <div *ngIf="tyojonolatauksetKeskenCountObservable | async as lataukset">
    <div *ngIf="lataukset.length else eiLatauksia">
      <p class="dark-gray-text" style="margin: 3em 0;">Seuraavat työt ovat kesken:</p>
      <ul>
        <li *ngFor="let lataus of lataukset">
          {{ lataus }}
        </li>
      </ul>
    </div>
    <ng-template #eiLatauksia>
      <p class="dark-gray-text" style="margin: 3em 0;">Ei keskeneräisiä töitä</p>
    </ng-template>
  </div>
</div>