<div class="list-page" style="min-height: 100vh; display: flex; justify-content: space-between; flex-direction: column;">

  <div>
    <app-asiakas-otsikko otsikko="Lataa tositteita" paluuUrl="/asiakkaat" [asiakas]="asiakasObservable | async"></app-asiakas-otsikko>

    <div *ngIf="kirjanpitajaOnDevaajaObservable | async" style="padding-bottom: 20px;">
      <h3 style="margin: 0;">Urit</h3>
      <div style="padding-top: 1em; padding-left: 1em; padding-right: 1em;">Kuitit (Lemonaid): <a [href]="kuititEncodedUriObservable | async" target="_BLANK">{{kuititUriObservable | async}}</a></div>
      <div style="padding-top: 0em; padding-left: 1em; padding-right: 1em;">Laskut (Lemonaid): <a [href]="laskutEncodedUriObservable | async" target="_BLANK">{{laskutUriObservable | async}}</a></div>
      <div style="padding-top: 0em; padding-left: 1em; padding-right: 1em;">Kuukausikortit (Lemonator): <a [href]="kuukausisummakortitEncodedUriObservable | async" target="_BLANK">{{kuukausisummakortitUriObservable | async}}</a></div>
    </div>

    <div style="display: flex; align-items: top; justify-content: space-between;">
      <div>
        <h4>Lataa palkkatositteet</h4>
        <form [formGroup]="form">
          <mat-form-field style="width: 150px;">
            <mat-label>Kuukausi</mat-label>
            <mat-select formControlName="selectedMonthZip">
              <mat-option *ngFor="let month of selectableMonths" [value]="month">
                {{ month | numbermonthtomonthyear | async }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
        <button (click)="downloadSalaryZip()" mat-button class="primarybutton">Download</button>
      </div>
      <div *ngIf="isSuperUserObservable | async">
        <h4>Käynnistä palkkojen haku</h4>
        <form [formGroup]="form">
          <mat-form-field style="width: 150px;">
            <mat-label>Kuukausi</mat-label>
            <mat-select formControlName="selectedMonthFetch">
              <mat-option *ngFor="let month of selectableMonths" [value]="month">
                {{ month | numbermonthtomonthyear | async }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
        <button (click)="fetchSalaryFromPalkkausfi()" mat-button class="primarybutton">Käynnistä</button>
      </div>
      <mat-form-field style="width: 150px;">
        <mat-label>Näytä</mat-label>
        <mat-select #naytaKaikki>
          <mat-option [value]="false">
            Uudet tositteet
          </mat-option>
          <mat-option [value]="true">
            Kaikki tositteet
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="kuukaudet-container">

      <div lemon-loading [show]="ladataanKuukausiaObservable | async"></div>
      <div *ngIf="eiUusiaTositteitaObservable | async" style="display: flex; align-items: center;">
        <span>Asiakkaalla ei ole <b>uusia</b> tositteita</span>
      </div>
      <div *ngIf="eiTositteitaObservable | async" style="display: flex; align-items: center;">
        <span>Asiakkaalla ei ole yhtään tositetta</span>
      </div>
      <ng-container *ngIf="!(ladataanKuukausiaObservable | async)">
        <div *ngFor="let kuukausi of kuukaudetObservable | async" class="mat-elevation-z3 kuukausi-container">

          <div class="light-blue-text kk-otsikko">
            {{kuukausi.vuosi}}/{{kuukausi.kk > 9 ? kuukausi.kk : '0' + kuukausi.kk }}
            <mat-icon *ngIf="kuukausi.lataamatta === 0" class="green-text">check</mat-icon>
          </div>
          <!-- Lataamatta: {{kuukausi.lataamatta}}, Ladattu: {{kuukausi.ladattuja}}, Yhteensä {{kuukausi.ladattuja + kuukausi.lataamatta}} -->
          <table class="gray-text">
            <tr *ngFor="let maksutapa of kuukausi.maksutavoittain" [class.light-gray-text]="1 > (maksutapa.ladattuja + maksutapa.lataamatta)">
              <td>{{maksutapa.maksutavanNimi}}</td>
              <td style="text-align: right; font-weight: bold;" class="blue-text">{{maksutapa.lataamatta > 0 ? maksutapa.lataamatta : ''}}</td>
              <td style="text-align: right;">{{maksutapa.ladattuja + maksutapa.lataamatta}}</td>
            </tr>
            <tr [class.light-gray-text]="1 > (kuukausi.ladattuja + kuukausi.lataamatta)">
              <td>Yhteensä</td>
              <td style="text-align: right; font-weight: bold;" class="blue-text">{{kuukausi.lataamatta > 0 ? kuukausi.lataamatta : ''}}</td>
              <td style="text-align: right;">{{kuukausi.ladattuja + kuukausi.lataamatta}}</td>
            </tr>
          </table>

          <div style="display: flex; justify-content: space-between;">
            <button [disabled]="1 > kuukausi.lataamatta" mat-button (click)="lataaUudet(kuukausi)" type="button" [class.blue-text]="kuukausi.lataamatta > 0" [class.light-gray-text]="1 > kuukausi.lataamatta" style="font-weight: bold;">
              Lataa uudet
            </button>

            <button mat-button (click)="lataaKaikki(kuukausi)" type="button" class="gray-text">
              Lataa kaikki
            </button>
          </div>

        </div>
      </ng-container>

    </div>

  </div>

  <div style="display: flex; justify-content: flex-end; margin-top: 2em; margin-bottom: 2em;">
    <button mat-button (click)="naytaHistoria()" class="secondarybutton">
      Lataushistoria&nbsp;<mat-icon>history</mat-icon>
    </button>
  </div>

</div>