<h1 class="raportti-header">
  Tulos
</h1>

<div class="tase-ensin" *ngIf="tulosEnsin">
  <button class="report-btn tulos-ensin-button" mat-button (click)="taseEnsinClicked.emit()">
    <div>Tase ensin</div>
  </button>
</div>

<ng-container *ngIf="tuloslaskelmanDataObservable | async; let data" style="position: relative;">
  <ng-container *ngIf="!(loadingSubject | async) else loading">
    <div *ngIf="data.e == 'a'">
      Valittu päivämääräväli ei ole millään Lemonatorista löytyvällä tilikaudella.
    </div>
    <div *ngIf="lastSucessfullyUpdated" class="gray-text" style="font-size: 11px; text-align: left; margin-left: 5px; margin-top: 16px; line-height: 13px;">Päivitetty {{ lastSucessfullyUpdated | ltimestamptime | async }}</div>

    <button mat-icon-button (click)="$event.stopPropagation()" [matMenuTriggerFor]="vertailuVuosiMenu" style="float: right;">
      <mat-icon>more_horiz</mat-icon>
    </button>

    <table *ngIf="data.c == 'c2'" class="report-table">
      <thead>
        <tr>
          <th></th>
          <th>{{data.c1Header}}</th>
          <th>{{data.c2Header}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of data.data; trackBy: trackAccountRowByAccountNumberFn" class="report-row-lvl-{{row.l}}" [class.report-row-summary]="row.s">
          <td class="n">{{row.n}}</td>
          <td>{{row.s1 | number:'1.2-2'}}</td>
          <td>{{row.s2 | number:'1.2-2'}}</td>
        </tr>
      </tbody>
    </table>
    <table *ngIf="data.c == 'c1'" class="report-table">
      <thead>
        <tr>
          <th></th>
          <th>{{data.c1Header}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of data.data; trackBy: trackAccountRowByAccountNumberFn" class="report-row-lvl-{{row.l}}" [class.report-row-summary]="row.s">
          <td class="n">{{row.n}}</td>
          <td>{{row.s1 | number:'1.2-2'}}</td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</ng-container>
<ng-template #loading>
  <mat-spinner style="margin-left: auto; margin-right: auto; margin-top: 20px;"></mat-spinner>
</ng-template>

<mat-menu #vertailuVuosiMenu="matMenu" yPosition="above" xPosition="before">
  <ng-template matMenuContent>
    <button mat-menu-item (click)="vertailuTiedotKokoTilikaudenMukaan()">Näytä koko tilikausi</button>
    <button mat-menu-item (click)="vertailuTiedotAjanjaksonMukaan()">Näytä valitun aikavälin mukaan</button>
  </ng-template>
</mat-menu>