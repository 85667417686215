<ng-container *ngIf="!onkoTmi">

  <form #tunnistamisForm="ngForm" [formGroup]="form" class="teksti tunnistamistiedot">

    <div class="light-blue-text otsikko">
      Osakkaat, hallitus ja tosiasialliset edunsaajat
    </div>

    <div *ngIf="isBrowsingHistory; else historiaBtn">
      <span style="font-size: 80%; padding: 0 5px;" class="dark-gray-text">Tuntemistietojen historia ajalta {{selectedHistorySubject.value?.paivitetty | ltimestamptime | async}}</span>

      <div style="display: flex;">
        <div class="dark-gray-text">
          <button [disabled]="!prevActive" mat-button (click)="selectDisplayedHistory('prev')">&lt; Edellinen versio</button>
          <button [disabled]="!nextActive" mat-button (click)="selectDisplayedHistory('next')">Seuraava versio ></button>
        </div>
      </div>

      <button class="primarybutton" style="display: block; margin-top: 1em;" mat-button (click)="editForm()">Muokkaa</button>
    </div>

    <ng-template #historiaBtn>
      <button class="primarybutton" style="display: block;" mat-button (click)="selectDisplayedHistory('init')">Katso historiaa</button>
    </ng-template>

    <div class="osakkaat">
      <div class="lemon-error" *ngIf="commonError">{{commonError}}</div>
      <div class="tuntemistieto-otsikko">{{'lemonaid-sopimus-dialog.kyc.osakkaat' | translate | async }}</div>

      <div class="lemon-error" *ngIf="osakkaatFormArray?.errors?.min">{{'lemonaid-sopimus-dialog.kyc.vahintaan-yksi-osakas' | translate | async }}</div>

      <div formArrayName="osakkaat">
        <div *ngFor="let osakasFormGroup of osakasFormGroupit; let i = index;" [formGroupName]="i" class="osakas">

          <div class="kyc-checkbox">
            <mat-checkbox [labelPosition]="'before'" formControlName="noFinnishId">{{'lemonaid-sopimus-dialog.kyc.ei-suomalaista-tunnusta' | translate | async }}
            </mat-checkbox>
          </div>

          <mat-radio-group class="kyc-radio" [class.kyc-changed]="" formControlName="onkoYritys" [style.display]="osakasFormGroup.get('onkoYritys').disabled ? 'none': 'flex'">
            <mat-radio-button style="margin: 5px;" [value]="false">{{'lemonaid-sopimus-dialog.kyc.osakas-on-luonnollinen' | translate | async }}</mat-radio-button>
            <mat-radio-button style="margin: 5px;" [value]="true">{{'lemonaid-sopimus-dialog.kyc.osakas-on-yritys' | translate | async }}</mat-radio-button>
          </mat-radio-group>

          <div class="kyc-single-row">
            <mat-form-field class="right-margin" [class.kyc-changed]="!!historyChangesMap.get(i + 'o'+ 'nimi')">
              <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.nimi' | translate | async }}</mat-label>
              <input [name]="namename" type="text" formControlName="nimi" autocomplete="nothing" matInput required data-lpignore="true" />
              <mat-error>
                <ng-container *ngIf="osakasFormGroup.get('nimi'); let n">
                  <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                    <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.nimi-required' | translate | async}}</ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="right-margin" [class.kyc-changed]="!!historyChangesMap.get(i + 'o'+ 'hetuTaiYtunnus')">
              <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.hetu-ytunnus' | translate | async }}</mat-label>
              <input [name]="namename" type="text" formControlName="hetu" autocomplete="nothing" matInput required data-lpignore="true" />
              <mat-error>
                <ng-container *ngIf="osakasFormGroup.get('hetu'); let n">
                  <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                    <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.hetu-ytunnus-required' | translate | async }}</ng-container>
                    <ng-container *ngIf="n.errors?.invalid">Varoitus: hetu tai y-tunnus on virheellinen</ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="right-margin" [style.display]="osakasFormGroup.get('birthDate').disabled ? 'none': 'inline-block'">
              <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.syntymaaika' | translate | async }}</mat-label>
              <input [name]="namename" type="text" [min]="minDate" [max]="maxDate" [matDatepicker]="picker1" formControlName="birthDate" autocomplete="nothing" matInput required data-lpignore="true" />
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-error>
                <ng-container *ngIf="osakasFormGroup.get('birthDate'); let n">
                  <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                    <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.syntymaaika-required' | translate | async }}</ng-container>
                    <ng-container *ngIf="n.errors?.matDatepickerMin">Liian aikainen päivämäärä</ng-container>
                    <ng-container *ngIf="n.errors?.matDatepickerMax">Liian myöhäinen päivämäärä</ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>
            <mat-datepicker #picker1></mat-datepicker>
            <mat-form-field [class.kyc-changed]="!!historyChangesMap.get(i + 'o'+ 'osakkeita')">
              <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.osakkeiden-lukumaara' | translate | async }}</mat-label>
              <input decimalNumberField matInput formControlName="osakkeita" [numberOfDecimals]="0" data-lpignore="true">
              <mat-error>
                <ng-container *ngIf="osakasFormGroup.get('osakkeita'); let n">
                  <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                    <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.osakkeiden-lukumaara-puuttuu' | translate | async }}</ng-container>
                    <ng-container *ngIf="n.errors?.min">Vähintään yksi osake</ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="kyc-single-row">

            <mat-form-field class="right-margin" [class.kyc-changed]="!!historyChangesMap.get(i + 'o'+ 'kansallisuus')">
              <mat-label>{{'lemonaid-sopimus-dialog.kyc.kansallisuus' | translate | async }}</mat-label>
              <mat-select formControlName="kansallisuus">
                <mat-option *ngFor="let kansa of kansallisuudetObservable | async" [value]="kansa.koodi">
                  {{ kansa.nimi }}
                </mat-option>
              </mat-select>
              <mat-error>
                <ng-container *ngIf="osakasFormGroup.get('kansallisuus'); let n">
                  <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                    <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.kansallisuus-required' | translate | async }}</ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>
            <mat-form-field [class.kyc-changed]="!!historyChangesMap.get(i + 'o'+ 'pep')">
              <mat-label>{{('lemonaid-sopimus-dialog.kyc.pep' | translate | async) + ' (PEP)' }}</mat-label>
              <mat-select formControlName="pep">
                <mat-option [value]="true">
                  {{'lemonaid-sopimus-dialog.kyc.kylla' | translate | async }}
                </mat-option>
                <mat-option [value]="false">
                  {{'lemonaid-sopimus-dialog.kyc.ei' | translate | async }}
                </mat-option>
              </mat-select>
              <mat-error>
                <ng-container *ngIf="osakasFormGroup.get('pep'); let n">
                  <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                    <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.pep-required' | translate | async }}</ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>
          </div>

          <button [disabled]="isBrowsingHistory" mat-icon-button (click)="poistaOsakas(i); $event.preventDefault();">
            <mat-icon>delete</mat-icon>
          </button>

          <div *ngIf="isBrowsingHistory && deletedOsakkaatText" class="red-text">{{deletedOsakkaatText}}</div>
        </div>
        <button [disabled]="isBrowsingHistory" class="primarybutton tuntemistieto-lisaa" mat-button (click)="lisaaUusiOsakas(); $event.preventDefault();">
          {{'lemonaid-sopimus-dialog.kyc.lisaa-osakas' | translate | async }}
        </button>
      </div>

      <div class="tuntemistieto-otsikko">{{'lemonaid-sopimus-dialog.kyc.hallitus-ja-toimitusjohtaja' | translate | async }}</div>

      <div class="lemon-error" *ngIf="hallitusFormArray?.errors?.min">{{'lemonaid-sopimus-dialog.kyc.vahintaan-yksi-hallituksen-jasenen' | translate | async }}</div>

      <div formArrayName="hallitus">
        <div *ngFor="let hallitusFormGroup of hallitusFormGroupit; let i = index;" [formGroupName]="i" class="osakas">

          <div class="kyc-checkbox">
            <mat-checkbox [labelPosition]="'before'" formControlName="noFinnishId">{{'lemonaid-sopimus-dialog.kyc.ei-suomalaista-tunnusta' | translate | async }}
            </mat-checkbox>
          </div>

          <div class="kyc-single-row">
            <mat-form-field class="right-margin" [class.kyc-changed]="!!historyChangesMap.get(i + 'h'+ 'nimi')">
              <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.nimi' | translate | async }}</mat-label>
              <input [name]="namename" type="text" formControlName="nimi" autocomplete="nothing" matInput required data-lpignore="true" />
              <mat-error>
                <ng-container *ngIf="hallitusFormGroup.get('nimi'); let n">
                  <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                    <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.nimi-required' | translate | async}}</ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="right-margin" [class.kyc-changed]="!!historyChangesMap.get(i + 'h'+ 'hetuTaiYtunnus')">
              <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.hetu' | translate | async }}</mat-label>
              <input [name]="namename" type="text" formControlName="hetu" autocomplete="nothing" matInput required data-lpignore="true" />
              <mat-error>
                <ng-container *ngIf="hallitusFormGroup.get('hetu'); let n">
                  <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                    <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.hetu-required' | translate | async }}</ng-container>
                    <ng-container *ngIf="n.errors?.invalid">Varoitus: hetu on virheellinen</ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="right-margin" [style.display]="hallitusFormGroup.get('birthDate').disabled ? 'none': 'inline-block'">
              <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.syntymaaika' | translate | async }}</mat-label>
              <input [name]="namename" type="text" [min]="minDate" [max]="maxDate" [matDatepicker]="picker2" formControlName="birthDate" autocomplete="nothing" matInput required data-lpignore="true" />
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-error>
                <ng-container *ngIf="hallitusFormGroup.get('birthDate'); let n">
                  <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                    <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.syntymaaika-required' | translate | async }}</ng-container>
                    <ng-container *ngIf="n.errors?.matDatepickerMin">Liian aikainen päivämäärä</ng-container>
                    <ng-container *ngIf="n.errors?.matDatepickerMax">Liian myöhäinen päivämäärä</ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>
            <mat-datepicker #picker2></mat-datepicker>

            <mat-form-field [class.kyc-changed]="!!historyChangesMap.get(i + 'h'+ 'rooli')">
              <mat-label>{{'lemonaid-sopimus-dialog.kyc.rooli' | translate | async }}</mat-label>
              <mat-select formControlName="rooli" required>
                <mat-option *ngFor="let rooli of hallitusRoolitObservable | async" [value]="rooli.avain">
                  {{ rooli.nimi }}
                </mat-option>
              </mat-select>
              <mat-error>
                <ng-container *ngIf="hallitusFormGroup.get('rooli'); let n">
                  <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                    <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.rooli-required' | translate | async }}</ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>

          </div>

          <div class="kyc-single-row">

            <mat-form-field class="right-margin" [class.kyc-changed]="!!historyChangesMap.get(i + 'h'+ 'kansallisuus')">
              <mat-label>{{'lemonaid-sopimus-dialog.kyc.kansallisuus' | translate | async }}</mat-label>
              <mat-select formControlName="kansallisuus">
                <mat-option *ngFor="let kansa of kansallisuudetObservable | async" [value]="kansa.koodi">
                  {{ kansa.nimi }}
                </mat-option>
              </mat-select>
              <mat-error>
                <ng-container *ngIf="hallitusFormGroup.get('kansallisuus'); let n">
                  <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                    <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.kansallisuus-required' | translate | async }}</ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>
            <mat-form-field [class.kyc-changed]="!!historyChangesMap.get(i + 'h'+ 'pep')">
              <mat-label>{{('lemonaid-sopimus-dialog.kyc.pep' | translate | async) + ' (PEP)' }}</mat-label>
              <mat-select formControlName="pep">
                <mat-option [value]="true">
                  {{'lemonaid-sopimus-dialog.kyc.kylla' | translate | async }}
                </mat-option>
                <mat-option [value]="false">
                  {{'lemonaid-sopimus-dialog.kyc.ei' | translate | async }}
                </mat-option>
              </mat-select>
              <mat-error>
                <ng-container *ngIf="hallitusFormGroup.get('pep'); let n">
                  <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                    <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.pep-required' | translate | async }}</ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>
          </div>

          <button [disabled]="isBrowsingHistory" mat-icon-button (click)="poistaHallituksenJasen(i); $event.preventDefault();">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
        <div *ngIf="isBrowsingHistory && deletedHallitusText" class="red-text">{{deletedHallitusText}}</div>

        <button [disabled]="isBrowsingHistory" class="primarybutton tuntemistieto-lisaa" mat-button (click)="lisaaUusiHallituksenJasen(); $event.preventDefault();">
          {{'lemonaid-sopimus-dialog.kyc.lisaa-jasen' | translate | async}}
        </button>
      </div>

      <div class="tuntemistieto-otsikko">{{'lemonaid-sopimus-dialog.kyc.edunsaajat' | translate | async}}</div>

      <div formArrayName="edunsaajat">
        <div *ngFor="let edunsaajaFormGroup of edunsaajatFormGroupit; let i = index;" [formGroupName]="i" class="osakas">

          <div class="kyc-checkbox">
            <mat-checkbox [labelPosition]="'before'" formControlName="noFinnishId">{{'lemonaid-sopimus-dialog.kyc.ei-suomalaista-tunnusta' | translate | async }}
            </mat-checkbox>
          </div>

          <div class="kyc-single-row">

            <mat-form-field class="right-margin" [class.kyc-changed]="!!historyChangesMap.get(i + 'e'+ 'nimi')">
              <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.nimi' | translate | async }}</mat-label>
              <input [name]="namename" type="text" formControlName="nimi" autocomplete="nothing" matInput required data-lpignore="true" />
              <mat-error>
                <ng-container *ngIf="edunsaajaFormGroup.get('nimi'); let n">
                  <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                    <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.nimi-required' | translate | async}}</ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>
            <mat-form-field [class.kyc-changed]="!!historyChangesMap.get(i + 'e'+ 'hetuTaiYtunnus')">
              <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.hetu' | translate | async }}</mat-label>
              <input [name]="namename" type="text" formControlName="hetu" autocomplete="nothing" matInput required data-lpignore="true" />
              <mat-error>
                <ng-container *ngIf="edunsaajaFormGroup.get('hetu'); let n">
                  <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                    <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.hetu-required' | translate | async }}</ng-container>
                    <ng-container *ngIf="n.errors?.invalid">Varoitus: hetu on virheellinen</ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="left-margin" [style.display]="edunsaajaFormGroup.get('birthDate').disabled ? 'none': 'inline-block'">
              <mat-label>{{ 'lemonaid-sopimus-dialog.kyc.syntymaaika' | translate | async }}</mat-label>
              <input [name]="namename" type="text" [min]="minDate" [max]="maxDate" [matDatepicker]="picker3" formControlName="birthDate" autocomplete="nothing" matInput required data-lpignore="true" />
              <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
              <mat-error>
                <ng-container *ngIf="edunsaajaFormGroup.get('birthDate'); let n">
                  <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                    <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.syntymaaika-required' | translate | async }}</ng-container>
                    <ng-container *ngIf="n.errors?.matDatepickerMin">Liian aikainen päivämäärä</ng-container>
                    <ng-container *ngIf="n.errors?.matDatepickerMax">Liian myöhäinen päivämäärä</ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>
            <mat-datepicker #picker3></mat-datepicker>
          </div>

          <div class="kyc-single-row">

            <mat-form-field class="right-margin" [class.kyc-changed]="!!historyChangesMap.get(i + 'e'+ 'kansallisuus')">
              <mat-label>{{'lemonaid-sopimus-dialog.kyc.kansallisuus' | translate | async }}</mat-label>
              <mat-select formControlName="kansallisuus">
                <mat-option *ngFor="let kansa of kansallisuudetObservable | async" [value]="kansa.koodi">
                  {{ kansa.nimi }}
                </mat-option>
              </mat-select>
              <mat-error>
                <ng-container *ngIf="edunsaajaFormGroup.get('kansallisuus'); let n">
                  <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                    <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.kansallisuus-required' | translate | async }}</ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>
            <mat-form-field [class.kyc-changed]="!!historyChangesMap.get(i + 'e'+ 'pep')">
              <mat-label>{{('lemonaid-sopimus-dialog.kyc.pep' | translate | async) + ' (PEP)' }}</mat-label>
              <mat-select formControlName="pep">
                <mat-option [value]="true">
                  {{'lemonaid-sopimus-dialog.kyc.kylla' | translate | async }}
                </mat-option>
                <mat-option [value]="false">
                  {{'lemonaid-sopimus-dialog.kyc.ei' | translate | async }}
                </mat-option>
              </mat-select>
              <mat-error>
                <ng-container *ngIf="edunsaajaFormGroup.get('pep'); let n">
                  <ng-container *ngIf="(n.touched || tunnistamisForm.submitted)">
                    <ng-container *ngIf="n.errors?.required">{{'lemonaid-sopimus-dialog.kyc.pep-required' | translate | async }}</ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>
          </div>

          <button [disabled]="isBrowsingHistory" mat-icon-button (click)="poistaEdunsaaja(i); $event.preventDefault();">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
        <div *ngIf="isBrowsingHistory && deletedEdunsaajatText" class="red-text">{{deletedEdunsaajatText}}</div>
        <button [disabled]="isBrowsingHistory" class="primarybutton tuntemistieto-lisaa" mat-button (click)="lisaaUusiEdunsaaja(); $event.preventDefault();">
          {{'lemonaid-sopimus-dialog.kyc.lisaa-edunsaaja' | translate | async}}
        </button>
      </div>
    </div>

    <div style="margin: 60px 0 0 0;" *ngIf="!isBrowsingHistory">
      <button class="primarybutton" mat-button (click)="tallenna()">Tallenna tiedot</button>
      <span *ngIf="knowYourCustomerLastUpdatedObservable | async; let knowYourCustomerLastUpdated" style="font-size: 80%; padding-left: 20px;">Tiedot tallensi viimeksi: {{knowYourCustomerLastUpdated}}.</span>
    </div>

    <div style="margin-bottom: 40px; margin-top: 30px; overflow: visible; display: flex; align-items: center; width: 675px;">
      <button class="secondarybutton" mat-button (click)="vaadiTuntemistietojenPaivitysta(); $event.preventDefault();">
        Vaadi tuntemistietojen päivitystä
      </button>
      <span style="font-size: 80%; padding-left: 20px; white-space: nowrap;" *ngIf="kycLastSeenLogObservable | async; let kycLastSeenLog">Lomaketta katseli viimeksi {{ kycLastSeenLog.kayttajanNimi}} {{ kycLastSeenLog.seenAtString}}</span>
    </div>

  </form>

</ng-container>