import { BaseEntity, Timestamp, LocalDate, ErrorResponse, LocalMonth, NumberDate, NumberMonth, NumberMoney } from '../../_shared-core/model/common'
import { VerotilinSaldoTiedotAikaleimalla } from './omavero'
import { TositteenLisaysprosessinOsaMobiilissa, TositteenLisaysprosessinAlvVahennysoikeusPorras, Roolit, Yritysmuoto, SopimuksenHyvaksyntaStatus, TuntemistietojenStatus, UserFeature, AsiakkaanKirjanpidonPeruste, SelvitettavienNakyvyys, YtjAlvrekisterinTila } from '../../_jaettu/model/kayttaja'
import { TuettuKieli } from '../../_shared-core/model/common'
import { KirjauksienLuontityyppi } from './kirjanpito'
import { EmailLahetysStatus, LaskunSahkoinenOsoite } from '../../_jaettu/model/lasku'
import { KayttajanTunnistamisStatus } from '../../_jaettu/model/kayttaja'
import { BankBalanceCalcAlgorithm, SahkoisenTiliotteenAlatyyppi } from '../../_jaettu/model/tiliote'
import { PaymentSupportedBank } from '../../_shared-core/model/payment'

export interface AsiakkaanMaksutapa {
  avain: string
  aktiivinen: boolean
  tunniste: number
  nimi: string
  base64Kuva: string
  kirjauksienLuontityyppi: KirjauksienLuontityyppi
  sahkoisenTiliotteenAlatyyppi?: SahkoisenTiliotteenAlatyyppi
  sahkoisenTiliotteenTunniste: string
  tilitapahtumatSynkronoidaanAlkaen: LocalDate
  tilitapahtumatSynkronoidaanLoppuen: LocalDate
  oletusVastatili: string
  holviOmallaRahallaAccount?: boolean
  bankBalanceCalcAlgorithm?: BankBalanceCalcAlgorithm
  yhdenkertainenYrityksenTililta?: boolean
  omallaRahallaUid?: string
}

export interface AsiakkaanMaksutapaHistoria extends AsiakkaanMaksutapa {
  paivitetty: Timestamp
  paivittaja: string
}

// export interface AsiakkaanOsoitetiedot extends BaseEntity {
//   katuosoite: string
//   postitmp: string
//   postinro: string
//   maa: string
// }

// export interface AsiakkaanLaskuasetukset extends BaseEntity {
//   laskunTyyppi: string
//   laskunKieli: TuettuKieli
//   laskunValuutta: string
//   laskunVastaanottajat: string[]
//   viitenumero: string
//   sahkoinenLaskutusosoite: LaskunSahkoinenOsoite
//   hinnat: AsiakkaanKuukausihinta[]
// }

// export interface AsiakkaanKirjanpitoasetukset extends BaseEntity {
//   tilinumerot: Tilinumero[]
//   alvIlmoitusjaksot: AlvIlmoitusjaksoAikajaksolla[]
//   kirjanpitajat: AsiakkaanKirjanpitajanVoimassaolojakso[]
//   kirjanpitajatvara: AsiakkaanKirjanpitajanVoimassaolojakso[]
// }

export interface KirjanpitoKuukausiruutuHistoria extends KirjanpitoKuukausiruutu {
  /**
   * Kirjanpitäjä uid who saved
   */
  paivittaja: string
  /**
   * Milloin paivitetty
   */
  paivitetty: Timestamp
}

// Sininen kynä odottaa tilinpäätöksen JA pöytäkirjan allekirjoitusta (kun kaikki allekirjoittaneet molemmat -> B)
// JOS tilintarkastetaan -> <i class=“fa fa-gavel” aria-hidden=“true”></i> kun TP allekirjoitettu, mutta tilintarkastusta ei vielä laitettu. Takaisin sininen kynä kun on.
// Oranssi kynä odottaa rekisteröintiä (Pois kun rekisteröidään -> C jos yhtiökokouksessa jaetaan osinkoja)
// Punainen kello (Pois kun kirjanpitaja täppää kuukausiruudussa Tiedot osinkojen maksamisesta -> Ilmoitettu)

export enum KirjanpitoKuukausiruudunTilinpaatoksenTila {
  ODOTTAA_TILINPAATOKSEN_ALLEKIRJOITTAMISTA = 1,
  ODOTTAA_TILINTARKASTUSTA = 2,
  ODOTTAA_YHTIOKOKOUKSEN_POYTAKIRJAN_ALLEKIRJOITTAMISTA = 3,
  ODOTTAA_REKISTEROINTIA = 4,
  VALMIS = 5
}

export interface LemonatorPaymentConfig {
  avain: string
  /**
   * Mistä päivästä lähtien maksuominaisuus on päällä?
   */
  start: NumberDate
  /**
   * Mistä päivästä lähtien maksuominaisuus on pois päältä?
   */
  end: NumberDate
  /**
   * Miltä tililtä maksut suoritetaan?
   */
  iban: string
  /**
  * Mikä pankki kyseessä? (IBANista pääteltävä BIC ei kerro pankkia kaikissa tapauksissa esim. säästöpankkien ITELFIHH)
  */
  bank: PaymentSupportedBank
  /**
   * Pankin antama asiakkaan yksilöivä tunniste
   * DANSKE: Käyttäjänumero WS-sopimukselta
   *
   */
  debtorIdentifier: string
  /**
   * Intergaation tyyppi
   */
  type: 'avalo'
}

// /**
//    * deactivated: Jos tämä on true, menee ruutu harmaaksi, ei mitään merkintöjä
//    */
// d ?: true
// /**
//  * Is at least one email sent?
//  */
// e ?: true
// /**
//  * Is the whole Tilikausi that this month is part of OK?
//  * If this month is, at the same time, the end iof one tilikausi
//  * and the start of the next one, this boolean applies only to the
//  * ENDING one.
//  */
// t ?: true
// /**
//  * VAT-report is sent?
//  */
// v ?: 'l' | 'u' // l = lähetetty, u = tarvitaan uudelleen lähetys
// osaTilikautta

/**
 * asiakkaat/{asiakasAvain}/asiakaslistauksen-kuukausiruutu/{asiakkaanAvainyymm}
 */
export interface KirjanpitoKuukausiruutu {

  /**
   * Asiakkaan ALV-jakso kyseisenä kuukautena.
   */
  alv: AlvIlmoitusjakso
  /**
   * Asiakkaan avain
   */
  a: string
  /**
   * kuukausi, muoto yymm
   */
  k: NumberMonth
  /**
   * Hinta, joka on voimassa tässä kuussa
   */
  h?: NumberMoney
  /**
   * Message (viesti)
   */
  m?: string

  /**
  * Deaktivoitu
  */
  bd?: 1
  /**
  * Email lähetetty ainakin kerran
  */
  bem?: 1
  /**
  * Koko tilikausi ok
  */
  bkt?: 1
  /**
  * Alv ilmoitus lähetetty ainakin kerran
  */
  balv?: 1
  /**
  * Onko osa tilikautta
  */
  bot?: 1
  /**
  * Onko murrettu tilikausi
  */
  bmt?: 1
  /**
  * ALV lähetys OK override
  */
  baok?: 1
  /**
  * Lukittu
  */
  bl?: 1
  /**
   * Veroilmoitus lähetetty ainakin kerran
   */
  vil?: 1
  /**
   * Veroilmoituksen sähköposti lähetetty ainakin kerran
   */
  vel?: 1
  /**
   * Does the ALV-ilmoitus need Resending?
   */
  r?: 1

  /**
   * Tiedot osinkojen maksamisesta
   * ilm - Ilmoitettu
   * ei - Ei makseta
   */
  tom?: 'ilm' | 'ei'
  /**
   * Jos tilikausi Loppuu tähän kuukauteen, muoto dd, eli vain päivä.
   */
  l?: number

  /**
   * kirjanpitoPvm, muoto yymmdd
   */
  kp?: NumberDate
  /**
   * kirjanpitoAika
   */
  ka?: number

  /**
   * tilinpaatosPvm, muoto yymmdd
   */
  tp?: NumberDate
  /**
   * tilinpaatosAika
   */
  ta?: number
  /** Tilinpäätösprosessin tila */
  tpp?: KirjanpitoKuukausiruudunTilinpaatoksenTila
  /** Odottaa osinkojen rekisteröintiä */
  oor?: 1

  /**
   * veroilmoitusPvm, muoto yymmdd
   */
  vp?: NumberDate
  /**
   * veroilmoitusAika
   */
  va?: number
  /**
   * poytakirjaPvm, muoto yymmdd
   */
  pp?: NumberDate

  /**
   * ladattujen tositteiden lukumäärä
   */
  la?: number
  /**
   * lataamattomien tositteiden määrä
   */
  le?: number

  /**
   * Kirjauksien total Count
   */
  c?: number
  /**
   * Undone kirjaukset
   */
  u?: number
  /**
   * How many kirjaukset are marked to be in purchase suspence accounts (selvitettävät ostot)?
   */
  d?: number
  /**
   * How many kirjaukset are marked to be in sales suspence accounts (selvitettävät myynnit)?
   */
  e?: number

  /**
   * Primääri kirjanpitäjä
   */
  p: string
  /**
   * Sekundäärinen (vara)kirjanpitäjä
   */
  s?: string

  /**
   * SäHköisen tilin saldo Heittää
   */
  sh?: 1

  /** Sopimus Voimassa ainakin yhden päivän tässä kuussa */
  sv?: 1

  /**
   * Tauolla?
   */
  t?: 1

  /**
   * POytälaatikossa?
   */
  po?: 1

  /**
   * KuukausiruutuJakso voimassa?
   * Jos asiakkaalla ON ole kuukausiruutujakso avoimena tässä kuussa, su === 1
   */
  kj?: 1

}

/**
 * asiakkaat/{asiakasAvain}/asiakas-lista/{asiakasAvain}
 */
export interface ListausAsiakas {
  /**
   * Key: asiakasAvain
   */
  k: string
  /**
   * asiakasId
   */
  i: number
  /**
   * Hinta. Jos nykyinen, vain arvo. Jos tulevaisuudessa, listassa on myös alkamispvm suluissa.
   */
  h: string
  /**
   * Nimi
   */
  n: string
  /**
   * Ytunnus
   */
  y: string
  /**
   * Yritysmuoto (Company form)
   */
  c: Yritysmuoto
  /**
   * Alv-ilmoitusjakso
   */
  a: AlvIlmoitusjakso
  /**
   * Omaveron saldo
   */
  o?: number
  /**
   * Omaveron huomautus (Remark)
   */
  r?: string
  /**
   * Mistä kuukaudesta alkaen uudessa kirjanpitoappissa?
   * Formaatti: yymm
   */
  u: number
  /**
   * Uudella listalla
   */
  e: boolean
  /**
   * Primääri kirjanpitäjä
   */
  p: string
  /**
   * Sekundäärinen (vara)kirjanpitäjä
   */
  s?: string

  /**
   * Synkronoi
   */
  bs?: 1
  /**
  * Sopimus hyväksymättä
  */
  bsh?: 1
  /**
  * Palaveri pitamatta
  */
  bpp?: 1
  /**
  * Pankkiyhteys puuttuu
  */
  bpy?: 1
  /**
  * Pankkiyhteys ei ole lisätty maksutapaan
  */
  bpm?: 1
  /**
  * Omavero maksuja myöhässä
  */
  bov?: 1
  /**
   * Ei tilikausia
   */
  bet?: 1
  /**
   * Muistiinpanot
   */
  m?: string
  /**
   * Muistiinpanon väri (Font color)
   */
  f?: string
  /**
   * String that contains only the names and email addresses of the users for search purposes.
   */
  d?: string
  /** Suomi.fi valtuutus puuttuu? */
  bsf?: 1

  /** YTJ-tiedot ovat muuttuneet */
  ytj?: 1

  /** Omaveron ALV on eri kuin meidän. */
  alv?: AlvIlmoitusjakso

  /** Riskiarvio puuttuu */
  ria?: 1

  /** Riskiarvio - edustaja tunnistamatta */
  rie?: 1

  /** Tämän hetkinen sopimuksen tila */
  st: AsiakkaanSopimuskaudenTila

  /** Tauon muistutuspäivä on tänään tai historiassa */
  tm?: 1
}

export interface AsiakasListausAsiakkaanPaivitysTyoData {
  asiakasAvain: string
  aloitettu: Timestamp
}

export interface AsiakasListausTilikausienPaivitysTyoData {
  asiakasAvain: string
  aloitettu: Timestamp
}

export interface AsiakasListausHintojenPaivitysTyoData {
  asiakasAvain: string
  aloitettu: Timestamp
}

export enum KirjanpidonTyyppi {
  KAHDENKERTAINEN = 'KAHDENKERTAINEN',
  YHDENKERTAINEN = 'YHDENKERTAINEN'
}

export interface AsiakkaanKirjanpidonTyyppi {
  alkaa: LocalDate | null
  loppuu: LocalDate | null
  tyyppi: KirjanpidonTyyppi
}

export interface Asiakas extends BaseEntity {

  // Basic information
  asiakasId: number
  nimi: string
  ytunnus: string
  kasittelija: string
  kasittelijavara: string | null

  omaverosaldo: VerotilinSaldoTiedotAikaleimalla
  synkkaustyot: { [tyonAvain: string]: boolean }

  // These data structures drive the listing view
  tilikaudet: Tilikausi[]
  muistiinpanot: string
  muistiinpanojenVari: string
  kirjanpidonMuistiinpanot: string
  kirjanpidonMuistiinpanoAuki?: true

  // These booleans drive different icons in listing view
  kayttoehtojaHyvaksymatta: boolean
  sopimuksenHyvaksyntaStatus: SopimuksenHyvaksyntaStatus
  tuntemistietojenStatus: TuntemistietojenStatus
  suomiFiValtuutusPuuttuu: boolean
  onkoPalaveriPidetty: boolean
  areAllBankAccountsAccessible: boolean
  areAllAccessibleAccountsInMaksutavat: boolean
  ytjTiedotMuuttuneet: boolean
  omaveronAlvKausi: AlvIlmoitusjakso

  // Todo: Refactor to own entity: AsiakkaanOsoitetiedot
  katuosoite: string
  postitmp: string
  postinro: string
  maa: string

  // Laskutusasetukset
  // TODO: refactor to own entity: AsiakkaanLaskutustiedot
  laskunTyyppi: string
  laskunKieli: TuettuKieli
  laskunValuutta: string
  laskunVastaanottajat: string[]
  viitenumero: string
  sahkoinenLaskutusosoite: LaskunSahkoinenOsoite
  hinnat: AsiakkaanKuukausihinta[]
  sopimuskaudet: AsiakkaanSopimuskausi[]
  kkruutujenKaudet: AsiakkaanKuukausiruutukausi[]

  // Kirjanpito
  // TODO: refactor to own entity: AsiakkaanKirjanpitotiedot
  tilinumerot: Tilinumero[]
  yritysmuoto: Yritysmuoto
  alvIlmoitusjaksot: AlvIlmoitusjaksoAikajaksolla[]
  kirjanpitajat: AsiakkaanKirjanpitajanVoimassaolojakso[]
  kirjanpitajatvara: AsiakkaanKirjanpitajanVoimassaolojakso[]

  // Todo: refactor to own collection
  kirjanpitoraporttienLahetystiedot: KirjanpitoraporttienLahetystiedot

  // Todo: these could be refactored to AsiakasTiedot entity?
  palaveriTyyppi: 'puhelin' | 'Google Meet' | 'toimisto'
  palaveriPidettyPvm: Timestamp

  /**
   * Mistä kuukaudesta alkaen uudessa kirjanpitoappissa?
   * Formaatti: yymm
   */
  uudessaKirjanpitoAppissaAlkaen: NumberMonth
  /**
   * Näkyykö asiakas uudessa vai vanhassa listassa?
   */
  uudessaListassa: boolean

  /**
   * Which sales team member originally added this customer? (kirjanpitajanAvain)
   */
  saleDoneBy?: string

  /** YTJ / nightly integration related stuff */
  ytjDataLastSynced?: Timestamp
  ytjIntegrationLastError?: 'invalid-ytunnus' | 'testiasiakas' | 'other-check-logs'
  ytjIntegrationLastSuccessSync?: Timestamp
  viitenumerotPaivitettyOmaverosta?: Timestamp

  /**
   * Onko asiakas testaamiseen käytetty, ei oikea asiakas?
   */
  testiasiakas?: 1

  /**
   * Onko asiakas OSS erityisrekisterissä?
   */
  ossRekisterissa?: 1

  /**
   * Estetäänkö ALV-laskujen lähettäminen tälle henkilölle?
   */
  estaAlvVerkkolaskujenLahetys?: 1

  /*
   * Käsitelläänkö palkkaus.fi palkkojen automaattikirjaukset alvittomana?
   */
  kasittelePalkatAlvittomana?: 1

  /*
   * Rekisteröidäänkö tilinpäätöksen tuloslaskelma bruttolaskelmana oletuksena?
   */
  tilinpaatosRekisteroidaanBruttotuloslaskelmalla?: 1

  /**
   * Hinnan perusteet
   */
  hinnanPerusteet?: string

  /**
   * Lisävastaanottajat
   */
  kirjanpitoviestienLisavastaanottajat?: string[]

  /**
   * Maksukonfiguraatiot asiakkaille. Jos maksupalvelu on
   * käytössä, tähän tallennetaan asiakkaan käyttämät
   * maksukanavat, jotka ovat käytössä Lemonaidissa.
   */
  paymentConfigs?: LemonatorPaymentConfig[]

  /**
   * Millä perusteella kirjanpito tehdään?
   */
  kirjanpidonPerusteet?: AsiakkaanKirjanpidonPeruste[]

  /**
   * Minkä tyyppinen kirjanpito on?
   */
  kirjanpidonTyypit?: AsiakkaanKirjanpidonTyyppi[]

  /**
   * 
   */
  paymentsNumberOfApprovalsRequired?: number

}

export interface Asiakastiedot extends BaseEntity {

  asiakasAvain: string

  kuvausKuvaus: string
  kuvausAloitus: string
  kuvausHuomioita: string

  tositemaaratKuitit: number
  tositemaaratMyyntilaskut: number
  tositemaaratMyyntiraportit: number
  tositemaaratPalkkalaskelmat: number
  tositemaaratMuuta: string

  maksutavatLasku: boolean
  maksutavatKorttipaate: boolean
  maksutavatKorttipaateLisa: string
  maksutavatVerkkokauppa: boolean
  maksutavatVerkkokauppaMaksutavat: string
  maksutavatKateinen: boolean

  maksutavatTilitykset: boolean
  maksutavatTilityksetMita: string

  pankkiPankki: string
  pankkiYhteys: boolean
  pankkiEiTilia: boolean

  auto: boolean
  autoTyyppi: 'henkilo' | 'paketti'
  autoAjo: 'enemmanyksityis' | 'enemmantyo' | 'kokonaantyo'
  autoLisa: string

  sijoitus: boolean
  sijoitusLisa: string

  ohjelmisto: 'lemonaid' | 'holvi' | 'molemmat'
  ohjelmistoLisa: string

  palkatLisa: string

  riskiarvioStatus: 'annettu' | 'puuttuu' | 'paivitettava'
}

export interface RiskiarvionHistoria {
  asiakasAvain: string
  riskiarvio: 'vahainen' | 'kohonnut' | 'korkea'
  risikiarvioLisa: string
  paivitetty: Timestamp
  paivittaja: string
}

export interface RahanpesunSelvitys {
  avain: string
  teksti: string
  tiedostot: RahanpesunSelvitysTiedosto[]
  paivitetty: Timestamp
  paivittaja: string
}

export interface RahanpesunSelvitysTiedosto {
  avain: string
  nimi: string
  fileEnding: string
}

export interface DownloadRahanpesunSelvitysTiedostoRequest {
  asiakasAvain: string
  selvitysAvain: string
  tiedosto: RahanpesunSelvitysTiedosto
}

export interface DownloadRahanpesunSelvitysTiedostoResponse extends ErrorResponse {
  base64File: string
}


export interface KirjanpitoraporttienLahetystiedot {
  // talousraporttienVastaanottajat: string[]
  liitaTuloslaskelma: boolean
  liitaTase: boolean
  liitaPaakirja: boolean
  liitaAlvIlmoitus: boolean
  liitaSelvitettavatOstot: boolean
  liitaSelvitettavatMyynnit: boolean
}

// Ruudun väri tulee tehosta. Alle 100 punainen, 100 - 200 keltainen, yli 200 virheä
// Sininen pallura tulee jos aktiivinen ja kirjanpitoa ei ole tehty ja
//    *  1kk alv jakso: nykyinen päivämäärä on suurempi kuin 12:sta ensi kuuta
//    *  3kk alv jakso: nykyinen päivämäärä on suurempi kuin 12.1, 12.4, 12.7, 12.10
//    * 12kk alv jakso: sama kuin 3kk.
// Sinisen palluran tilalla oleva punainen huutomerkki: sama kuin pallura, mutta aikaa mennyt YLI vielä seuraavan kuukauden viidenteen (eli vähintään 6).
// Sinisen palluran tilalla oleva musta huutomerkki: sama kuin pallura, mutta aikaa mennyt YLI vielä seuraavan kuukauden 12 (eli vähintään 13).

// Sininen OK vasen alalaita tulee kun tilikausi on kokonaan merkitty OK:ksi, eli kaikkiin tilikauden ruutuihin.
//    * Tilinpäätös on OK, kun tilinpäätös dialogissa on kaikki muut merkitty OK:ksi paitsi pöytäkirjan lähettäminen + kaikki kuukaudet merkitty kirjanopito tehty.
//    * Jos kyse on toiminimestä, tilinpäätösdialogissa on vain veroilmoitus ja kirjanpitotehty.

// Numero oikeaan alakulmaan käsittelemättömät tositteet kuukaudelta
//    * Jos kirjanopito ei ole tehty, turkoosin värisenä tositteiden kokonaismäärä
//    * Jos kuukausi on tehty, näkyy harmaana (Näyttää kokonaismäärää) paitsi jos uusia, silloin punaisena uusien määrä

// Oikea yläkulma: puhekupla, jos on viesti

// Jos tilikautta ei ole tiedossa, menee pelkäksi kysymysmerkiksi

// Teho lasketaan: kuukausihinta / minuutit * 60

export interface Tilinumero {
  // pankki: string
  iban: string
  // bic: string
}

export enum AlvIlmoitusjakso {
  TUNTEMATON = 'tuntematon',
  KK1 = 'kk1',
  KK3 = 'kk3',
  KK12 = 'kk12',
  EI = 'ei'
}

export interface AlvIlmoitusjaksoAikajaksolla {
  alvIlmoitusjakso: AlvIlmoitusjakso
  muutosPvm: Timestamp
  voimassaAlkaen: LocalDate
}

export interface AsiakkaanKirjanpitajanVoimassaolojakso {
  kirjanpitajanAvain: string
  voimassaoloAlkaa: LocalDate
}

export enum TilinpaatoksenTuloslaskelmanTyyppi {
  BRUTTO = 'brutto',
  VIRALLINEN = 'virallinen'
}

export interface Tilikausi {
  avain: string
  alkaa: LocalDate
  loppuu: LocalDate
  lukittu?: true
  automationLocked?: true
  omaveroTasmaytysEstetty?: true
  veroilmoitusSent?: Timestamp
  veroilmoitusSentAvain?: string
  tilinpaatosStarted?: 1
  veroilmoitusStarted?: 1
  tilinpaatoksenTuloslaskelma?: TilinpaatoksenTuloslaskelmanTyyppi
}

export interface Kayttaja extends BaseEntity {
  etunimi: string
  sukunimi: string
  kayttajaTunnistettu: KayttajanTunnistamisStatus
  hetu: string
  email: string
  aktiivinen: boolean
  prosessinOsat: TositteenLisaysprosessinOsaMobiilissa[]
  alvVahennysoikeusPortaat: TositteenLisaysprosessinAlvVahennysoikeusPorras[]
  maksutavat: number[]
  roolit: { [rooli in Roolit]?: boolean }
  synkkaustyot: { [tyonAvain: string]: boolean }
  kirjauduKayttajanaUid: string | null
  kirjauduKayttajanaAsiakasAvain: string | null
  selvitettavienNakyvyys: SelvitettavienNakyvyys
  puhelinnumero?: string
  tervetuloaEmailStatus?: EmailLahetysStatus
  sopimuksenHyvaksyntaStatus: SopimuksenHyvaksyntaStatus
  asiointikieli?: TuettuKieli
  tervetuloaDialogiNaytetty?: boolean
  pepTiedotAnnettu?: boolean
  features?: { [rooli in UserFeature]?: boolean }
  kirjanpitajaAvain?: string
}

export interface AsiakkaanVientiLemonatoristaLemonaidiinTyo {
  aloitettu: Timestamp
}

export interface KayttajanVientiLemonatoristaLemonaidiinTyo {
  aloitettu: Timestamp
  kayttajaAvain: string
  lahetaTervetuloaEmail?: boolean
}

export type TallennaTaikalinkkiType = 'tallenna-taikalinkki' | 'ala-tallenna-taikalinkkia'

export interface TallennaKayttajaPyynto {
  tallennaTaikalinkki: TallennaTaikalinkkiType
  asiakasAvain: string
  kayttaja: Kayttaja
  paivitaKirjanpitajanTiedot?: true
}
export interface TallennaKayttajaVastaus extends ErrorResponse {
  missaYrityksissaKiinni?: { kayttajaEmail: string, kayttajaAvain: string, asiakasAvain: string, asiakasNimi: string, asiakasYtunnus: string }[]
}

export enum AsiakkaanSopimuskaudenLopettamisenSyy {
  LIIKETOIMINTA_LOPPPUU = 'l1',
  LIIKETOIMINTA_TAUOLLE = 'l2',
  ALKAA_TEKEMAAN_ITSE = 'l3',
  VAIHTAA_TILITOIMISTOA_HINNAN_VUOKSI = 'l4',
  VAIHTAA_TILITOIMISTOA_OHJELMISTON_VUOKSI = 'l5',
  VAIHTAA_TILITOIMISTOA_MUUSTA_SYYTÄ = 'l6',
  VAIHTAA_YHTIOMUOTOA_PYSYY_ASIAKKAANA = 'l7',
  MUU_SYY = 'l8',
  HINNAN_KOROTUS = 'l9',

  PURKU_MAKSAMATTOMAT_LASKUT = 'p1',
  PURKU_TOIMITTAMATON_AINEISTO = 'p2',
  PURKU_RAHANPESUEPAILY = 'p3',
  PURKU_MUU_SYY = 'p4',
  EI_TULLUT_ASIAKKAAKSI = 'p5'
}

export enum AsiakkaanSopimuskaudenTila {
  /** Oletustila uusille kausille */
  ALLEKIRJOITTAMATTA = 'a',
  /** Kun allekirjoitettu */
  VOIMASSA = 'v',
  /** Kun sopimuskausi on tauolla */
  TAUOLLA = 't',
  /** Kun sopimuskausi on pöytälaatikossa */
  POYTALAATIKOSSA = 'pl',
  /** Päättymispäivä on annettu, mutta päivämäärä ei ole vielä mennyt */
  PAATTYMASSA = 'p',
  /** Päättymispäivä on annettu ja päivämäärä on jo mennyt, lopettamisenSyy EI tyyppiä purku */
  PAATTYNYT = 'pa',
  /** Päättymispäivä on annettu ja päivämäärä on jo mennyt, lopettamisenSyy tyyppiä purku */
  PURETTU = 'pu'
}

export interface AsiakkaanSopimuskaudenTauko extends BaseEntity {
  alkaa: LocalDate
  loppuu: LocalDate
  tyyppi: 'tauko' | 'poytalaatikko'
  muistutuspvm?: LocalDate
}

export interface AsiakkaanSopimuskausi extends BaseEntity {
  alkaa: LocalDate
  loppuu: LocalDate
  lahetaPaattymassaEmail: null | boolean
  lahetaPaattynytEmail: null | boolean
  tositteidenToimitusLoppuu: LocalDate
  lopettamisenSyy: AsiakkaanSopimuskaudenLopettamisenSyy
  lisatiedot: string
  tauot: AsiakkaanSopimuskaudenTauko[]
}

export interface AsiakkaanKuukausiruutukausi extends BaseEntity {
  alkaa: LocalDate
  loppuu: LocalDate
}

export interface AsiakkaanKuukausihinta {
  muutosPvm: Timestamp
  hinta: number
  voimassaAlkaen: LocalDate
  muutoksenSyy: string
}
export interface TaikalinkkiLahetysHistoria {
  pvm: Timestamp
  email: string
}

export interface VerojenMaksuyhteystiedot extends BaseEntity {
  yhteisonTuloveroViite: string // Yhteisön tulovero // Oy
  varainsiirtoViite: string // Varainsiirtovero // Oy
  omaAloitteisetViite: string // Oma-aloitteiset verot // Oy
  henkilonTuloveroViite: string // Henkilön tulovero // Tmi
  kiinteistoViite: string // Kiinteistövero // Tmi / henkkoht
  perinto: string // Perintövero // Tmi / henkkoht
  lahjavero: string // Lahjavero // Tmi / henkkoht
}

export interface EnnakkoveronMaksuohjelmanEra {
  erapaiva: LocalDate
  summa: number
  avoinna: number
}

export interface LahetettavaEnnakkoverolasku {
  kuukausi: LocalMonth
  asiakasAvain: string
  ennakkoveronEra: EnnakkoveronMaksuohjelmanEra
  viitenumero: string
  sahkoinenLaskutusosoite: LaskunSahkoinenOsoite
  luotu: Timestamp
  lahetetty: Timestamp
  lahettaminenKasitelty: Timestamp
  tila: 'lahetetaan' | 'ei_laheteta'
}

export interface EnnakkoveronMaksuohjelma extends BaseEntity {
  erat: EnnakkoveronMaksuohjelmanEra[]
}

export interface AsiakkaalleLemonatoristaLahetettyLasku extends BaseEntity {
  asiakasId: string
  asiakasAvain: string
  laskuAvain: string
  tunniste: string
}

export enum KayttajienTietojenLatauslaajuus {
  Laskutusosoitteet = 1,
  KayttajienOsoitteet = 2,
  KaikkiOsoitteet = 3
}

export enum EiYhtiomuotoaYhtiomuoto {
  YHTIOMUOTO_EI_TIEDOSSA = 'eipaole'
}

export interface AsiakashakuRequest {
  yhtiomuoto?: Yritysmuoto | EiYhtiomuotoaYhtiomuoto
  alvjakso?: AlvIlmoitusjakso
  teksti?: string
  kirjanpitaja?: string
}

export interface AsiakasSalesListaAsiakas {
  /** id */
  i: number
  /** avain, Key */
  k: string
  /** Name */
  n: string
  /** Y-tunnus */
  y: string
  /** Muistiinpanot */
  m: string
  /** Muistiinpanojen väri */
  f?: string
  /** Sopimus hyväksymättä */
  bsh?: 1
  /**
   * Hinta. Jos nykyinen, vain arvo. Jos tulevaisuudessa, listassa on myös alkamispvm suluissa.
   */
  h: string
}
export interface AsiakasSalesListaAsiakasResponse extends ErrorResponse<'unknown'> {
  asiakkaat?: AsiakasSalesListaAsiakas[]
}

export interface AsiakashakuAsiakas {
  /** id */
  i: number
  /** avain, Key */
  k: string
  /** Name */
  n: string
  /** Y-tunnus */
  y: string
  /** Vastuukirjanpitäjän avain */
  v?: string
}
export interface AsiakashakuResponse extends ErrorResponse<'unknown'> {
  asiakkaat?: AsiakashakuAsiakas[]
}

export interface AsiakkaidenSpostiosoitteidenExportReq {
  scope: KayttajienTietojenLatauslaajuus
  asiakkaidenAvaimet: string[]
}

export interface AsiakkaidenSpostiosoitteidenExportResp extends ErrorResponse {
  data: string[][]
}

export interface AsiakkaanKuukausiruutuStatusSaveRequest {
  asiakasAvain: string
  ruutu: KirjanpitoKuukausiruutu
}

export interface AsiakkaanKuukausiruutuStatusSaveResponse extends ErrorResponse { }

export const ytjYtunnusTapahtumat = ['Tunnus annettu']
export type YtjYtunnusTapahtuma = typeof ytjYtunnusTapahtumat[number]

export const ytjRekisterit = ['Kaupparekisteri', 'Verohallinnon perustiedot', 'Ennakkoperintärekisteri', 'Arvonlisäverovelvollisuus', 'Työnantajarekisteri', 'Yhdistysrekisteri'] as const
export type YtjRekisteri = typeof ytjRekisterit[number]

export type YtjRekisterinTila = YtjAlvrekisterinTila | YtjEnnakkoperintarekisterinTila | YtjKaupparekisterinTila | YtjTyonantajarekisterinTila | YtjVerohallinnonPerustiedotRekisterinTila | YtjYhdistysrekisterinTila

export const ytjEnnakkoperintarekisterinTila = ['Rekisterissä', 'Aiheeton rivi']
export type YtjEnnakkoperintarekisterinTila = typeof ytjEnnakkoperintarekisterinTila[number]

export const ytjKaupparekisterinTilat = ['Rekisterissä', 'Lakannut', 'Ei rekisteröity perustaminen', 'Rekisteröimätön']
export type YtjKaupparekisterinTila = typeof ytjKaupparekisterinTilat[number]

export const ytjTyonantajarekisterinTilat = ['Rekisterissä', 'Aiheeton rivi']
export type YtjTyonantajarekisterinTila = typeof ytjTyonantajarekisterinTilat[number]

export const ytjVerohallinnonPerustiedotRekisterinTila = ['Rekisterissä']
export type YtjVerohallinnonPerustiedotRekisterinTila = typeof ytjVerohallinnonPerustiedotRekisterinTila[number]

export const ytjYhdistysrekisterinTilat = ['Rekisterissä', 'Lakannut']
export type YtjYhdistysrekisterinTila = typeof ytjYhdistysrekisterinTilat[number]

export interface YtjAsiakasOsoiteData {
  katu: string
  postitmp: string
  postinro: string
  co: string
  ulkom: string
  maa: string
  maaKoodi: string
  lisatiedot: string
}

export interface YtjAsiakasData {

  asiakasAvain: string
  ytunnus: string

  henkilo: string

  toiminimi: string
  toiminimip: LocalDate

  aputoiminimet: YtjAsiakasToiminimiData[]
  rinnakkaistoiminimet: YtjAsiakasToiminimiData[]

  yritysmuoto: string
  yritysmuotokoodi: string
  yritysmuotop: LocalDate

  koti: string
  kotikoodi: string
  kotip: LocalDate

  kieli: string
  kielip: LocalDate

  paatoimiala: string
  paatoimialakoodi: string
  paatoimialap: LocalDate

  postiosoite: YtjAsiakasOsoiteData
  postiosoitep: LocalDate

  kayntiosoite: YtjAsiakasOsoiteData
  kayntiosoitep: LocalDate

  konkurssissa: boolean
  velkajarjestelyssa: boolean
  saneerauksessa: boolean
  selvitystilassa: boolean
  toimintakeskeytetty: boolean

  rekisterit: YtjAsiakasRekisteriData[]

  yhteystiedot: YtjAsiakasYhteystietoData[]

}

export type YtjAsiakasRekisteriData = {
  nimi: YtjRekisteri
  tila: YtjRekisterinTila
  p: LocalDate
} & (
    { nimi: 'Arvonlisäverovelvollisuus', tila: YtjAlvrekisterinTila, p: LocalDate } |
    { nimi: 'Kaupparekisteri', tila: YtjKaupparekisterinTila, p: LocalDate } |
    { nimi: 'Verohallinnon perustiedot', tila: YtjVerohallinnonPerustiedotRekisterinTila, p: LocalDate } |
    { nimi: 'Ennakkoperintärekisteri', tila: YtjEnnakkoperintarekisterinTila, p: LocalDate } |
    { nimi: 'Työnantajarekisteri', tila: YtjTyonantajarekisterinTila, p: LocalDate } |
    { nimi: 'Yhdistysrekisteri', tila: YtjYhdistysrekisterinTila, p: LocalDate }
  )

export interface YtjAsiakasYhteystietoData {
  yhteystieto: string
  p: LocalDate
}

export interface YtjAsiakasToiminimiData {
  nimi: string
  p: LocalDate
}

export type YtjAsiakasDataVersionChangeReason = 'toiminimi' | 'ytunnus' | 'yritysmuoto' | 'kotipaikka' | 'kieli' | 'paatoimiala' | 'konkurssissa' | 'velkajarjestelyssa' | 'saneerauksessa' | 'selvitystilassa' | 'toimintakeskeytetty'
  | 'kayntiosoite.katu' | 'postiosoite.katu'
  | 'kayntiosoite.tmp' | 'postiosoite.tmp'
  | 'kayntiosoite.nro' | 'postiosoite.nro'
  | 'kayntiosoite.co' | 'postiosoite.co'
  | 'kayntiosoite.ulkom' | 'postiosoite.ulkom'
  | 'kayntiosoite.maa' | 'postiosoite.maa'
  | 'kayntiosoite.lisatiedot' | 'postiosoite.lisatiedot'
  | 'aputoiminimi' | 'rinnakkaistoiminimi'
  | 'rekisteri' | 'yhteystiedot' | 'ensimmainen'
  | 'henkilo'
export interface YtjAsiakasDataHistoryVersion extends YtjAsiakasData {
  versionCreated: Timestamp
  versionChangeReason: YtjAsiakasDataVersionChangeReason[]
  previousVersions: boolean
}

export interface YtjAsiakasDataTyojonoData {
  asiakasAvain: string
  aloitettu: Timestamp
}


export interface AnnaAsiakkaidenNimitiedotPyynto {
  /**
   * Limit results to this kirjanpitaja
   */
  kirjanpitajaAvain?: string
}
export interface AnnaAsiakkaidenNimitiedotVastaus {
  nimitiedot: AsiakkaanNimitiedot[]
}
export interface AsiakkaanNimitiedot {
  avain: string
  asiakasId: number
  nimi: string
  ytunnus: string
}

export interface PakotaSopimuksenUudelleenAllekirjoitusPyynto {
  vaadiKayttajiltaUusiTunnistautuminen: boolean
  naytaKayttajilleTervetuloaDialogi: boolean
  vaadiPepTietojenPaivittaminen: boolean
  vaadiSopimuksenAllekirjoittaminenUudelleen: boolean
  asiakasAvain: string
}
export interface PakotaSopimuksenUudelleenAllekirjoitusVastaus {

}

export interface PerutaSopimuksenPaattyminenPyynto {
  asiakasAvain: string
  sopimuskausiAvain: string
}

export interface PerutaSopimuksenPaattyminenVastaus extends ErrorResponse<'unknown-error'> {

}

