<div *ngIf="kirjanpitajaOnDevaajaObservable | async" style="padding-bottom: 20px;">
  <h3 style="margin: 0;">Urit</h3>
  <div style="padding-top: 1em; padding-left: 1em; padding-right: 1em;">Käyttäjän tallentamat tiedot: <a [href]="liitetiedotEncodedUriObservable | async" target="_BLANK">{{liitetiedotUriObservable | async}}</a></div>
</div>

<form [formGroup]="form">

  <div *ngIf="lukittuObservable | async" style="padding: 3em; font-weight: bold;">
    Tilinpäätös on rekisteröity. Tietoja ei voi enää muokata.
  </div>

  <div style="background-color: #F2F2F2; padding: 15px;">
    <div style="margin: 20px 5px 0 5px;">Onko yhtiö mikro- vai pienyritys?</div>
    <div style="padding: 8px 15px 0 15px;">
      <ng-container *ngIf="tilikausiAlkanut2024JalkeenObservable | async; else aiemmatTilikaudet">
        <mat-checkbox formControlName="taseenLoppusummaYli" class="cb pitka-label" name="o1">{{'PMA1_1_5.ONKO_MIKROYRITYS_TASEEN_LOPPUSUMMA_YLI_2024_ALKAEN' | translatetilinpaatos: 2021 | async }}</mat-checkbox> <br />
        <mat-checkbox formControlName="liikevaihtoYli" class="cb pitka-label" name="o2">{{'PMA1_1_5.ONKO_MIKROYRITYS_LIIKEVAIHTO_YLI_2024_ALKAEN' | translatetilinpaatos: 2021 | async }}</mat-checkbox><br />
        <mat-checkbox formControlName="henkilostoYli" class="cb pitka-label" name="o3">{{'PMA1_1_5.ONKO_MIKROYRITYS_HENKILOSTO_YLI_2024_ALKAEN' | translatetilinpaatos: 2021 | async }}</mat-checkbox><br />
      </ng-container>
      <ng-template #aiemmatTilikaudet>
        <mat-checkbox formControlName="taseenLoppusummaYli" class="cb pitka-label" name="o1">{{'PMA1_1_5.ONKO_MIKROYRITYS_TASEEN_LOPPUSUMMA_YLI' | translatetilinpaatos: 2021 | async }}</mat-checkbox> <br />
        <mat-checkbox formControlName="liikevaihtoYli" class="cb pitka-label" name="o2">{{'PMA1_1_5.ONKO_MIKROYRITYS_LIIKEVAIHTO_YLI' | translatetilinpaatos: 2021 | async }}</mat-checkbox><br />
        <mat-checkbox formControlName="henkilostoYli" class="cb pitka-label" name="o3">{{'PMA1_1_5.ONKO_MIKROYRITYS_HENKILOSTO_YLI' | translatetilinpaatos: 2021 | async }}</mat-checkbox><br />
      </ng-template>
    </div>

    <div style="font-weight: bold; margin: 15px 0 30px 15px;">
      <div *ngIf="onkoMikro; else pienyritys">
        Tilinpäätös tehdään mikroyrityssäännöstön mukaisesti.
      </div>
      <ng-template #pienyritys>
        Tilinpäätös tehdään pienyrityssäännöstön mukaisesti
      </ng-template>
    </div>

    <div style="padding-bottom: 10px;">
      <div>Tilinpäätöksessä käytettävä tuloslaskelma:</div>
      <mat-radio-group style="margin: 15px;" name="tilinpaatoksenTuloslaskelma" formControlName="tilinpaatoksenTuloslaskelma">
        <mat-radio-button [value]="TilinpaatoksenTuloslaskelmanTyyppi.VIRALLINEN">Virallinen</mat-radio-button>
        <mat-radio-button [value]="TilinpaatoksenTuloslaskelmanTyyppi.BRUTTO">Brutto</mat-radio-button>
      </mat-radio-group>
    </div>

    <div>
      <!-- <div>Tällä työkalulla voit luoda tilinpäätöksen liitetiedot "tavanomaiselle" mikro-osakeyhtiölle ja siitä löytyy liitetietojen yleisimmät kohdat. Jos tilinpäätös vaatii jonkin seikan esittämistä, joka ei onnistu työkalua käyttämällä, pitää liitetiedot tehdä ohjelman ulkopuolella.</div> -->
      <label style="padding: 20px 5px 5px 5px; display: block;" id="tilintarkastetaan-radio-group-label">Tilinpäätös tilintarkastetaan:</label>
      <mat-radio-group formControlName="tilintarkastetaan" aria-labelledby=" tilintarkastetaan-radio-group-label">
        <mat-radio-button [value]="true" style="margin-left: 10px; display: block;">Kyllä</mat-radio-button>
        <mat-radio-button [value]="false" style="margin-left: 10px; display: block;">Ei</mat-radio-button>
      </mat-radio-group>
      <mat-error *ngIf="tilintarkastetaan?.touched && tilintarkastetaan?.errors?.required">Tieto tilintarkastuksesta puuttuu.</mat-error>

      <label [class.mat-form-field-invalid]="allekirjoitetaanLemonaidissa?.touched && allekirjoitetaanLemonaidissa?.errors?.required" style="padding: 20px 5px 5px 5px; display: block;" id="allekirjoitetaanLemonaidissa-radio-group-label">Tilinpäätös allekirjoitetaan Lemonaidissa:</label>
      <mat-radio-group formControlName="allekirjoitetaanLemonaidissa" aria-labelledby="allekirjoitetaanLemonaidissa-radio-group-label" name="o3445">
        <mat-radio-button [value]="true" style="margin-left: 10px; display: block;">Kyllä</mat-radio-button>
        <mat-radio-button [value]="false" style="margin-left: 10px; display: block;">Ei</mat-radio-button>
      </mat-radio-group>
      <mat-error *ngIf="allekirjoitetaanLemonaidissa?.touched && allekirjoitetaanLemonaidissa?.errors?.required">Tieto allekirjoituksesta puuttuu.</mat-error>
    </div>
  </div>

</form>

<button *ngIf="!(lukittuObservable | async)" mat-button class="primarybutton" style="margin: 30px 15px;" [disabled]="tilinpaatoksenTuloslaskelma?.errors || tilintarkastetaan?.errors || this.allekirjoitetaanLemonaidissa?.errors " (click)="aloitaTilinpaatos()">Aloita tilinpäätöksen tekeminen</button>