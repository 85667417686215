<div class="list-page" style="max-width: 2000px; padding: 1em;">

  <div *ngIf="kirjanpitajaOnDevaajaObservable | async" style="padding-bottom: 20px;">
    <h3 style="margin: 0;">Urit</h3>
    <div style="padding-top: 1em; padding-left: 1em; padding-right: 1em;">Päätilikartta: <a [href]="tilikarttaEncodedUriObservable | async" target="_BLANK">{{tilikarttaUriObservable | async}}</a></div>
  </div>

  <mat-tab-group>
    <mat-tab label="Päätilikartta">
      <div style="padding: 1em;">
        <div style="margin-bottom: 10px;">

          <button mat-button (click)="lisaa()" type="button" class="primarybutton mat-elevation-z4" style="padding-left: 8px;">
            <mat-icon>add</mat-icon>
            Lisää tili
          </button>

        </div>

        <div *ngIf="commonError" class="error-text">{{ commonError }}</div>

        <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
        <div class="example-list" [class.hidden]="loading" cdkDropList (cdkDropListDropped)="drop($event)">
          <div (click)="muokkaa(node.kirjanpitotili)" class="example-box" *ngFor="let node of flatatyt | async" [style.padding-left]="(node.level * 15) + 'px'" cdkDrag>
            {{node.name}}
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Profiilit">
      <div app-profiili-paatilikartta style="padding: 1em;"></div>
    </mat-tab>
  </mat-tab-group>

</div>