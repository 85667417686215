import { Injectable } from '@angular/core'

import { DomSanitizer } from '@angular/platform-browser'

import { FirebaseLemonator, FirebaseLemonaid } from '../firebase-lemonator.service'

import { LemonHttpService } from '../lemon-http.service'

import { AsiakasService, AsiakkaanAvainTiedot } from '../asiakas/asiakas.service'

import { KirjanpitoKuukausiruutu } from 'app/_jaettu-lemonator/model/asiakas'
import { AsiakasJaettuService } from 'app/_jaettu-lemonator/service/asiakas-jaettu.service'

import { TositeUriService } from 'app/_jaettu/service/tosite/tosite-uri.service'
import { DateService } from 'app/_shared-core/service/date.service'
import { CurrencyService } from 'app/_shared-core/service/currency.service'
import { TimestampProviderBase } from 'app/_shared-core/service/timestamp-provider.interface'

import { AlvIlmoitus, KirjaukseenLiitettyjenTiedostojenSivut, KirjattavaKuitti, KirjattavaLasku, Kirjaus, KirjauksenKuitinTallennuspyynto, KirjauksenLaskunTallennuspyynto, KirjauksenLiitetiedostonPoistopyynto, KirjauksenRaahattavanTallennuspyynto, KirjaukseenLiitetynTiedostonSivunTyyppi, KirjaukseenLiitetynTiedostonSivu, KirjattavaRaahattuTiedosto, KirjanpidonProjekti } from 'app/_jaettu-lemonator/model/kirjanpito'
import { KirjanpitoUriService } from 'app/_jaettu-lemonator/service/kirjanpito-uri.service'

import { KirjanpitoJaettuService } from 'app/_jaettu-lemonator/service/kirjanpito-jaettu.service'
import { KirjanpitajanNimitiedot } from 'app/_jaettu-lemonator/model/kirjanpitaja'
import { KopioijaPalvelu } from 'app/_jaettu/service/kopioija.service'

import { NaytettavaKuitti, NaytettavaLasku, NaytettavaRaahattava } from 'app/kirjanpito/tositteet/tosite-kirjaamattomat.component'
import { SivuJaAvain } from 'app/kirjanpito/tositteet/tosite-naytto.component'
import { NaytettavaKirjaus } from 'app/kirjanpito/kirjanpito.component'
import { AsiakasUriService } from 'app/_jaettu-lemonator/service/asiakas-uri.service'
import { DebugService } from '../debug.service'
import { LaskuPdfEsikatselutiedot } from 'app/_angular/_components/lasku.perinteinen-rajoitettu-leveyteen.component'
import { Laskuasetukset, LaskunTila } from 'app/_jaettu/model/lasku'
import { KirjanpitoImageService } from './kirjanpito-image.service'
import { DEFAULT_IMAGE_ZOOM } from 'app/_angular/_components/image-viewer.component'

import { BehaviorSubject, firstValueFrom, Observable, of, Subject } from 'rxjs'
import { switchMap, startWith, map, distinctUntilChanged } from 'rxjs/operators'
import { FirestoreTosite } from 'app/_jaettu/model/tosite'
import { HttpClient } from '@angular/common/http'
import { PdfService, SivuPdfsta } from 'app/_jaettu-angular/service/pdf.service'
import { TositeTyyppi } from 'app/kirjanpito/tositteet/tosite-standalone.component'
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator'
import { EraajotYksiKerrallaanSuorittajaData } from 'app/_jaettu/model/eraajot'


type TositteenLahde = 'lemonaid-kuitti' | 'lemonaid-lasku' | 'holvi' | 'kirjanpitaja'
export interface TositeJaNaytettavaSivut {
  tyyppi: TositeTyyppi
  avain: string
  jarjestys: number
  tallennetaan?: boolean
  poistetaan?: boolean
  // kirjanpitajanLisaama: boolean
  // teksti: string
  kuitinAvain?: string
  kuittiTekstiPromise?: Promise<string>
  luottotappio?: true
  lahde: TositteenLahde
  sivut: NaytettavaSivuUusi[]
}

export interface NaytettavaSivuUusi {
  avain: string
  valittu: boolean
  sivu: KirjaukseenLiitetynTiedostonSivu
  type: 'lasku' | 'kuitti' | 'kuitti-async' | 'pdf'
  zoomable: boolean
  zoomMultiplier: number
  rotatable: boolean
  rotationDegrees: '0' | '90' | '180' | '270'
  ladataan: boolean
  jarjestys: number
  virhe?: any
  url?: string
  urlEnhanced?: string
  useEnhancedUrl?: 1
  ulrPromise?: Promise<string>
  esikatselutiedot?: LaskuPdfEsikatselutiedot
}

export interface NaytettavaSivuKuitti extends NaytettavaSivuUusi {
  type: 'kuitti'
  url: string
  zoomable: true
  rotatable: true
}

export interface NaytettavaSivuKuittiAsync extends NaytettavaSivuUusi {
  type: 'kuitti-async'
  ulrPromise: Promise<string>
  zoomable: true
  rotatable: true
}

export interface NaytettavaSivuPdf extends NaytettavaSivuUusi {
  type: 'pdf'
  url: string
  zoomable: true
  rotatable: true
}

export interface NaytettavaSivuLasku extends NaytettavaSivuUusi {
  type: 'lasku'
  esikatselutiedot: LaskuPdfEsikatselutiedot
  zoomable: false
  rotatable: false
}

export interface ListausAlvIlmoitus extends AlvIlmoitus {
  tunnistetiedotString: string
  maksettavaTaiPalautettavaVero: number
  edellinenMaksettavaTaiPalautettavaVero: number
  tallennustiedot: string
  url: string
  uri: string
}

export interface KirjausJaSivunMuutos {
  kirjaus: Kirjaus
  sivut: KirjaukseenLiitettyjenTiedostojenSivut
}

export interface TositteenEsikatselutiedot {
  tunniste: string
  sivut: KirjaukseenLiitettyjenTiedostojenSivut
  naytettavaKuitti?: NaytettavaKuitti
  naytettavaLasku?: NaytettavaLasku
  naytettavaRaahattava?: NaytettavaRaahattava
}

@Injectable()
export class KirjanpitoImageHandlerService {

  /**
   * Tarkoitus on hoitaa tätä kautta notifikaatiot:
   * 1. Kuitin, laskun ja tiedoston kiinnittämisestä (droppaaminen kirjaukseen)
   * 2. Kuitin, laskun ja tiedoston irroittamisesta (droppaaminen vasen alakulma)
   * 3. Sivun poistamisesta (Ruksi ei esikatselussa)
   * 4. Esikatselun sulkeminen?
   *
   * Lisäksi tiedoitukset ja tila kun
   * 1. Tiedoston tallentaminen alkaa + epäonnistuu (Side Feed instant view-ominaisuudelle)
   * 2. Kiinnityksen poistaminen alkaa + epäonnistuu
   * 3. Kiinnityksen tekeminen alkaa + epäonnistuu
   */


  private _esikatseltavaTositeSubject: BehaviorSubject<TositteenEsikatselutiedot> = new BehaviorSubject(null)
  /** Esikatselun hallinta */
  esikatseltavaTositeObservable: Observable<TositteenEsikatselutiedot> = this._esikatseltavaTositeSubject.asObservable()

  // Tositteet joiden lisääminen on kesken
  private _tositteetJoidenLiittaminenOnKeskenMap: Map<string, TositeJaNaytettavaSivut[]> = new Map()
  private _tositteenLiittaminenAloitettiinSubject: Subject<TositeJaNaytettavaSivut> = new Subject()
  tositteenLiittaminenAloitettiinObservable: Observable<TositeJaNaytettavaSivut> = this._tositteenLiittaminenAloitettiinSubject.asObservable()
  private _tositteenLiittaminenEpaonnistuiSubject: Subject<TositeJaNaytettavaSivut> = new Subject()
  tositteenLiittaminenEpaonnistuiObservable: Observable<TositeJaNaytettavaSivut> = this._tositteenLiittaminenEpaonnistuiSubject.asObservable()
  private _tositteenLiittaminenOnnistuiSubject: Subject<TositeJaNaytettavaSivut> = new Subject()
  tositteenLiittaminenOnnistuiObservable: Observable<TositeJaNaytettavaSivut> = this._tositteenLiittaminenOnnistuiSubject.asObservable()

  // Tositteet, joiden liitoksen poistaminen on kesken
  private _tositteetJoidenLiitoksenPoistoOnKeskenMap: Map<string, TositeJaNaytettavaSivut[]> = new Map()
  private _tositteenLiitoksenPoistoAloitettiinSubject: Subject<TositeJaNaytettavaSivut> = new Subject()
  tositteenLiitoksenPoistoAloitettiinObservable: Observable<TositeJaNaytettavaSivut> = this._tositteenLiitoksenPoistoAloitettiinSubject.asObservable()
  private _tositteenLiitoksenPoistoEpaonnistuiSubject: Subject<TositeJaNaytettavaSivut> = new Subject()
  tositteenLiitoksenPoistoEpaonnistuiObservable: Observable<TositeJaNaytettavaSivut> = this._tositteenLiitoksenPoistoEpaonnistuiSubject.asObservable()
  private _tositteenLiitoksenPoistoOnnistuiSubject: Subject<TositeJaNaytettavaSivut> = new Subject()
  tositteenLiitoksenPoistoOnnistuiObservable: Observable<TositeJaNaytettavaSivut> = this._tositteenLiitoksenPoistoOnnistuiSubject.asObservable()

  constructor(
    private _asiakasService: AsiakasService,
    private _tositeUriService: TositeUriService,
    private _kirjanpitoImageService: KirjanpitoImageService,
    private _kirjanpitoJaettuService: KirjanpitoJaettuService,
    private _firebase: FirebaseLemonator,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _http: HttpClient,
    private _pdfService: PdfService,
  ) {
    this._asiakasService.nykyinenAsiakasAvainObservable.subscribe(asiakas => {
      this._tositteetJoidenLiittaminenOnKeskenMap.clear()
      this._tositteetJoidenLiitoksenPoistoOnKeskenMap.clear()
    })
  }

  lopetaEsikatselu() {
    if (this._esikatseltavaTositeSubject.value !== null) {
      this._esikatseltavaTositeSubject.next(null)
    }
  }

  aloitaEsikatselu(tiedot: TositteenEsikatselutiedot) {
    if (tiedot?.tunniste !== this._esikatseltavaTositeSubject.value?.tunniste) {
      this._esikatseltavaTositeSubject.next(tiedot)
    }
  }

  muunnaKirjattavaKuittiLiitetyiksiSivuiksi(kirjattavaKuitti: KirjattavaKuitti): KirjaukseenLiitettyjenTiedostojenSivut {
    const tosite = kirjattavaKuitti && kirjattavaKuitti.kuitti ? kirjattavaKuitti.kuitti[kirjattavaKuitti.kuitti.length - 1] : null
    if (!tosite) {
      return
    }
    const sivut: KirjaukseenLiitettyjenTiedostojenSivut = {
      t: {},
      p: 1
    }
    this._kirjanpitoJaettuService.muutaKuittiLiitetyiksiSivuiksi(tosite, sivut)
    return sivut
  }

  muunnaKirjattavaRaahattavaLiitetyiksiSivuiksi(raahattu: NaytettavaRaahattava): KirjaukseenLiitettyjenTiedostojenSivut {
    if (!raahattu || !raahattu.raahattava || !raahattu.raahattava.s) {
      return
    }
    return this.muunnaKirjattavaRaahattuTiedostoLiitetyiksiSivuiksi(raahattu.avain, raahattu.raahattava)
  }

  muunnaKirjattavaRaahattuTiedostoLiitetyiksiSivuiksi(raahatunAvain: string, raahattu: KirjattavaRaahattuTiedosto): KirjaukseenLiitettyjenTiedostojenSivut {
    if (!raahattu || !raahatunAvain) {
      return
    }

    const sivut: KirjaukseenLiitettyjenTiedostojenSivut = {
      t: {},
      p: 1
    }
    sivut.t[raahatunAvain] = raahattu.s

    return sivut
  }

  muunnaKirjattavaLaskuLiitetyiksiSivuiksi(kirjattavaLasku: KirjattavaLasku): KirjaukseenLiitettyjenTiedostojenSivut {
    const lasku = this._kirjanpitoJaettuService.annaLaskuKirjattavastaLaskusta(kirjattavaLasku)
    if (!lasku) {
      return
    }

    const sivut: KirjaukseenLiitettyjenTiedostojenSivut = {
      t: {},
      p: 1
    }
    this._kirjanpitoJaettuService.muutaLaskuLiitetyiksiSivuiksi(lasku, sivut)

    return sivut
  }

  async muunnaTositteiksiJaSivuiksi(
    asiakas: AsiakkaanAvainTiedot,
    sivut: KirjaukseenLiitettyjenTiedostojenSivut,
    lisaaminenKesken: TositeJaNaytettavaSivut[],
    liitoksenPoistoKesken: TositeJaNaytettavaSivut[],
    naytaPoistetutKirjaukset: boolean
  ): Promise<TositeJaNaytettavaSivut[]> {
    const tositteetJaSivutMap: Map<string, TositeJaNaytettavaSivut> = new Map()

    if (sivut?.t) {
      const olemassaOlevatAvaimet = Object.keys(sivut.t)
      for (const olemassaOlevanAvain of olemassaOlevatAvaimet) {
        const sivu = sivut.t[olemassaOlevanAvain]
        const avain = sivu.a ?? olemassaOlevanAvain
        if (
          sivu.t === KirjaukseenLiitetynTiedostonSivunTyyppi.KUITTI_NO_PAGES ||
          lisaaminenKesken?.find(a => a.avain === avain) ||
          (!naytaPoistetutKirjaukset && sivu.h)
        ) {
          continue
        }
        await this._lisaaSivuTositteeseen(asiakas, olemassaOlevanAvain, sivu, liitoksenPoistoKesken, tositteetJaSivutMap)
      }
    }
    // console.log('Lisääminen kesken', lisaaminenKesken)

    const palautettava = Array.from(tositteetJaSivutMap.values())
    if (lisaaminenKesken) {
      for (const lisattava of lisaaminenKesken) {
        palautettava.push(lisattava)
      }
    }

    const ordered = palautettava.sort((a, b) => {
      return a.jarjestys - b.jarjestys
    })

    // let holvistaCount = 0
    // let lemonaidistaCount = 0
    // let kirjanpitajanCount = 0
    for (const tosite of ordered) {
      if (tosite.tyyppi === 'lasku') {
        for (const sivu of tosite.sivut) {
          if (sivu.esikatselutiedot?.juurilasku?.tila === LaskunTila.luottotappio) {
            tosite.luottotappio = true
            break
          }
        }
      }
      // if (tosite.lahde === 'lemonaid-kuitti' || tosite.lahde === 'lemonaid-lasku') {
      //   lemonaidistaCount++
      //   tosite.teksti = 'Lemonaidtosite ' + lemonaidistaCount
      // } else if (tosite.lahde === 'kirjanpitaja') {
      //   kirjanpitajanCount++
      //   tosite.teksti = 'Kirjanpitäjän lisäämä ' + kirjanpitajanCount
      // } else if (tosite.lahde === 'holvi') {
      //   holvistaCount++
      //   tosite.teksti = 'Holvitosite ' + holvistaCount
      // } else {
      //   throw Error('Tuntematon lähde: ' + tosite.lahde)
      // }
      if (tosite.kuitinAvain && tosite.lahde !== 'holvi') {
        const uri = this._tositeUriService.annaKuitinFirestoreUri(asiakas.asiakasId + '', tosite.kuitinAvain)

        tosite.kuittiTekstiPromise = this._firebaseLemonaid.firestoreDoc<FirestoreTosite>(uri).get().then(
          kuitti => {
            if (!kuitti) {
              return ''
            }
            return (kuitti.selite || '') + ' ' + (kuitti.alvVahennysoikeus ?? '') + '%'
          }
        )
      }
      // if (tosite.lahde === 'holvi') {
      //   tosite.sivut.sort((a, b) => {
      //     if (a.url && b.url) {
      //       return b.url.localeCompare(a.url)
      //     }
      //     return b.jarjestys - a.jarjestys
      //   })
      // } else {
      // tosite.sivut.sort((a, b) => {
      // if (a.url && b.url) {
      //   return a.url.localeCompare(b.url)
      // }
      //   return a.jarjestys - b.jarjestys
      // })
      // }
    }

    return ordered

  }

  private async _muutaVanhaSivuUusiksiSivuiksiJaLisaaListaan(lista: NaytettavaSivuUusi[], asiakas: AsiakkaanAvainTiedot, olemassaOlevanAvain: string, sivu: KirjaukseenLiitetynTiedostonSivu): Promise<void> {
    if (sivu.t === KirjaukseenLiitetynTiedostonSivunTyyppi.KUITTI_ALKUPERAINEN_PDF_RAJAYTETTY_JPEG_SIVUIKSI) {
      const urlit = this._kirjanpitoImageService.annaPdfSivujenObservablet(asiakas, olemassaOlevanAvain, sivu)
      if (urlit) {
        let index = 1
        for (const url of urlit) {
          const naytettavaSivu: NaytettavaSivuKuitti = {
            avain: olemassaOlevanAvain,
            valittu: false,
            sivu: sivu,
            url: url.pakattu as string,
            type: 'kuitti',
            zoomable: true,
            zoomMultiplier: DEFAULT_IMAGE_ZOOM,
            rotatable: true,
            rotationDegrees: '0',
            jarjestys: sivu.o + index,
            ladataan: true
          }
          if (url.parempi) {
            naytettavaSivu.urlEnhanced = url.parempi as string
          }
          lista.push(naytettavaSivu)
          index++
        }
      }
    } else if (
      sivu.t === KirjaukseenLiitetynTiedostonSivunTyyppi.KUITTI_JPEG ||
      sivu.t === KirjaukseenLiitetynTiedostonSivunTyyppi.RAAHATTU_WEBP ||
      sivu.t === KirjaukseenLiitetynTiedostonSivunTyyppi.KUITTI_ALKUPERAINEN_MUU
    ) {
      const enhancable = sivu.t !== KirjaukseenLiitetynTiedostonSivunTyyppi.KUITTI_ALKUPERAINEN_MUU

      const naytettavaSivu: NaytettavaSivuKuitti = {
        avain: olemassaOlevanAvain,
        valittu: false,
        sivu: sivu,
        url: this._kirjanpitoImageService.annaSivunKuvanUrl(asiakas, olemassaOlevanAvain, sivu, true) as string,
        type: 'kuitti',
        zoomable: true,
        zoomMultiplier: DEFAULT_IMAGE_ZOOM,
        rotatable: true,
        rotationDegrees: '0',
        jarjestys: sivu.o,
        ladataan: true
      }
      if (enhancable) {
        naytettavaSivu.urlEnhanced = this._kirjanpitoImageService.annaSivunKuvanUrl(asiakas, olemassaOlevanAvain, sivu, false) as string
      }
      lista.push(naytettavaSivu)
    } else if (
      sivu.t === KirjaukseenLiitetynTiedostonSivunTyyppi.KUITTI_ALKUPERAINEN_PDF ||
      sivu.t === KirjaukseenLiitetynTiedostonSivunTyyppi.RAAHATTU_PDF
    ) {

      const url = this._kirjanpitoImageService.annaSivunKuvanUrl(asiakas, olemassaOlevanAvain, sivu, true) as string
      const pdfSivut = await this._annaPdfnSivut(url)
      let index = 1
      for (const pdfSivu of pdfSivut) {
        const naytettavaSivu: NaytettavaSivuKuittiAsync = {
          avain: olemassaOlevanAvain,
          valittu: false,
          sivu: sivu,
          ulrPromise: pdfSivu.aloitaKuvanLataaminen(),
          type: 'kuitti-async',
          zoomable: true,
          zoomMultiplier: DEFAULT_IMAGE_ZOOM,
          rotatable: true,
          rotationDegrees: '0',
          jarjestys: sivu.o + index,
          ladataan: true
        }
        lista.push(naytettavaSivu)
        index++
      }

      // NÄYTÄ PDF
      // const naytettavaSivu: NaytettavaSivuPdf = {
      //   avain: olemassaOlevanAvain,
      //   valittu: false,
      //   sivu: sivu,
      //   url: this._kirjanpitoImageService.annaSivunObservable(asiakas, olemassaOlevanAvain, sivu) as string,
      //   type: 'pdf',
      //   zoomable: true,
      //   zoomMultiplier: DEFAULT_IMAGE_ZOOM,
      //   rotatable: true,
      //   rotationDegrees: '0',
      //   runningIndex: 0
      // }
      // existing.sivut.push(naytettavaSivu)
    } else if (sivu.t === KirjaukseenLiitetynTiedostonSivunTyyppi.LASKU) {

      const laskunVersiot: NaytettavaSivuUusi[] = []

      const naytettavaSivu: NaytettavaSivuLasku = {
        avain: olemassaOlevanAvain,
        valittu: false,
        sivu: sivu,
        esikatselutiedot: { juurilasku: sivu.l, kasiteltava: sivu.l, asetukset: sivu.l.asetukset }, // this._annaLaskunEsikatselutiedotObervable(sivu.l, sivu.l),
        type: 'lasku',
        zoomable: false,
        zoomMultiplier: DEFAULT_IMAGE_ZOOM,
        rotatable: false,
        rotationDegrees: '0',
        jarjestys: 0, // Assigned later
        ladataan: true
      }
      laskunVersiot.push(naytettavaSivu)

      if (sivu.l.korvaus) {
        for (const korvaava of sivu.l.korvaus) {
          const korvaavaNaytettavaSivu: NaytettavaSivuLasku = {
            avain: olemassaOlevanAvain,
            valittu: false,
            sivu: sivu,
            esikatselutiedot: { juurilasku: sivu.l, kasiteltava: korvaava, asetukset: korvaava.asetukset },
            type: 'lasku',
            zoomable: false,
            zoomMultiplier: DEFAULT_IMAGE_ZOOM,
            rotatable: false,
            rotationDegrees: '0',
            jarjestys: 0, // Assigned later
            ladataan: true
          }
          laskunVersiot.push(korvaavaNaytettavaSivu)
        }
      }
      const reversed = laskunVersiot.reverse()
      let index = 1
      for (const s of reversed) {
        s.jarjestys = sivu.o + index
        index++
      }
      lista.push(...reversed)
    } else if (sivu.t === KirjaukseenLiitetynTiedostonSivunTyyppi.RAAHATTU_PDF_RAJAYTETTY_SIVUIKSI) {
      const urlit = this._kirjanpitoImageService.annaRaahatunPdfSivujenUrlit(asiakas, olemassaOlevanAvain, sivu)
      if (urlit) {
        let index = 1
        for (const url of urlit) {
          const naytettavaSivu: NaytettavaSivuKuitti = {
            avain: olemassaOlevanAvain,
            valittu: false,
            sivu: sivu,
            url: url.pakattu as string,
            type: 'kuitti',
            zoomable: true,
            zoomMultiplier: DEFAULT_IMAGE_ZOOM,
            rotatable: true,
            rotationDegrees: '0',
            jarjestys: sivu.o + index,
            ladataan: true
          }
          if (url.parempi) {
            naytettavaSivu.urlEnhanced = url.parempi as string
          }
          lista.push(naytettavaSivu)
          index++
        }
      }
    }
  }

  private async _lisaaSivuTositteeseen(
    asiakas: AsiakkaanAvainTiedot,
    olemassaOlevanAvain: string,
    sivu: KirjaukseenLiitetynTiedostonSivu,
    liitoksenPoistoKesken: TositeJaNaytettavaSivut[],
    tositteetJaSivutMap: Map<string, TositeJaNaytettavaSivut>
  ) {
    const avain = sivu.a ?? olemassaOlevanAvain
    let existing = tositteetJaSivutMap.get(avain)
    if (!existing) {
      let lahde: TositteenLahde = 'kirjanpitaja'
      let tyyppi: TositeTyyppi = 'raahattu'
      if (sivu.l) {
        lahde = 'lemonaid-lasku'
        tyyppi = 'lasku'
      } else if (sivu.b) {
        lahde = 'holvi'
      } else if (sivu.a) {
        lahde = 'lemonaid-kuitti'
        tyyppi = 'kuitti'
      }
      existing = {
        avain: avain,
        tyyppi: tyyppi,
        lahde: lahde,
        sivut: [],
        jarjestys: sivu.o
      }
      if (liitoksenPoistoKesken?.find(a => a.avain === avain)) {
        existing.tallennetaan = true
      }
      if (lahde === 'lemonaid-kuitti') {
        existing.kuitinAvain = sivu.a
      }
      tositteetJaSivutMap.set(avain, existing)
    } else {
      if (sivu.o && existing.jarjestys > sivu.o) { existing.jarjestys = sivu.o }
    }

    await this._muutaVanhaSivuUusiksiSivuiksiJaLisaaListaan(existing.sivut, asiakas, olemassaOlevanAvain, sivu)
  }

  // private _annaLaskunEsikatselutiedotObervable(lasku: Lasku, kasiteltava: LaskuBase): Observable<LaskuPdfEsikatselutiedot> {
  //   // console.timeLog('laskulataus')
  //   if (kasiteltava.asetukset) {
  //     const esikatselutiedot: LaskuPdfEsikatselutiedot = {
  //       asetukset: kasiteltava.asetukset,
  //       juurilasku: lasku,
  //       kasiteltava: kasiteltava
  //     }
  //     return of(esikatselutiedot)
  //   }
  //   // console.timeLog('laskulataus')
  //   return null
  // }

  private _annaPdfnSivut(url: string): Promise<SivuPdfsta[]> {
    const startedAt = Date.now()
    // this._parseStartAt = startedAt
    if (url.startsWith('data:')) {
      return fetch(url).then(res => res.arrayBuffer()).then(arrayBuffer => {
        return this._handleArrayBuffer(arrayBuffer, startedAt)
      })
    }
    return firstValueFrom(this._http.get(url, { responseType: 'arraybuffer' })).then(arrayBuffer => {
      return this._handleArrayBuffer(arrayBuffer, startedAt)
    })
  }

  private _handleArrayBuffer(arrayBuffer: ArrayBuffer, startedAt: number): Promise<SivuPdfsta[]> {
    const uint8Array = new Uint8Array(arrayBuffer)
    return this._pdfService.yritaParsiaPdf(uint8Array).then(pdfDoc => {
      return this._pdfService.hoidaPdfnRajayttaminenSivuiksi(pdfDoc, 830)
    })
  }

  tositteenLisaaminenAloitettiin(kirjaus: Kirjaus, tosite: TositeJaNaytettavaSivut) {
    if (!kirjaus?.avain || !tosite?.avain) {
      return
    }
    tosite.tallennetaan = true
    const lista = this._tositteetJoidenLiittaminenOnKeskenMap.get(kirjaus.avain)
    if (!lista) {
      this._tositteetJoidenLiittaminenOnKeskenMap.set(kirjaus.avain, [tosite])
    } else {
      lista.push(tosite)
    }
    this._tositteenLiittaminenAloitettiinSubject.next(tosite)
  }

  tositteenLisaaminenEpaonnistui(kirjaus: Kirjaus, tosite: TositeJaNaytettavaSivut) {
    if (!kirjaus?.avain || !tosite?.avain) {
      return
    }
    const lista = this._tositteetJoidenLiittaminenOnKeskenMap.get(kirjaus.avain)
    if (lista) {
      const filtered = lista.filter(a => a.avain !== tosite.avain)
      if (filtered.length) {
        this._tositteetJoidenLiittaminenOnKeskenMap.set(kirjaus.avain, filtered)
      } else {
        this._tositteetJoidenLiittaminenOnKeskenMap.delete(kirjaus.avain)
      }
    }
    this._tositteenLiittaminenEpaonnistuiSubject.next(tosite)
  }

  tositteenLisaaminenOnnistui(kirjaus: Kirjaus, tosite: TositeJaNaytettavaSivut) {
    if (!kirjaus?.avain || !tosite?.avain) {
      return
    }
    const lista = this._tositteetJoidenLiittaminenOnKeskenMap.get(kirjaus.avain)
    if (lista) {
      const filtered = lista.filter(a => a.avain !== tosite.avain)
      if (filtered.length) {
        this._tositteetJoidenLiittaminenOnKeskenMap.set(kirjaus.avain, filtered)
      } else {
        this._tositteetJoidenLiittaminenOnKeskenMap.delete(kirjaus.avain)
      }
    }
    this._tositteenLiittaminenOnnistuiSubject.next(tosite)
  }

  annaTositteetJoidenLisaaminenOnKesken(kirjauksenAvain: string): TositeJaNaytettavaSivut[] {
    return this._tositteetJoidenLiittaminenOnKeskenMap.get(kirjauksenAvain)
  }

  async hoidaTositteenLaittaminenNakyviin(asiakas: AsiakkaanAvainTiedot, event: SivuJaAvain, naytettavaKirjaus: NaytettavaKirjaus): Promise<void> {
    const kirjaus: Kirjaus = naytettavaKirjaus?.kirjaus

    if (!asiakas || !kirjaus) {
      return
    }

    const poistettavatSivut: KirjaukseenLiitettyjenTiedostojenSivut = {
      p: null,
      t: {}
    }
    poistettavatSivut.t[event.sivunAvain] = event.sivu

    if (event.sivu.a) {
      const pyynto: KirjauksenKuitinTallennuspyynto = {
        asiakasAvain: asiakas.avain,
        kirjausAvain: kirjaus.avain,
        kuitinAvain: event.sivu.a
      }
      return this._firebase.functionsCall<KirjauksenKuitinTallennuspyynto, void>('kirjanpitoKirjauksetKuittiKopioi', pyynto).then(() => { })
    } else if (event.sivu.l) {
      const pyynto: KirjauksenLaskunTallennuspyynto = {
        asiakasAvain: asiakas.avain,
        kirjausAvain: kirjaus.avain,
        laskuAvain: event.sivu.l.avain
      }

      return this._firebase.functionsCall<KirjauksenLaskunTallennuspyynto, void>('kirjanpitoKirjauksetLaskuKopioi', pyynto).then(() => { })
    } else {
      const pyynto: KirjauksenRaahattavanTallennuspyynto = {
        asiakasAvain: asiakas.avain,
        kirjausAvain: kirjaus.avain,
        raahattavanAvain: event.sivunAvain
      }

      return this._firebase.functionsCall<KirjauksenRaahattavanTallennuspyynto, void>('kirjanpitoKirjauksetRaahattuKopioi', pyynto).then(() => { })
    }
  }

  async hoidaLiitoksenPoisto(asiakas: AsiakkaanAvainTiedot, tosite: TositeJaNaytettavaSivut, naytettavaKirjaus: NaytettavaKirjaus): Promise<void> {
    const kirjaus: Kirjaus = naytettavaKirjaus?.kirjaus
    if (!asiakas || !kirjaus || !tosite?.sivut?.length) {
      return
    }

    const poistettavatSivut: KirjaukseenLiitettyjenTiedostojenSivut = {
      p: null,
      t: {}
    }

    const sivu = tosite.sivut[0]
    poistettavatSivut.t[sivu.avain] = sivu.sivu

    if (sivu.sivu.a) {
      this.tositteenLiitoksenPoistoAloitettiin(kirjaus, tosite)
      const pyynto: KirjauksenKuitinTallennuspyynto = {
        asiakasAvain: asiakas.avain,
        kirjausAvain: kirjaus.avain,
        kuitinAvain: sivu.sivu.a
      }

      return this._firebase.functionsCall<KirjauksenKuitinTallennuspyynto, void>('kirjanpitoKirjauksetKuittiPoista', pyynto).then(() => {
        this.tositteenLiitoksenPoistoOnnistui(kirjaus, tosite)
      }).catch(err => {
        this.tositteenLiitoksenPoistoEpaonnistui(kirjaus, tosite)
        throw err
      })
    } else if (sivu.sivu.l) {
      this.tositteenLiitoksenPoistoAloitettiin(kirjaus, tosite)
      const pyynto: KirjauksenLaskunTallennuspyynto = {
        asiakasAvain: asiakas.avain,
        kirjausAvain: kirjaus.avain,
        laskuAvain: sivu.sivu.l.avain
      }

      return this._firebase.functionsCall<KirjauksenLaskunTallennuspyynto, void>('kirjanpitoKirjauksetLaskuPoista', pyynto).then(() => {
        this.tositteenLiitoksenPoistoOnnistui(kirjaus, tosite)
      }).catch(err => {
        this.tositteenLiitoksenPoistoEpaonnistui(kirjaus, tosite)
        throw err
      })
    } else {
      this.tositteenLiitoksenPoistoAloitettiin(kirjaus, tosite)
      const pyynto: KirjauksenLiitetiedostonPoistopyynto = {
        asiakasAvain: asiakas.avain,
        kirjausAvain: kirjaus.avain,
        kuvaAvain: sivu.avain
      }

      return this._firebase.functionsCall<KirjauksenLiitetiedostonPoistopyynto, void>('kirjanpitoKirjauksetLiitetiedostoPoista', pyynto).then(() => {
        this.tositteenLiitoksenPoistoOnnistui(kirjaus, tosite)
      }).catch(err => {
        this.tositteenLiitoksenPoistoEpaonnistui(kirjaus, tosite)
        throw err
      })
    }
  }

  tositteenLiitoksenPoistoAloitettiin(kirjaus: Kirjaus, tosite: TositeJaNaytettavaSivut) {
    if (!kirjaus?.avain || !tosite?.avain) {
      return
    }
    const lista = this._tositteetJoidenLiitoksenPoistoOnKeskenMap.get(kirjaus.avain)
    if (!lista) {
      this._tositteetJoidenLiitoksenPoistoOnKeskenMap.set(kirjaus.avain, [tosite])
    } else {
      lista.push(tosite)
    }
    this._tositteenLiitoksenPoistoAloitettiinSubject.next(tosite)
  }

  tositteenLiitoksenPoistoEpaonnistui(kirjaus: Kirjaus, tosite: TositeJaNaytettavaSivut) {
    if (!kirjaus?.avain || !tosite?.avain) {
      return
    }
    const lista = this._tositteetJoidenLiitoksenPoistoOnKeskenMap.get(kirjaus.avain)
    if (lista) {
      const filtered = lista.filter(a => a.avain !== tosite.avain)
      if (filtered.length) {
        this._tositteetJoidenLiitoksenPoistoOnKeskenMap.set(kirjaus.avain, filtered)
      } else {
        this._tositteetJoidenLiitoksenPoistoOnKeskenMap.delete(kirjaus.avain)
      }
    }
    this._tositteenLiitoksenPoistoEpaonnistuiSubject.next(tosite)
  }

  tositteenLiitoksenPoistoOnnistui(kirjaus: Kirjaus, tosite: TositeJaNaytettavaSivut) {
    if (!kirjaus?.avain || !tosite?.avain) {
      return
    }
    const lista = this._tositteetJoidenLiitoksenPoistoOnKeskenMap.get(kirjaus.avain)
    if (lista) {
      const filtered = lista.filter(a => a.avain !== tosite.avain)
      if (filtered.length) {
        this._tositteetJoidenLiitoksenPoistoOnKeskenMap.set(kirjaus.avain, filtered)
      } else {
        this._tositteetJoidenLiitoksenPoistoOnKeskenMap.delete(kirjaus.avain)
      }
    }
    this._tositteenLiitoksenPoistoOnnistuiSubject.next(tosite)
  }

  annaKaikkiTositteetJoidenPoistaminenOnKesken(): IterableIterator<TositeJaNaytettavaSivut[]> {
    return this._tositteetJoidenLiitoksenPoistoOnKeskenMap.values()
  }

  annaTositteetJoidenPoistaminenOnKesken(kirjauksenAvain: string): TositeJaNaytettavaSivut[] {
    return this._tositteetJoidenLiitoksenPoistoOnKeskenMap.get(kirjauksenAvain)
  }

  muutaRaahattavaTositteeksi(asiakas: AsiakkaanAvainTiedot, kirjattavaRaahattava: NaytettavaRaahattava): Promise<TositeJaNaytettavaSivut> {
    const liitetytSivut = this.muunnaKirjattavaRaahattavaLiitetyiksiSivuiksi(kirjattavaRaahattava)
    return this.muunnaTositteiksiJaSivuiksi(asiakas, liitetytSivut, null, null, false).then(kaikki => {
      if (kaikki.length) {
        return kaikki[0]
      }
      return null
    })
  }

  muutaKuittiTositteeksi(asiakas: AsiakkaanAvainTiedot, kirjattavaKuitti: KirjattavaKuitti): Promise<TositeJaNaytettavaSivut> {
    const liitetytSivut = this.muunnaKirjattavaKuittiLiitetyiksiSivuiksi(kirjattavaKuitti)
    return this.muunnaTositteiksiJaSivuiksi(asiakas, liitetytSivut, null, null, false).then(kaikki => {
      if (kaikki.length) {
        return kaikki[0]
      }
      return null
    })
  }

  muutaLaskuTositteeksi(asiakas: AsiakkaanAvainTiedot, kirjattavaLasku: KirjattavaLasku): Promise<TositeJaNaytettavaSivut> {
    const liitetytSivut = this.muunnaKirjattavaLaskuLiitetyiksiSivuiksi(kirjattavaLasku)
    return this.muunnaTositteiksiJaSivuiksi(asiakas, liitetytSivut, null, null, false).then(kaikki => {
      if (kaikki.length) {
        return kaikki[0]
      }
      return null
    })
  }

}

@Injectable()
export class KirjanpitoService {

  holviZipKasittelemattaCountObservable: Observable<number>
  holviZipDriverExistsObservable: Observable<boolean>

  tilitapahtumiaKasittelemattaCountObservable: Observable<number>
  holviTilitapahtumiaKasittelemattaCountObservable: Observable<number>
  holviApiKasittelemattaCountObservable: Observable<number>
  holviApiDriverExistsObservable: Observable<boolean>
  // alvIlmoituksetJoissaOnMuutoksiaObservable: Observable<{ ilmoitus: AlvIlmoitus, kirjaukset: Raportointikirjaus[] }[]>

  kuukausiruudutJoissaOnUudelleenLahettamistaTarvitsevia: Observable<KirjanpitoKuukausiruutu[]>

  kirjanpidonProjektitObservable: Observable<KirjanpidonProjekti[]>

  constructor(
    private _sanitizer: DomSanitizer,
    private _asiakasService: AsiakasService,
    private _asiakasUriService: AsiakasUriService,
    private _asiakasJaettuService: AsiakasJaettuService,
    private _tositeUriService: TositeUriService,
    private _httpService: LemonHttpService,
    private _firebase: FirebaseLemonator,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _dateService: DateService,
    private _currencyService: CurrencyService,
    private _kirjanpitoUriService: KirjanpitoUriService,
    private _timestampProviderBase: TimestampProviderBase,
    private _kirjanpitoJaettuService: KirjanpitoJaettuService,
    private _kopioijaPalvelu: KopioijaPalvelu,
    private _debugService: DebugService
  ) {

    this.kuukausiruudutJoissaOnUudelleenLahettamistaTarvitsevia = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (asiakas) {
          const kuukausiruutuCollectionUri = this._asiakasUriService.annaListausRuutuCollectionUri(asiakas.avain)
          return this._firebase.firestoreCollection<KirjanpitoKuukausiruutu>(kuukausiruutuCollectionUri).where('r', '==', true).listen()
        }
        return of<any[]>([])
      })
    )

    this.holviZipKasittelemattaCountObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (asiakas) {
          return this._firebase.firestoreCollection<any>('tyojono_data/' + asiakas.avain + '/kirjanpito_holvi_import').listenSnapshots().pipe(
            map(snapshots => snapshots?.length ?? 0)
          )
        }
        return of(0)
      }),
      startWith(0),
      lemonShare()
    )

    this.tilitapahtumiaKasittelemattaCountObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (asiakas) {
          return this._firebaseLemonaid.firestoreCollection<any>('tyojono_data/' + asiakas.avain + '/tilitapahtuman_tuonti').listenSnapshots().pipe(
            map(snapshots => snapshots?.length ?? 0)
          )
        }
        return of(0)
      }),
      startWith(0),
      lemonShare()
    )

    this.holviApiKasittelemattaCountObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (asiakas) {
          return this._firebase.firestoreCollection<any>('tyojono_data/' + asiakas.avain + '/holvi_api_import').listenSnapshots().pipe(
            map(snapshots => snapshots?.length ?? 0)
          )
        }
        return of(0)
      }),
      startWith(0),
      lemonShare()
    )

    this.holviApiDriverExistsObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (!asiakas) {
          return of(false)
        }
        return this._firebase.firestoreDoc<EraajotYksiKerrallaanSuorittajaData>('tyojono_ajurit/holvi_api_import-' + asiakas.avain).listen().pipe(
          map(entry => {
            return !!entry
          })
        )
      }),
      startWith(false),
      lemonShare()
    )

    this.holviZipDriverExistsObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (!asiakas) {
          return of(false)
        }
        const driverAvain = 'tyojono_ajurit/kirjanpito_holvi_import-' + asiakas.avain
        return this._firebase.firestoreDoc<EraajotYksiKerrallaanSuorittajaData>(driverAvain).listen().pipe(
          map(entry => {
            return !!entry
          })
        )
      }),
      startWith(false),
      lemonShare()
    )

    this.kirjanpidonProjektitObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (!asiakas) {
          return of<KirjanpidonProjekti[]>([])
        }
        return this._firebase.firestoreCollection<KirjanpidonProjekti>(this._kirjanpitoUriService.annaKirjanpitoProjektitCollection(asiakas.avain)).listen()
      }),
      map(projektit => {
        return projektit.sort((a, b) => { return (a.nimi || '').localeCompare((b.nimi || '')) })
      }),
      distinctUntilChanged((previous, current) => {
        if (!previous && !!current) {
          return false
        }
        if (!!previous && !current) {
          return false
        }
        if (!previous && !current) {
          return true
        }
        if (previous.length !== current.length) {
          return false
        }
        for (let i = 0; i < current.length; i++) {
          const old = previous[i]
          const nev = current[i]
          if (
            old.avain !== nev.avain ||
            old.color !== nev.color ||
            old.nimi !== nev.nimi ||
            old.usableInLemonator !== nev.usableInLemonator ||
            old.viewableInLemonaid !== nev.viewableInLemonaid ||
            old.viewableInLemonator !== nev.viewableInLemonator
          ) {
            return false
          }
        }
        return true
      }),
      lemonShare()
    )

    // const lukitsemattomatAlvIlmoituksetObservable: Observable<AlvIlmoitus[]> = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
    //   switchMap(asiakas => {
    //     if (asiakas) {
    //       const alvilmoituksetUri = this._kirjanpitoUriService.annaAlvIlmoituksenCollectionUri(asiakas.avain)
    //       return this._firebase.collection<AlvIlmoitus>(alvilmoituksetUri, ref => {
    //         return ref.where('lukittu', '==', false)
    //       }).valueChanges()
    //     }
    //     return of<AlvIlmoitus[]>([])
    //   })
    // )

    // const viimeisimmatLukitsemattomatAlvilmoituksetObservable: Observable<AlvIlmoitus[]> = lukitsemattomatAlvIlmoituksetObservable.pipe(
    //   map(ilmoitukset => {
    //     const m: Map<string, AlvIlmoitus> = new Map()
    //     if (ilmoitukset) {
    //       for (const ilmoitus of ilmoitukset) {
    //         const edellinen = m.get(ilmoitus.tunniste)
    //         if (edellinen) {
    //           if (edellinen.luotu.toMillis() < ilmoitus.luotu.toMillis()) {
    //             m.set(ilmoitus.tunniste, ilmoitus)
    //           }
    //         } else {
    //           m.set(ilmoitus.tunniste, ilmoitus)
    //         }
    //       }
    //     }
    //     return Array.from(m.values())
    //   })
    // )

    // const lukitsemattomatRaportointikirjauksetObservable: Observable<Raportointikirjaus[]> = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
    //   switchMap(asiakas => {
    //     if (asiakas) {
    //       const raporttikirjaukseUri = this._kirjanpitoUriService.annaRaporttikirjausCollectionUri(asiakas.avain)
    //       return this._firebase.collection<Raportointikirjaus>(raporttikirjaukseUri, ref => {
    //         return ref.where('l', '==', false)
    //       }).valueChanges()
    //     }
    //     return of<Raportointikirjaus[]>([])
    //   })
    // )

    // const alvJaksotJaKirjauksetObservable: Observable<{ ilmoitus: AlvIlmoitus, kirjaukset: Raportointikirjaus[] }[]> = combineLatest(viimeisimmatLukitsemattomatAlvilmoituksetObservable, lukitsemattomatRaportointikirjauksetObservable).pipe(
    //   map(([ilmoitukset, kirjaukset]) => {
    //     const tulokset: { ilmoitus: AlvIlmoitus, kirjaukset: Raportointikirjaus[] }[] = []
    //     if (ilmoitukset && kirjaukset) {
    //       for (const ilmoitus of ilmoitukset) {

    //         const ilmoitusJaKirjaukset: { ilmoitus: AlvIlmoitus, kirjaukset: Raportointikirjaus[] } = {
    //           ilmoitus: ilmoitus,
    //           kirjaukset: []
    //         }

    //         const alkamisPvmAsDate = this._dateService.kuukaudenEnsimmainen(this._dateService.localDateToDate(ilmoitus.startDate))
    //         const alkamisPvmAsTimestamp = this._timestampProviderBase.dateToTimestamp(alkamisPvmAsDate)
    //         const alkamisMillis = alkamisPvmAsTimestamp.toMillis()

    //         const loppumisPvmAsDate = this._dateService.kuukaudenViimeinen(this._dateService.localDateToDate(ilmoitus.endDate))
    //         const loppumisPvmAsTimestamp = this._timestampProviderBase.dateToTimestamp(loppumisPvmAsDate)
    //         const loppumisMillis = loppumisPvmAsTimestamp.toMillis()

    //         for (const kirjaus of kirjaukset) {
    //           const kirjausMillis = kirjaus.p.toMillis()
    //           if (alkamisMillis <= kirjausMillis && kirjausMillis <= loppumisMillis) {
    //             ilmoitusJaKirjaukset.kirjaukset.push(kirjaus)
    //           }
    //         }
    //         tulokset.push(ilmoitusJaKirjaukset)
    //       }
    //     }
    //     return tulokset
    //   })
    // )

    // this.alvIlmoituksetJoissaOnMuutoksiaObservable = alvJaksotJaKirjauksetObservable.pipe(
    //   map(ilmoituksetJaKirjaukset => {
    //     return ilmoituksetJaKirjaukset.filter(ilmoitusJaKirjaukset => {
    //       const uusi = this._kirjanpitoJaettuService.annaUusiAlvilmoitus()
    //       const summat = this._kirjanpitoJaettuService.laskeAlvIlmoituksenSummatKirjauksista(ilmoitusJaKirjaukset.kirjaukset)
    //       this._kirjanpitoJaettuService.copyValuesFromSumsToAlvIlmoitus(uusi, summat)
    //       return this._kirjanpitoJaettuService.onkoAlvIlmoituksissaEroja(ilmoitusJaKirjaukset.ilmoitus, uusi)
    //     })
    //   }),
    //   // tap(muutoksia => {
    //   // for (const muutos of muutoksia) {
    //   // console.log('muutoksia ->', muutos.ilmoitus.tunniste, muutos.ilmoitus.jarjestysnumero)
    //   // console.log('kirjauksia', muutos.kirjaukset.length)
    //   // }
    //   // }),
    //   lemonShare()
    // )

  }

  muunnaAlvIlmoitusListausAlvIlmoitukseksi(populateDebugUrls: boolean, asiakas: AsiakkaanAvainTiedot, alvIlmoitus: AlvIlmoitus, kirjanpitajienNimitiedotMap: Map<string, KirjanpitajanNimitiedot>): ListausAlvIlmoitus {
    const asListaus = this._kopioijaPalvelu.cloneObjectDeep(alvIlmoitus) as ListausAlvIlmoitus
    asListaus.maksettavaTaiPalautettavaVero = this._kirjanpitoJaettuService.annaMaksettavaTaiPalautettavaVero(alvIlmoitus)
    asListaus.tunnistetiedotString = this._kirjanpitoJaettuService.annaTunnistetiedotString(alvIlmoitus.tunnistetiedot, 'fi')

    const nimitiedot = kirjanpitajienNimitiedotMap.get(alvIlmoitus.tallentaja)
    const date = this._dateService.timestampToDate(alvIlmoitus.tallennettu ? alvIlmoitus.tallennettu : alvIlmoitus.luotu)
    asListaus.tallennustiedot = (nimitiedot ? (nimitiedot.etunimi + ' ' + nimitiedot.sukunimi) : 'Tuntematon') + ' ' + this._dateService.muotoilePaivaJaAikaDate(date, 'fi')

    if (populateDebugUrls) {
      asListaus.uri = this._kirjanpitoUriService.annaAlvIlmoituksenUri(asiakas.avain, alvIlmoitus.avain)
      asListaus.url = this._debugService.createFirestoreLink(asListaus.uri)
    } else {
      asListaus.uri = null
      asListaus.url = null
    }

    return asListaus
  }

  getColorNameAndClassMap() {
    return {
      pun: 'red-text',
      sin: 'blue-text',
      vsi: 'light-blue-text',
      kel: 'yellow-text',
      vih: 'green-text',
      vio: 'purple-text',
      har: 'light-gray-text'
    }
  }

}
