<div class="valkoinen blue-background" id="valikko">
  <div>
    <mat-nav-list style="padding: 15px;">
      <mat-list-item (click)="clickKotiin()">
        <div style="width: 100%; text-align: center; font-size: 30px; line-height: 37px;">
          <i matListItemIcon class="icon ion-ios-heart-empty" style="width: 30px; height: 30px; font-size: 30px; padding: 8px;"></i>Koti
        </div>
      </mat-list-item>
    </mat-nav-list>
    <!-- <button mat-button (click)="suljeVasenValikkoOma($event)" style="position: absolute; top: 0; left: 0; padding: 0; margin: 0; min-width: 40px;">
      <i style="font-size: 40px; width: 40px; height: 40px; line-height: 40px; display: inline-block;" class="icon ion-ios-close"></i>
    </button> -->
    <div style="margin-left: 6%; margin-right: 6%;">
      <div *ngIf="tyojonolatauksetKeskenObservable | async else separator" [matTooltip]="'Katso tarkemmat tiedot tiedot napauttamalla'" (click)="navigateToTyojonoLatausDetails()" style="display: flex; align-content: center; justify-content: center; flex-wrap: wrap; cursor: pointer;">
        <mat-progress-bar mode="indeterminate" style="cursor: pointer; width: 100%;" color="warn"></mat-progress-bar>
        <div style="flex-basis: 100%; height: 0; cursor: pointer;"></div>
        <div style="cursor: pointer; padding-top: 5px;">Latauksia kesken...</div>
      </div>
      <ng-template #separator>
        <div style="height: 2px; background-color: white; opacity: .2;"></div>
      </ng-template>
    </div>

    <div *ngIf="url?.startsWith('/yllapito')">
      <mat-nav-list>
        <mat-list-item routerLink="/yllapito/integraatiot-verohallinto" [class.selectedmenu]="url?.startsWith('/yllapito/integraatiot-verohallinto')">
          <i matListItemIcon class="icon ion-ios-globe"></i> Integraatiot (Verohallinto)
        </mat-list-item>
        <mat-list-item routerLink="/yllapito/integraatiot-holvi" [class.selectedmenu]="url?.startsWith('/yllapito/integraatiot-holvi')">
          <i matListItemIcon class="icon ion-ios-globe"></i> Integraatiot (Holvi)
        </mat-list-item>
        <mat-list-item routerLink="/yllapito/postmarkapp/dmarc" [class.selectedmenu]="url?.startsWith('/yllapito/postmarkapp/dmarc')">
          <i matListItemIcon class="icon ion-ios-mail"></i> Postmarkapp DMARC
        </mat-list-item>
        <mat-list-item routerLink="/yllapito/kirjanpitajat" [class.selectedmenu]="url?.startsWith('/yllapito/kirjanpitajat')">
          <i matListItemIcon class="icon ion-ios-person"></i> Kirjanpitäjät
        </mat-list-item>
        <mat-list-item routerLink="/yllapito/raportit" [class.selectedmenu]="url?.startsWith('/yllapito/raportit')">
          <i matListItemIcon class="icon ion-ios-clipboard"></i> Raportit
        </mat-list-item>
        <!-- <mat-list-item routerLink="/yllapito/kayttoehdot" [class.selectedmenu]="url?.startsWith('/yllapito/kayttoehdot')">
          <i matListItemIcon class="icon ion-ios-list-box"></i> Käyttöehdot
        </mat-list-item> -->
        <mat-list-item routerLink="/yllapito/paatilikartta" [class.selectedmenu]="url?.startsWith('/yllapito/paatilikartta')">
          <i matListItemIcon class="fa fa-bars"></i> Päätilikartta
        </mat-list-item>
        <mat-list-item routerLink="/yllapito/sopimukset" [class.selectedmenu]="url?.startsWith('/yllapito/sopimukset')">
          <i matListItemIcon class="icon ion-ios-contract"></i> Sopimukset
        </mat-list-item>
        <mat-list-item routerLink="/yllapito/yhteisomyyntilaskut" [class.selectedmenu]="url?.startsWith('/yllapito/yhteisomyyntilaskut')">
          <i matListItemIcon class="icon ion-ios-download"></i> Yhteisömyyntilaskut
        </mat-list-item>
        <mat-list-item routerLink="/yllapito/massamuikkarit" [class.selectedmenu]="url?.startsWith('/yllapito/massamuikkarit')">
          <i matListItemIcon class="icon ion-ios-mail"></i> Maksumuistutuksien massalähetys
        </mat-list-item>
        <mat-list-item routerLink="/yllapito/pankkiyhteydet" [class.selectedmenu]="url?.startsWith('/yllapito/pankkiyhteydet')">
          <i matListItemIcon class="icon ion-ios-card"></i> Pankkiyhteydet
        </mat-list-item>
        <mat-list-item routerLink="/yllapito/ajastetut-tyot" [class.selectedmenu]="url?.startsWith('/yllapito/ajastetut-tyot')">
          <i matListItemIcon class="icon ion-ios-alarm"></i> Ajastetut työt
        </mat-list-item>
        <!-- <mat-list-item routerLink="/yllapito/sahkopostipohjat" [class.selectedmenu]="url?.startsWith('/yllapito/sahkopostipohjat')">
          <i matListItemIcon class="icon ion-ios-mail"></i> Sähköpostipohjat
        </mat-list-item> -->

        <!-- <mat-list-item (click)="setupCookies()" style="text-align: center;">
          <i matListItemIcon class="fa fa-birthday-cake"></i>Prod cookies
        </mat-list-item>
        <mat-list-item (click)="setupCookiesDev()" style="text-align: center;">
          <i matListItemIcon class="fa fa-birthday-cake"></i>Dev cookies
        </mat-list-item> -->
      </mat-nav-list>
    </div>
    <div *ngIf="!url?.startsWith('/yllapito')">
      <div style="display: flex; align-content: center; justify-content: center; margin-bottom: 10px; margin-top: 10px; flex-wrap: wrap;">
        <div style="font-size: 16px; line-height: 20px; font-weight: 100; padding: 25px 10px;">
          <div *ngIf="!nykyinenAsiakas || !nykyinenAsiakas.avain">
            <div>Asiakasta ei valittu</div>
          </div>
          <div *ngIf="nykyinenAsiakas && nykyinenAsiakas.avain">
            <div>{{nykyinenAsiakas?.nimi}}</div>
          </div>
        </div>
      </div>
      <div *ngIf="nykyinenAsiakas && nykyinenAsiakas.avain && nykyinenAsiakas.avain != 'uusi'">
        <div style="display: flex; align-content: center; justify-content: center; margin-top: 10px; margin-bottom: 15px; flex-wrap: wrap;">
          <button (click)="vaihdaAlavalikko('kirjanpito')" class="isonappi" [class.selectedmenu]="valittuAlavalikko === 'kirjanpito'" mat-button style="margin: 5px; padding: 5px; border-radius: 10px; font-weight: 100; font-size: 16px; height: 85px; color: white;">
            <i class="fa fa-table" style="font-size: 43px; height: 37px; display: inline-block; line-height: 37px; padding-bottom: 10px;"></i>
            <br>
            Kirjanpito
          </button>
          <button (click)="vaihdaAlavalikko('lemonaid')" class="isonappi" [class.selectedmenu]="valittuAlavalikko === 'lemonaid'" mat-button style="margin: 5px; padding: 5px; border-radius: 10px; font-weight: 100; font-size: 16px; font-size: 16px; height: 85px; color: white;">
            <i class="fa fa-lemon-o" style="font-size: 43px; height: 37px; display: inline-block; line-height: 37px; padding-bottom: 10px;"></i>
            <br>
            Lemonaid
          </button>
          <button (click)="vaihdaAlavalikko('asetukset')" class="isonappi" [class.selectedmenu]="valittuAlavalikko === 'asetukset'" mat-button style="margin: 5px; padding: 5px; border-radius: 10px; font-weight: 100; font-size: 16px; font-size: 16px; height: 85px; color: white;">
            <i class="icon ion-ios-cog" style="font-size: 48px; height: 37px; display: inline-block; line-height: 37px; right: -3px; padding-bottom: 10px;"></i>
            <br>
            Asetukset
          </button>
          <button *ngIf="naytaSuper" (click)="vaihdaAlavalikko('yllapito')" class="isonappi" [class.selectedmenu]="valittuAlavalikko === 'yllapito'" mat-button style="margin: 5px; padding: 5px; border-radius: 10px; font-weight: 100; font-size: 16px; font-size: 16px; height: 85px; color: white;">
            <i class="icon ion-ios-hammer" style="font-size: 48px; height: 37px; display: inline-block; line-height: 37px; right: -3px; padding-bottom: 10px;"></i>
            <br>
            Ylläpito
          </button>
        </div>
        <mat-nav-list *ngIf="valittuAlavalikko === 'yllapito'" style="text-align: left;">
          <mat-list-item *ngIf="naytaSuper" [routerLink]="'/asiakkaat/' + nykyinenAsiakas.avain + '/pankkiyhteydet'" [class.selectedmenu]="url === '/asiakkaat/' + nykyinenAsiakas.avain + '/pankkiyhteydet'">
            <i matListItemIcon class="icon ion-ios-lock"></i> Pankkiyhteydet
          </mat-list-item>
          <mat-list-item *ngIf="naytaSuper" [routerLink]="'/asiakkaat/' + nykyinenAsiakas.avain + '/tilitapahtumat'" [class.selectedmenu]="url === '/asiakkaat/' + nykyinenAsiakas.avain + '/tilitapahtumat'">
            <i matListItemIcon class="icon ion-ios-wallet"></i>
            <div style="display: flex; align-items: center;"> Tilitapahtumat</div>
          </mat-list-item>
          <mat-list-item *ngIf="naytaSuper" [routerLink]="'/asiakkaat/' + nykyinenAsiakas.avain + '/paivasaldot'" [class.selectedmenu]="url?.startsWith('/asiakkaat/' + nykyinenAsiakas.avain + '/paivasaldot')">
            <i matListItemIcon class="icon ion-ios-cash"></i> Päiväsaldot
          </mat-list-item>
          <mat-list-item *ngIf="naytaSuper" [routerLink]="'/asiakkaat/' + nykyinenAsiakas.avain + '/tilitapahtumat/kohdistusdebug'" [class.selectedmenu]="url === '/asiakkaat/' + nykyinenAsiakas.avain + '/tilitapahtumat/kohdistusdebug'">
            <i matListItemIcon class="icon ion-ios-wallet"></i> Reskontra-debug
          </mat-list-item>
          <mat-list-item *ngIf="naytaSuper" [routerLink]="'/asiakkaat/' + nykyinenAsiakas.avain + '/yllapito/apixlaskut'" [class.selectedmenu]="url?.startsWith('/asiakkaat/' + nykyinenAsiakas.avain + '/yllapito/apixlaskut')">
            <i matListItemIcon class="icon ion-logo-xbox"></i> Apix laskut
          </mat-list-item>
          <mat-list-item *ngIf="naytaSuper" [routerLink]="'/asiakkaat/' + nykyinenAsiakas.avain + '/lahetykset'" [class.selectedmenu]="url?.startsWith('/asiakkaat/' + nykyinenAsiakas.avain + '/yllapito/lahetykset')">
            <i matListItemIcon class="icon ion-ios-mail"></i> Kirjanpidon lähetykset
          </mat-list-item>
          <mat-list-item [routerLink]="'/asiakkaat/' + nykyinenAsiakas.avain + '/veronmaksu'" [class.selectedmenu]="url?.startsWith('/asiakkaat/' + nykyinenAsiakas.avain + '/veronmaksu')">
            <i matListItemIcon class="icon ion-ios-cash"></i> Veron maksu
          </mat-list-item>
          <!-- <mat-list-item *ngIf="naytaSuper" [routerLink]="'/asiakkaat/' + nykyinenAsiakas.avain + '/ytj-tiedot'" [class.selectedmenu]="url === '/asiakkaat/' + nykyinenAsiakas.avain + '/ytj-tiedot'">
            <i matListItemIcon class="icon ion-ios-business"></i> YTJ tiedot
          </mat-list-item> -->
          <mat-list-item *ngIf="naytaSuper" [routerLink]="'/asiakkaat/' + nykyinenAsiakas.avain + '/alusta-kirjanpito'" [class.selectedmenu]="url?.startsWith('/asiakkaat/' + nykyinenAsiakas.avain + '/alusta-kirjanpito')">
            <i matListItemIcon class="icon ion-ios-rocket"></i> Alusta kirjanpito
          </mat-list-item>
          <mat-list-item *ngIf="naytaSuper" [routerLink]="'/asiakkaat/' + nykyinenAsiakas.avain + '/yhteisomyynti-ilmoitukset'" [class.selectedmenu]="url?.startsWith('/asiakkaat/' + nykyinenAsiakas.avain + '/yhteisomyynti-ilmoitukset')">
            <i matListItemIcon class="icon ion-ios-mail"></i> Yhteisömyynti-ilmoitukset
          </mat-list-item>
        </mat-nav-list>
        <!-- <mat-nav-list *ngIf="valittuAlavalikko === 'kirjanpito'">
          <mat-list-item [routerLink]="'/asiakkaat/' + nykyinenAsiakas.avain + '/kirjanpito'" [class.selectedmenu]="url?.startsWith('/asiakkaat/' + nykyinenAsiakas.avain + '/kirjanpito')">
            <i matListItemIcon class="fa fa-table"></i> Kirjanpito
          </mat-list-item>
          <mat-list-item [routerLink]="'/asiakkaat/' + nykyinenAsiakas.avain + '/tilikartta'" [class.selectedmenu]="url?.startsWith('/asiakkaat/' + nykyinenAsiakas.avain + '/tilikartta')">
            <i matListItemIcon class="fa fa-bars"></i> Tilikartta
          </mat-list-item>
          <mat-list-item [routerLink]="'/asiakkaat/' + nykyinenAsiakas.avain + '/kirjanpito/aineiston-lataus'" [class.selectedmenu]="url?.startsWith('/asiakkaat/' + nykyinenAsiakas.avain + '/kirjanpito/aineiston-lataus')">
            <i matListItemIcon class="fa fa-cloud-download"></i> Aineiston lataus
          </mat-list-item>
          <mat-list-item [routerLink]="'/asiakkaat/' + nykyinenAsiakas.avain + '/holvi-import'" [class.selectedmenu]="url?.startsWith('/asiakkaat/' + nykyinenAsiakas.avain + '/holvi-import')">
            <i matListItemIcon class="fa fa-cloud-upload"></i> Holvi-import <mat-spinner *ngIf="holviKasittelemattomiaLatauksiaObservable | async" [diameter]="30" color="warn" style="margin-left: 20px;"></mat-spinner>
          </mat-list-item>
        </mat-nav-list> -->
        <mat-nav-list *ngIf="valittuAlavalikko === 'lemonaid'">
          <mat-list-item [routerLink]="'/asiakkaat/' + nykyinenAsiakas.avain + '/tositteet/lataa'" [class.selectedmenu]="url?.startsWith('/asiakkaat/' + nykyinenAsiakas.avain + '/tositteet/lataa')">
            <i matListItemIcon class="icon ion-ios-cloud-download"></i> Lataa tositteita
          </mat-list-item>
          <mat-list-item [routerLink]="'/asiakkaat/' + nykyinenAsiakas.avain + '/tositteet/selaa'" [class.selectedmenu]="url?.startsWith('/asiakkaat/' + nykyinenAsiakas.avain + '/tositteet/selaa')">
            <i matListItemIcon class="icon ion-ios-images"></i> Selaa tositteita
          </mat-list-item>
          <mat-list-item [routerLink]="'/asiakkaat/' + nykyinenAsiakas.avain + '/laskut/selaa'" [class.selectedmenu]="url?.startsWith('/asiakkaat/' + nykyinenAsiakas.avain + '/laskut/selaa')">
            <i matListItemIcon class="icon ion-ios-paper"></i> Selaa laskuja
          </mat-list-item>
          <mat-list-item [routerLink]="'/asiakkaat/' + nykyinenAsiakas.avain + '/kayttajat'" [class.selectedmenu]="url?.startsWith('/asiakkaat/' + nykyinenAsiakas.avain + '/kayttajat')">
            <i matListItemIcon class="icon ion-ios-contacts"></i> Käyttäjät
          </mat-list-item>
          <mat-list-item [routerLink]="'/asiakkaat/' + nykyinenAsiakas.avain + '/maksutavat'" [class.selectedmenu]="url?.startsWith('/asiakkaat/' + nykyinenAsiakas.avain + '/maksutavat')">
            <i matListItemIcon class="icon ion-ios-card"></i> Maksutavat
          </mat-list-item>
          <mat-list-item [routerLink]="'/asiakkaat/' + nykyinenAsiakas.avain + '/tositteiden-arkisto'" [class.selectedmenu]="url?.startsWith('/asiakkaat/' + nykyinenAsiakas.avain + '/tositteiden-arkisto')">
            <i matListItemIcon class="icon ion-ios-folder-open"></i> Arkisto
          </mat-list-item>
          <mat-list-item *ngIf="nykyinenAsiakas.yritysmuoto == 't'" [routerLink]="'/asiakkaat/' + nykyinenAsiakas.avain + '/kirjanpidon-ulkopuoliset-vahennykset'" [class.selectedmenu]="url?.startsWith('/asiakkaat/' + nykyinenAsiakas.avain + '/kirjanpidon-ulkopuoliset-vahennykset')" style="text-align: left;">
            <i matListItemIcon class="icon ion-ios-car"></i> Kirjanpidon ulkopuoliset vähennykset
          </mat-list-item>
          <mat-list-item *ngIf="naytaSuper" [routerLink]="'/asiakkaat/' + nykyinenAsiakas.avain + '/laskuasetukset'" [class.selectedmenu]="url?.startsWith('/asiakkaat/' + nykyinenAsiakas.avain + '/laskuasetukset')">
            <i matListItemIcon class="icon ion-ios-cog"></i> Laskuasetukset
          </mat-list-item>
        </mat-nav-list>
        <mat-nav-list *ngIf="valittuAlavalikko === 'asetukset'">
          <mat-list-item [routerLink]="'/asiakkaat/' + nykyinenAsiakas.avain + '/asiakastiedot'" [class.selectedmenu]="url === '/asiakkaat/' + nykyinenAsiakas.avain + '/asiakastiedot'">
            <i matListItemIcon class="icon ion-ios-paper"></i> Asiakastiedot
          </mat-list-item>
          <mat-list-item [routerLink]="'/asiakkaat/' + nykyinenAsiakas.avain + '/tuntemistiedot'" [class.selectedmenu]="url === '/asiakkaat/' + nykyinenAsiakas.avain + '/tuntemistiedot'">
            <i matListItemIcon class="icon ion-ios-people"></i> Tuntemistiedot
          </mat-list-item>
          <mat-list-item [routerLink]="'/asiakkaat/' + nykyinenAsiakas.avain + '/kirjanpitotiedot'" [class.selectedmenu]="url === '/asiakkaat/' + nykyinenAsiakas.avain + '/kirjanpitotiedot'">
            <i matListItemIcon class="icon ion-ios-create"></i> Kirjanpito
          </mat-list-item>
          <mat-list-item [routerLink]="'/asiakkaat/' + nykyinenAsiakas.avain + '/aineiston-lataus'" [class.selectedmenu]="url?.startsWith('/asiakkaat/' + nykyinenAsiakas.avain + '/aineiston-lataus')">
            <i matListItemIcon class="fa fa-cloud-download"></i> Aineiston lataus
          </mat-list-item>
          <mat-list-item [routerLink]="'/asiakkaat/' + nykyinenAsiakas.avain + '/laskutus'" [class.selectedmenu]="url === '/asiakkaat/' + nykyinenAsiakas.avain + '/laskutus'">
            <i matListItemIcon class="icon ion-ios-cash"></i> Laskutus
          </mat-list-item>
          <mat-list-item [routerLink]="'/asiakkaat/' + nykyinenAsiakas.avain + '/sopimukset'" [class.selectedmenu]="url?.startsWith('/asiakkaat/' + nykyinenAsiakas.avain + '/sopimukset')">
            <i matListItemIcon class="fa fa-handshake-o"></i> Sopimukset
          </mat-list-item>
          <mat-list-item [routerLink]="'/asiakkaat/' + nykyinenAsiakas.avain + '/holvi-import'" [class.selectedmenu]="url?.startsWith('/asiakkaat/' + nykyinenAsiakas.avain + '/holvi-import')">
            <i matListItemIcon class="fa fa-gear"></i>
            <div style="display: flex; align-items: center;">Holvi</div>
          </mat-list-item>
          <mat-list-item [routerLink]="'/asiakkaat/' + nykyinenAsiakas.avain + '/ajastetut-tyot'" [class.selectedmenu]="url?.startsWith('/asiakkaat/' + nykyinenAsiakas.avain + '/ajastetut-tyot')">
            <i matListItemIcon class="icon ion-ios-alarm"></i> Ajastetut työt
          </mat-list-item>
          <mat-list-item [routerLink]="'/asiakkaat/' + nykyinenAsiakas.avain + '/lataus-tyot'" [class.selectedmenu]="url?.startsWith('/asiakkaat/' + nykyinenAsiakas.avain + '/lataus-tyot')">
            <i matListItemIcon class="icon ion-ios-hourglass"></i> Keskeneräiset työt
          </mat-list-item>
        </mat-nav-list>
      </div>
      <div *ngIf="url == '/jaettavat-asiakkaat' || url == '/asiakkaat' || url == '/asiakkaat/haku' || url == '/asiakkaat/uusi-lista' || url == '/' || url == '' || url == '/asiakkaat/uusi' || url == '/tilastot' || url == '/zen' || url?.startsWith('/tyoajanseuranta') || url?.startsWith('/kirjanpitajan-asetukset')">
        <mat-nav-list *ngIf="valittuAlavalikko == 'koti'">
          <mat-list-item *ngIf="naytaLisaaAsiakas" [routerLink]="'/asiakkaat/uusi'">
            <mat-icon matListItemIcon>add</mat-icon> Lisää asiakas
          </mat-list-item>
          <mat-list-item *ngIf="naytaSuper" [routerLink]="'/asiakkaat/haku'" [class.selectedmenu]="url == '/asiakkaat/haku'">
            <i matListItemIcon class="icon ion-ios-search"></i> Asiakashaku
          </mat-list-item>
          <mat-list-item *ngIf="naytaSuper || naytaSales" [routerLink]="'/jaettavat-asiakkaat'" [class.selectedmenu]="url == '/jaettavat-asiakkaat'">
            <i matListItemIcon class="icon ion-ios-cash"></i> Jaettavat asiakkaat
          </mat-list-item>
          <mat-list-item [routerLink]="'/asiakkaat'" [class.selectedmenu]="url == '/asiakkaat'">
            <i matListItemIcon class=" icon ion-ios-cloud-download"></i> Työlista
          </mat-list-item>
          <mat-list-item [routerLink]="'/asiakkaat/uusi-lista'" [class.selectedmenu]="url == '/asiakkaat/uusi-lista' || url == '/' || url == ''">
            <i matListItemIcon class=" icon ion-ios-cloud-download"></i> Uusi työlista
          </mat-list-item>
          <mat-list-item [routerLink]="'/tilastot'" [class.selectedmenu]="url?.startsWith('/tilastot')">
            <i matListItemIcon class="fa fa-bar-chart"></i> Tilastot
          </mat-list-item>
          <mat-list-item *ngIf="naytaHolviObservable | async" [routerLink]="'/zen'" [class.selectedmenu]="url?.startsWith('/zen')">
            <img matListItemIcon src="assets/meditation-yoga.svg" style="height: 26px;" /> Zen
          </mat-list-item>
          <!-- <mat-list-item *ngIf="naytaTyoajanseurantaObservable | async" [routerLink]="tyoajanseurantaLinkObservable | async" [class.selectedmenu]="url?.startsWith('/tyoajanseuranta')">
            <i matListItemIcon class="fa fa-clock-o"></i> Työajanseuranta
          </mat-list-item> -->
          <mat-list-item [routerLink]="'/kirjanpitajan-asetukset'" [class.selectedmenu]="url?.startsWith('/kirjanpitajan-asetukset')">
            <i matListItemIcon class="fa fa-address-card"></i> Asetukset
          </mat-list-item>
        </mat-nav-list>
      </div>
      <!-- <div *ngIf="url == '/asiakkaat' || url == '/' || url == '' || url == '/asiakkaat/uusi' || url == '/tilastot' || url == '/zen' || url?.startsWith('/tyoajanseuranta')">
        <ng-container *ngIf="onkoKirjaamattomiaTuntejaObservable | async">
          <mat-nav-list>
            <mat-list-item [routerLink]="tyoajanseurantaLinkObservable | async" style="margin-top: 10%; margin-bottom: 10%;">
              <span style="color: #f44336; font-weight: 500; padding-left: 5px;">Kirjaamattomia tunteja!</span>
            </mat-list-item>
          </mat-nav-list>
        </ng-container>
      </div> -->
      <form *ngIf="naytaSuper && valittuAlavalikko === 'koti' && (url == '/asiakkaat' || url == '/asiakkaat/uusi-lista' || url == '/')" [formGroup]="form" novalidate style="margin: 1em 0 0 0;">
        <mat-form-field class="valkoinen" style="width: 200px;">
          <mat-label>Rajaa kirjanpitäjän mukaan</mat-label>
          <mat-select class="valkoinen" formControlName="kirjanpitaja">
            <!-- <mat-option [value]="null">
              Kaikki
            </mat-option> -->
            <mat-option *ngFor="let kirjanpitaja of kirjanpitajienLista" [value]="kirjanpitaja.avain">
              {{kirjanpitaja.etunimi}} {{ kirjanpitaja.sukunimi }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- <mat-form-field style="width: 200px;">
          <input type="text" [matDatepicker]="picker2" formControlName="aikakone" placeholder="Aikakone" matInput required data-lpignore="true" />
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        </mat-form-field> -->
        <!-- <mat-datepicker #picker2></mat-datepicker> -->
      </form>
    </div>
  </div>
  <div>

    <mat-nav-list>
      <mat-list-item (click)="logout()" style="text-align: center;">
        <!-- <i matListItemIcon class="icon ion-ios-log-out" style="transform: rotate(180deg);"></i> -->
        <div style="text-align: center; width: 100%;">{{'app.kirjaudu-ulos' | translate | async}}</div>
      </mat-list-item>
    </mat-nav-list>
    <div style="margin-left: 6%; margin-right: 6%; height: 2px; background-color: white; opacity: .2;"></div>
    <div style="line-height: 54px;">
      {{ (kirjanpitajaObservable | async)?.etunimi }}&nbsp;{{ (kirjanpitajaObservable | async)?.sukunimi }}
    </div>

    <!-- <div style="margin-left: 6%; margin-right: 6%; height: 2px; background-color: white; opacity: .2;"></div> -->
    <button mat-button>
      <img src="/assets/lemonaid_logo_pieni.png" style="width: 65%;" />
    </button>
    <button mat-button (click)="avaaVersiohistoria()">
      <span style="font-size: 12px; color: white;">Lemonator {{ version }}</span>
    </button>

    <div *ngIf="naytaSuper">
      <div style="margin-left: 6%; margin-right: 6%; height: 2px; background-color: white; opacity: .2;"></div>
      <div style="/* font-weight: bold; */ font-size: 16px; line-height: 22px; margin: 15px 0;">
        Lähettämättömät<br />
        ALV-ilmot:
        <span *ngIf="lahettamattomatAlvIlmoituksetMaaraObservable | async; let maara" style="font-weight: bold;" [class.red-text]="maara.maara > 0" [class.green-text]="1 > maara.maara">{{ maara.maara }}</span><br />
        Veroilmot:
        <span *ngIf="lahettamattomatVeroIlmoituksetMaaraObservable | async; let maara" style="font-weight: bold;" [class.red-text]="maara.maara > 0" [class.green-text]="1 > maara.maara">{{ maara.maara }}</span><br />
        PRH rekisteröinnit:
        <span *ngIf="lahettamattomatPrhRekisteroinnitMaaraObservable | async; let maara" style="font-weight: bold;" [class.red-text]="maara.maara > 0" [class.green-text]="1 > maara.maara">{{ maara.maara }}</span><br />
      </div>
      <div style="margin-left: 6%; margin-right: 6%; height: 2px; background-color: white; opacity: .2;"></div>
      <mat-nav-list style="padding: 0;">
        <mat-list-item *ngIf="!url?.startsWith('/yllapito')" (click)="avaaYllapito()" [class.selectedmenu]="url?.startsWith('/yllapito')">
          <div style="text-align: center; width: 100%;">Ylläpito</div>
        </mat-list-item>
        <mat-list-item *ngIf="url?.startsWith('/yllapito')" [class.selectedmenu]="url?.startsWith('/yllapito')" routerLink="/asiakkaat">
          <div style="text-align: center; width: 100%;">Lopeta ylläpito</div>
        </mat-list-item>
      </mat-nav-list>
    </div>

  </div>
</div>