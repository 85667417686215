import { Component, OnInit, OnDestroy, Inject, ErrorHandler } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { LaskuService } from '../../../_angular/service/lasku/lasku.service'


import { Lasku, Laskuasetukset } from '../../../_jaettu/model/lasku'

import { Subject } from 'rxjs'
import { MatSnackBar } from '@angular/material/snack-bar'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonator.service'
import { LaskuUriService } from 'app/_jaettu/service/lasku/lasku-uri.service'


export interface LahetaMuistutusDialogData {
  laskut: Lasku[]
}

@Component({
  templateUrl: './laheta.dialog.html'
})
export class LahetaMuistutusDialog implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>()

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LahetaMuistutusDialogData,
    private _dialogRef: MatDialogRef<LahetaMuistutusDialog>,
    private _errorHandler: ErrorHandler,
    private _laskuService: LaskuService,
    private _laskuUriService: LaskuUriService,
    private _snackbar: MatSnackBar,
    private _firebaseLemonaid: FirebaseLemonaid
  ) {

  }

  ngOnInit() {
    this.sendReminderInvoices()
  }

  async sendReminderInvoices() {
    const asetuksetUri = this._laskuUriService.getLaskuasetuksetUri(this._laskuService.LEMONTREE_ASIAKAS_AVAIN, this._laskuService.LEMONTREE_ASIAKAS_AVAIN)
    const asetukset = await this._firebaseLemonaid.firestoreDoc<Laskuasetukset>(asetuksetUri).get().then(asetukset => {
      if (asetukset) { asetukset.avain = this._laskuService.LEMONTREE_ASIAKAS_AVAIN }
      return asetukset
    })

    for (const alkuperainenLasku of this.data.laskut) {
      try {
        await this._laskuService.sendLemonTreeReminderInvoice(alkuperainenLasku, asetukset)
      } catch (error) {
        this._snackbar.open(error, 'OK', { duration: 15000 })
      }
    }

    this._dialogRef.close()
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

}
