import { Component, OnInit, OnDestroy, ErrorHandler } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { MatSnackBar } from '@angular/material/snack-bar'
import { MatDialog } from '@angular/material/dialog'

import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'

import { AsiakasService } from '../../_angular/service/asiakas/asiakas.service'
import { AsiakasKopioija } from '../../_angular/service/asiakas/asiakas.kopioija'

import { combineLatest, firstValueFrom, map, Observable, of, Subject, switchMap, takeUntil } from 'rxjs'

import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { CurrencyService } from 'app/_shared-core/service/currency.service'
import { FirebaseLemonaid, FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { Asiakas, AsiakkaanSopimuskaudenTauko, AsiakkaanSopimuskaudenTila, AsiakkaanSopimuskausi, PakotaSopimuksenUudelleenAllekirjoitusPyynto, PakotaSopimuksenUudelleenAllekirjoitusVastaus, PerutaSopimuksenPaattyminenPyynto, PerutaSopimuksenPaattyminenVastaus } from 'app/_jaettu-lemonator/model/asiakas'
import { DebugService } from 'app/_angular/service/debug.service'

import { AsiakkaanSopimuskausiUusiDialog, AsiakkaanSopimuskausiUusiDialogData } from './sopimuskausi-uusi.dialog'
import { AsiakkaanSopimuskausiPaataDialog, AsiakkaanSopimuskausiPaataDialogData } from './sopimuskausi-paata.dialog'
import { AsiakkaanSopimuskausiTaukoDialog, AsiakkaanSopimuskausiTaukoDialogData } from './sopimuskausi-tauko.dialog'
import { AsiakasJaettuService } from 'app/_jaettu-lemonator/service/asiakas-jaettu.service'
import { AsiakkaanSopimuskausiService } from 'app/_jaettu-lemonator/service/sopimuskausi.service'
import { SopimuksenHyvaksynnanHistoria } from 'app/_jaettu/model/sopimus'
import { KirjanpitajaService } from 'app/_angular/service/kirjanpitaja/kirjanpitaja.service'
import { LocalDate } from 'app/_shared-core/model/common'
import { DateService } from 'app/_shared-core/service/date.service'
import { AreYouSureDialog, AreYouSureDialogData } from 'app/_jaettu-angular/_components/are-you-sure.dialog'
import { FormControl, FormGroup } from '@angular/forms'

interface MuokkaaAsiakkaanTietojaForm {
  naytaKayttajilleTervetuloaDialogi: FormControl<boolean>
  vaadiKayttajiltaUusiTunnistautuminen: FormControl<boolean>
  vaadiPepTietojenPaivittaminen: FormControl<boolean>
  vaadiSopimuksenAllekirjoittaminenUudelleen: FormControl<boolean>
}

interface SopimuskaudenTaukorivi {
  tauko: AsiakkaanSopimuskaudenTauko
  naytetaankoMuokkaaNappi: boolean
  luoja: string
  paivittaja: string
}

interface SopimuskaudenHyvaksyntarivi {
  hyvaksynta: SopimuksenHyvaksynnanHistoria
  hyvaksyja: string
}

interface Sopimuskausirivi {
  kausi: AsiakkaanSopimuskausi
  syyOtsikko: string
  syy: string
  lisatiedot: string
  onkoPurku: boolean
  sopimusPaattyyPianEmailDate: LocalDate
  sopimusOnPaattynytEmailDate: LocalDate
  devaaja: boolean
  naytaLaitaTauolle: boolean
  naytaPakotaSopimuksenAllekirjoitus: boolean
  naytaPerutaSopimuksenPaattyminen: boolean
  laskutusAlkaa: string
  tauot: SopimuskaudenTaukorivi[]
  poytalaatikot: SopimuskaudenTaukorivi[]
  hyvaksynnat: SopimuskaudenHyvaksyntarivi[]
  tila: AsiakkaanSopimuskaudenTila
  tilanNimi: string
  // tilanVarikoodi: string
  luoja: string
  paivittaja: string
}

@Component({
  templateUrl: './sopimuskaudet.component.html',
  styleUrls: ['./sopimuskaudet.component.css']
})
export class AsiakasAsetuksetSopimuskaudetComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>()

  asiakas: Asiakas = null
  sopimuskausiError: string = null

  asiakasUriObservable: Observable<string>
  asiakasEncodedUriObservable: Observable<string>
  kirjanpitajaOnDevaajaObservable: Observable<boolean>
  sopimuskaudetObservable: Observable<Sopimuskausirivi[]>
  naytaLisaaObservable: Observable<boolean>

  muokkaaAsiakkaanTietojaFormGroup: FormGroup<MuokkaaAsiakkaanTietojaForm>

  private _asiakkaanSopimuskausiService = new AsiakkaanSopimuskausiService(this._dateService, this._timestampService)
  private _sopimustenHyvaksynnatObservable: Observable<SopimuksenHyvaksynnanHistoria[]>

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _dialog: MatDialog,
    private _asiakasService: AsiakasService,
    private _dateService: DateService,
    private _asiakasKopioija: AsiakasKopioija,
    private _errorHandler: ErrorHandler,
    private _ladataanService: LadataanService,
    private _snackbar: MatSnackBar,
    private _timestampService: TimestampService,
    private _firebase: FirebaseLemonator,
    private _lemonaidFirebase: FirebaseLemonaid,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _asiakasJaettuService: AsiakasJaettuService,
    private _currencyService: CurrencyService,
    private _debugService: DebugService,
    private _kirjanpitajaService: KirjanpitajaService
  ) {
    this.asiakas = this._asiakasKopioija.annaUusiAsiakas()
  }

  ngOnInit() {

    this.muokkaaAsiakkaanTietojaFormGroup = new FormGroup({
      naytaKayttajilleTervetuloaDialogi: new FormControl<boolean>(false),
      vaadiKayttajiltaUusiTunnistautuminen: new FormControl<boolean>(false),
      vaadiPepTietojenPaivittaminen: new FormControl<boolean>(false),
      vaadiSopimuksenAllekirjoittaminenUudelleen: new FormControl<boolean>(false)
    })

    // Debug thingies
    this.kirjanpitajaOnDevaajaObservable = this._kirjautunutKayttajaService.kirjanpitajaOnDevaajaObservable
    this.asiakasUriObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      map(avainTiedot => {
        if (avainTiedot?.avain) {
          return 'asiakkaat/' + avainTiedot.avain
        }
        return ''
      })
    )
    this.asiakasEncodedUriObservable = this.asiakasUriObservable.pipe(
      map(uri => {
        return this._debugService.createFirestoreLink(uri)
      })
    )
    this._sopimustenHyvaksynnatObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (asiakas) {
          return this._lemonaidFirebase.firestoreCollectionGroup<SopimuksenHyvaksynnanHistoria>('sopimus-hyvaksynnat-full-history')
            .where('asiakasAvain', '==', asiakas.avain)
            .listen()
        }
        return of<SopimuksenHyvaksynnanHistoria[]>([])
      })
    )
    this._asiakasService.nykyinenAsiakasObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(asiakas => {
      if (asiakas) {
        this.asiakas = this._asiakasKopioija.kopioiAsiakas(asiakas)
      }
    })
    const kayttajatNimiMapObservable: Observable<Map<string, string>> = this._asiakasService.nykyisenAsiakkaanKayttajatObservable.pipe(
      map(kayttajat => {
        const m: Map<string, string> = new Map()
        if (kayttajat) {
          for (const kayttaja of kayttajat) {
            m.set(kayttaja.avain, kayttaja.etunimi + ' ' + kayttaja.sukunimi)
          }
        }
        return m
      })
    )
    this.sopimuskaudetObservable = combineLatest([
      this._asiakasService.nykyinenAsiakasObservable,
      this._kirjautunutKayttajaService.kirjanpitajaOnDevaajaObservable,
      this._sopimustenHyvaksynnatObservable,
      this._kirjanpitajaService.kirjanpitajienNimitiedotMapObservable,
      kayttajatNimiMapObservable
    ]).pipe(
      map(([asiakas, devaaja, sopimuksenHyvaksynnat, kirjanpitajatNimiMap, kayttajaNimiMap]) => {
        const sopimuskaudet = asiakas?.sopimuskaudet ?? []
        const sopimuskausienAllekirjoituksetMap = this._asiakkaanSopimuskausiService.annaSopimuskausienAikaisetAllekirjoitukset(sopimuskaudet, sopimuksenHyvaksynnat ?? [])
        return sopimuskaudet.map((kausi, idx) => {

          const kuukaudenHintaJaSeuraava = this._asiakasJaettuService.annaHintaKuukaudelleJaSeuraavaHinta(asiakas, kausi.alkaa)
          let laskutusAlkaa = 'Hinta puuttuu'
          if (kuukaudenHintaJaSeuraava.voimassaOleva) {
            const alku = this._dateService.maxLocalDate(kuukaudenHintaJaSeuraava.voimassaOleva.voimassaAlkaen, kausi.alkaa, this._dateService.timestampToLocalDate(kausi.luotu))
            laskutusAlkaa = this._formatoiHinta(alku, kuukaudenHintaJaSeuraava.voimassaOleva.hinta)
          } else if (kuukaudenHintaJaSeuraava.seuraava) {
            laskutusAlkaa = this._formatoiHinta(kuukaudenHintaJaSeuraava.seuraava.voimassaAlkaen, kuukaudenHintaJaSeuraava.seuraava.hinta)
          }

          const luoja = kirjanpitajatNimiMap.get(kausi.luoja)
          const paivittaja = kirjanpitajatNimiMap.get(kausi.paivittaja)
          const kaikkiKaudenAikana = sopimuskausienAllekirjoituksetMap.get(kausi.avain)
          const tila = this._asiakkaanSopimuskausiService.annaSopimuskaudenTila(kausi, this._dateService.currentLocalDate(), kaikkiKaudenAikana)
          const onkoPurku = this._asiakkaanSopimuskausiService.onkoPurku(kausi.lopettamisenSyy)
          const onkoLopetus = this._asiakkaanSopimuskausiService.onkoLopetus(kausi.lopettamisenSyy)
          const tauot: AsiakkaanSopimuskaudenTauko[] = kausi.tauot ?? []
          const mappedTauot = tauot.map((tauko, index) => {
            const taukoLuoja = kirjanpitajatNimiMap.get(kausi.luoja)
            const taukoPaivittaja = kirjanpitajatNimiMap.get(kausi.paivittaja)
            const r: SopimuskaudenTaukorivi = {
              tauko: tauko,
              naytetaankoMuokkaaNappi: tauot.length === index + 1,
              luoja: taukoLuoja ? taukoLuoja.etunimi + ' ' + taukoLuoja.sukunimi : 'Luojaa EI löydy!',
              paivittaja: taukoPaivittaja ? taukoPaivittaja.etunimi + ' ' + taukoPaivittaja.sukunimi : 'Paivittajaa EI löydy!'
            }
            return r
          })
          const mapped: Sopimuskausirivi = {
            kausi: kausi,
            lisatiedot: kausi.lisatiedot ?? null,
            syy: null,
            syyOtsikko: onkoPurku ? 'Purkamisen syy' : 'Päättymisen syy',
            onkoPurku: onkoPurku,
            sopimusPaattyyPianEmailDate: this._asiakkaanSopimuskausiService.annaSopimusPaattyyPianEmailDate(kausi),
            sopimusOnPaattynytEmailDate: this._asiakkaanSopimuskausiService.annaSopimusOnPaattynytEmailDate(kausi),
            devaaja: devaaja,
            laskutusAlkaa: laskutusAlkaa,
            naytaLaitaTauolle: !tauot.find(tauko => !tauko.loppuu),
            naytaPakotaSopimuksenAllekirjoitus: kaikkiKaudenAikana.length > 0 && devaaja && idx + 1 === asiakas.sopimuskaudet.length,
            naytaPerutaSopimuksenPaattyminen: devaaja && (onkoLopetus || onkoPurku),
            tauot: mappedTauot.filter(t => t.tauko.tyyppi === 'tauko'),
            poytalaatikot: mappedTauot.filter(t => t.tauko.tyyppi === 'poytalaatikko'),
            hyvaksynnat: kaikkiKaudenAikana.map(hyvaksynta => {
              const hyvaksyja = kayttajaNimiMap.get(hyvaksynta.kayttajaAvain)
              const h: SopimuskaudenHyvaksyntarivi = {
                hyvaksyja: hyvaksyja ? hyvaksyja : 'Hyväksyjää EI löydy!',
                hyvaksynta: hyvaksynta
              }
              return h
            }),
            tila: tila,
            tilanNimi: this._asiakkaanSopimuskausiService.annaTilanNimi(tila),
            // tilanVarikoodi: this._asiakkaanSopimuskausiService.annaTilanVarikoodi(tila),
            luoja: luoja ? luoja.etunimi + ' ' + luoja.sukunimi : 'Luojaa EI löydy!',
            paivittaja: paivittaja ? paivittaja.etunimi + ' ' + paivittaja.sukunimi : 'Paivittajaa EI löydy!'
          }
          if (kausi.lopettamisenSyy) {
            mapped.syy = this._asiakkaanSopimuskausiService.lokalisoidutSopimuksenLopettamissyyt.find(syy => syy.arvo === kausi.lopettamisenSyy)?.nimi ?? null
          }
          return mapped
        })
      })
    )
    this.naytaLisaaObservable = this._asiakasService.nykyinenAsiakasObservable.pipe(
      map(asiakas => !asiakas?.sopimuskaudet?.find(kausi => !kausi.loppuu))
    )
  }

  private _formatoiHinta(pvm: LocalDate, hinta: number): string {
    return pvm.month + '/' + pvm.year + ' (' + this._currencyService.formatoiRaha(hinta, 'EUR', 'fi') + ')'
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  // poistaSopimuskausi(sopimuskausi: AsiakkaanSopimuskausi) {
  //   const index = this.asiakas.sopimuskaudet.findIndex(k => k.avain === sopimuskausi.avain)
  //   if (index > -1) {
  //     this.asiakas.sopimuskaudet.splice(index, 1)
  //   }
  // }

  async laitaTauolle(sopimuskausi: AsiakkaanSopimuskausi) {
    const tauko: AsiakkaanSopimuskaudenTauko = {
      alkaa: null,
      tyyppi: 'tauko',
      avain: this._firebase.firestoreCreateId(),
      loppuu: null,
      luoja: null,
      luotu: null,
      paivitetty: null,
      paivittaja: null,
      poistettu: false,
      muistutuspvm: this._dateService.lisaaKuukausiaPaikallinen(this._dateService.currentLocalDate(), 3)
    }
    await this.muokkaaTaukoa(sopimuskausi, tauko, true)
  }

  laitaPoytalaatikkoon(sopimuskausi: AsiakkaanSopimuskausi) {
    const tauko: AsiakkaanSopimuskaudenTauko = {
      alkaa: null,
      tyyppi: 'poytalaatikko',
      avain: this._firebase.firestoreCreateId(),
      loppuu: null,
      luoja: null,
      luotu: null,
      paivitetty: null,
      paivittaja: null,
      poistettu: false
    }
    this.muokkaaTaukoa(sopimuskausi, tauko, true)
  }

  async muokkaaTaukoa(sopimuskausi: AsiakkaanSopimuskausi, tauko: AsiakkaanSopimuskaudenTauko, uusi: boolean) {
    const data: AsiakkaanSopimuskausiTaukoDialogData = {
      asiakas: this.asiakas,
      kausi: sopimuskausi,
      tauko: tauko,
      uusi: uusi
    }

    const dialogNgUnsubscribe: Subject<void> = new Subject<void>()
    this._dialog.open<AsiakkaanSopimuskausiTaukoDialog, AsiakkaanSopimuskausiTaukoDialogData, void>(AsiakkaanSopimuskausiTaukoDialog, {
      data: data
    }).afterClosed().pipe(
      takeUntil(dialogNgUnsubscribe)
    ).subscribe({
      next: () => {
      },
      error: err => {
        this._errorHandler.handleError(err)
        dialogNgUnsubscribe.next()
        dialogNgUnsubscribe.complete()
      },
      complete: () => {
        dialogNgUnsubscribe.next()
        dialogNgUnsubscribe.complete()
      }
    })
  }

  muokkaaAsiakkaanAsetuksia(): Promise<any> {
    this._ladataanService.aloitaLataaminen()
    const pyynto: PakotaSopimuksenUudelleenAllekirjoitusPyynto = {
      asiakasAvain: this.asiakas.avain,
      naytaKayttajilleTervetuloaDialogi: this.muokkaaAsiakkaanTietojaFormGroup.get('naytaKayttajilleTervetuloaDialogi').value,
      vaadiKayttajiltaUusiTunnistautuminen: this.muokkaaAsiakkaanTietojaFormGroup.get('vaadiKayttajiltaUusiTunnistautuminen').value,
      vaadiPepTietojenPaivittaminen: this.muokkaaAsiakkaanTietojaFormGroup.get('vaadiPepTietojenPaivittaminen').value,
      vaadiSopimuksenAllekirjoittaminenUudelleen: this.muokkaaAsiakkaanTietojaFormGroup.get('vaadiSopimuksenAllekirjoittaminenUudelleen').value
    }
    if (
      !pyynto.naytaKayttajilleTervetuloaDialogi &&
      !pyynto.vaadiKayttajiltaUusiTunnistautuminen &&
      !pyynto.vaadiPepTietojenPaivittaminen &&
      !pyynto.vaadiSopimuksenAllekirjoittaminenUudelleen
    ) {
      this._snackbar.open('EI TALLENNETTAVIA MUUTOKSIA. MITÄÄN EI TEHTY.', null, { duration: 3000 })
    }
    return this._firebase.functionsCall<PakotaSopimuksenUudelleenAllekirjoitusPyynto, PakotaSopimuksenUudelleenAllekirjoitusVastaus>('sopimuksenUudelleenAllekirjoittaminenPakotetusti', pyynto).then(() => {
      this._snackbar.open('Muutokset tallennettiin.', null, { duration: 3000 })
    }).catch(error => {
      this._errorHandler.handleError(error)
    }).finally(() => {
      this.muokkaaAsiakkaanTietojaFormGroup.get('naytaKayttajilleTervetuloaDialogi').setValue(false)
      this.muokkaaAsiakkaanTietojaFormGroup.get('vaadiKayttajiltaUusiTunnistautuminen').setValue(false)
      this.muokkaaAsiakkaanTietojaFormGroup.get('vaadiPepTietojenPaivittaminen').setValue(false)
      this.muokkaaAsiakkaanTietojaFormGroup.get('vaadiSopimuksenAllekirjoittaminenUudelleen').setValue(false)
      this._ladataanService.lopetaLataaminen()
    })
  }

  async paataSopimuskausi(sopimuskausi: AsiakkaanSopimuskausi) {
    this.sopimuskausiError = null
    const data: AsiakkaanSopimuskausiPaataDialogData = {
      asiakas: this.asiakas,
      kausi: sopimuskausi
    }
    const dialogNgUnsubscribe: Subject<void> = new Subject<void>()
    this._dialog.open<AsiakkaanSopimuskausiPaataDialog, AsiakkaanSopimuskausiPaataDialogData, void>(AsiakkaanSopimuskausiPaataDialog, {
      data: data
    }).afterClosed().pipe(
      takeUntil(dialogNgUnsubscribe)
    ).subscribe({
      next: () => {
      },
      error: err => {
        this._errorHandler.handleError(err)
        dialogNgUnsubscribe.next()
        dialogNgUnsubscribe.complete()
      },
      complete: () => {
        dialogNgUnsubscribe.next()
        dialogNgUnsubscribe.complete()
      }
    })
  }

  lisaaSopimuskausi() {

    if (this.asiakas.sopimuskaudet?.find(kausi => !kausi.loppuu)) {
      this.sopimuskausiError = 'Päätä edellinen sopimuskausi ennen uuden lisäämistä.'
      return
    }
    this.sopimuskausiError = null

    const dialogNgUnsubscribe: Subject<void> = new Subject<void>()
    const data: AsiakkaanSopimuskausiUusiDialogData = {
      asiakas: this.asiakas
    }
    this._dialog.open<AsiakkaanSopimuskausiUusiDialog, AsiakkaanSopimuskausiUusiDialogData, void>(AsiakkaanSopimuskausiUusiDialog, {
      data: data
    }).afterClosed().pipe(
      takeUntil(dialogNgUnsubscribe)
    ).subscribe({
      next: () => {
        // if (kausi) {
        //   if (!this.asiakas.sopimuskaudet) {
        //     this.asiakas.sopimuskaudet = []
        //   }
        //   this.asiakas.sopimuskaudet.push(kausi)
        // }
      },
      error: err => {
        this._errorHandler.handleError(err)
        dialogNgUnsubscribe.next()
        dialogNgUnsubscribe.complete()
      },
      complete: () => {
        dialogNgUnsubscribe.next()
        dialogNgUnsubscribe.complete()
      }
    })

  }

  async pakotaSopimuksenAllekirjoittaminenUudelleen() {
    const isSure = await firstValueFrom(this._dialog.open<AreYouSureDialog, AreYouSureDialogData>(AreYouSureDialog, { data: { rightAction: 'Pakota', text: 'Oletko varma, että haluat laittaa allekirjoituksen pakotetusti päälle?' } }).afterClosed())

    if (!isSure) {
      return
    }

    if (this.asiakas && this.asiakas.avain) {
      this._ladataanService.aloitaLataaminen()
      const pyynto: PakotaSopimuksenUudelleenAllekirjoitusPyynto = {
        asiakasAvain: this.asiakas.avain,
        naytaKayttajilleTervetuloaDialogi: false,
        vaadiKayttajiltaUusiTunnistautuminen: false,
        vaadiPepTietojenPaivittaminen: false,
        vaadiSopimuksenAllekirjoittaminenUudelleen: true
      }
      this._firebase.functionsCall<PakotaSopimuksenUudelleenAllekirjoitusPyynto, PakotaSopimuksenUudelleenAllekirjoitusVastaus>('sopimuksenUudelleenAllekirjoittaminenPakotetusti', pyynto).then(() => {
        this._ladataanService.lopetaLataaminen()
      }).catch(error => {
        this._ladataanService.lopetaLataaminen()
        this._errorHandler.handleError(error)
      })
    } else {
      this._errorHandler.handleError(new Error('Asiakas puuttuu!! ' + JSON.stringify(this.asiakas)))
    }
  }

  async perutaSopimuksenPaattyminen(sopimuskausiAvain: string) {
    const isSure = await firstValueFrom(this._dialog.open<AreYouSureDialog, AreYouSureDialogData>(AreYouSureDialog, { data: { rightAction: 'Peruta', text: 'Oletko varma, että haluat perua sopimuksen päättymisen?' } }).afterClosed())

    if (!isSure) {
      return
    }

    if (this.asiakas && this.asiakas.avain) {
      this._ladataanService.aloitaLataaminen()
      const pyynto: PerutaSopimuksenPaattyminenPyynto = {
        asiakasAvain: this.asiakas.avain,
        sopimuskausiAvain: sopimuskausiAvain
      }
      return this._firebase.functionsCall<PerutaSopimuksenPaattyminenPyynto, PerutaSopimuksenPaattyminenVastaus>('sopimuksenPaattyminenPeruta', pyynto).then(resp => {
        if (!resp || resp.e) {
          this._errorHandler.handleError(new Error('Sopimuksen päättämisen peruminen epännistui! ' + resp?.e ?? 'Tuntematon virhe'))
        } else {
          this._snackbar.open('Sopimuksen päättyminen peruttu.', 'OK', { duration: 5000 })
        }
      }).catch(err => {
        this._errorHandler.handleError(new Error('Sopimuksen päättämisen peruminen epännistui tuntematon virheen takia!'))
      }).finally(() => {
        this._ladataanService.lopetaLataaminen()
      })
    }

  }

}
