import { Asiakas, Tilikausi } from '../../../_jaettu-lemonator/model/asiakas'
import { KirjanpitajanNimitiedot } from '../../../_jaettu-lemonator/model/kirjanpitaja'
import { VeroilmoituksenAvainluvut, VeroilmoitusDraft, TilikaudenSummat, VeroilmoitusFinal, VeroilmoitusOsakas } from '../../../_jaettu-lemonator/model/kirjanpito'
import { ErottavanTilinTiedot, VeroilmoitusSummatService } from './veroilmoitus-summat.service'
import { CustomerOsakas, KirjanpidonUlkopuolisetKulut, Yritysmuoto } from '../../../_jaettu/model/kayttaja'
import { DateService } from '../../../_shared-core/service/date.service'
import { VeroilmoitusLaskenta2020TmiService } from './tmi/veroilmoitus-laskenta-2020-tmi.service'
import { VeroilmoitusLaskenta2021TmiService } from './tmi/veroilmoitus-laskenta-2021-tmi.service'
import { VeroilmoitusLaskenta2022TmiService } from './tmi/veroilmoitus-laskenta-2022-tmi.service'
import { VeroilmoitusLaskenta2023TmiService } from './tmi/veroilmoitus-laskenta-2023-tmi.service'
import { VeroilmoitusLaskenta2024TmiService } from './tmi/veroilmoitus-laskenta-2024-tmi.service'
import { VeroilmoitusLaskenta2020OyService } from './oy/veroilmoitus-laskenta-2020-oy.service'
import { VeroilmoitusLaskenta2021OyService } from './oy/veroilmoitus-laskenta-2021-oy.service'
import { VeroilmoitusLaskenta2022OyService } from './oy/veroilmoitus-laskenta-2022-oy.service'
import { VeroilmoitusLaskenta2023OyService } from './oy/veroilmoitus-laskenta-2023-oy.service'
import { VeroilmoitusLaskenta2024OyService } from './oy/veroilmoitus-laskenta-2024-oy.service'
import { IlmoitusRivi } from '../base-ilmoitin-export.service'
import { Timestamp } from '../../../_shared-core/model/common'
import { CurrencyService } from '../../../_shared-core/service/currency.service'
import { AsiakasJaettuService } from '../asiakas-jaettu.service'
import { CodeCheckService } from '../../../_shared-core/service/code-check.service'
import { Korko } from '../../../_jaettu/service/vahvistettu-korko.service'

export interface VeroilmoituksenPaivityksenTulos {
  arvot: IlmoitusRivi[]
  oletukset: VeroilmoituksenAvainluvut
  erottavatTilit: ErottavanTilinTiedot[]
  virheet: VeroilmoitusValidationError[] | null
}

export interface VeroilmoituksenKaikkiTilisummat {
  /**
   * Nykyisen tilikauden summat tileittäin
   */
  nykyinen: TilikaudenSummat
  /**
   * Edellisen tilikauden summat tileittäin
   */
  edellinen: TilikaudenSummat
}

export interface VeroilmoitusValidationError {
  fields: string
  message: string
}

export interface OsingotLocatingCalculationService {
  groupIlmoitusrivitByOsingot(arvot: IlmoitusRivi[]): IlmoitusRivi[][]
  annaTunniste(numero: string, group: string, order: number)
}
export interface VeroilmoituksenLaskija {

  // validoi(rivit: IlmoitusRivi[]): VeroilmoitusValidationError[]

  muodostaVeroilmoituksenLopullisetArvot(
    tietokannasta: VeroilmoitusDraft,
    kayttajanMuokkaamatEiTallennetut: VeroilmoituksenAvainluvut,
    tilienSummat: VeroilmoituksenKaikkiTilisummat,
    summatService: VeroilmoitusSummatService,
    asiakas: Asiakas,
    kirjanpitaja: KirjanpitajanNimitiedot,
    tilikausi: Tilikausi,
    kirjanpidonUlkopuolisetKulut: KirjanpidonUlkopuolisetKulut,
    muodostettu: Timestamp, // Must be same as created to the nanosecond.
    peruskorko: Korko
  ): VeroilmoituksenPaivityksenTulos
}

export class VeroilmoitusLaskentaService {

  constructor(
    private _dateService: DateService,
    private _currencyService: CurrencyService,
    private _codeCheckService: CodeCheckService,
    private _asiakasJaettuService: AsiakasJaettuService
  ) { }

  public annaDefaultUlkopuolisetKulutLomake(
    asiakasAvain: string,
    tilikausi: Tilikausi
  ) {
    const kirjanpidonUlkopuolisetKulut: KirjanpidonUlkopuolisetKulut = {
      ajoneuvot: [],
      asiakasAvain: asiakasAvain,
      avain: null,
      kotiYrityksenTyotilana: 'ei-vahennysta',
      luoja: null,
      luotu: null,
      paivitetty: null,
      paivittaja: null,
      poistettu: false,
      tiedotPerustuvat: 'muuhun selvitykseen',
      ulkomaanMatkapaivienLukumaara: 0,
      ulkomaanPaivarahojenYhteismaara: 0,
      vuosi: tilikausi?.loppuu?.year || 0,
      yli10hKotimaanMatkojenLukumaara: 0,
      yli6hKotimaanMatkojenLukumaara: 0
    }
    return kirjanpidonUlkopuolisetKulut
  }

  public lajitteleTilisummat(
    asiakas: Asiakas,
    tilikausi: Tilikausi,
    edellinenTilikausi: Tilikausi,
    summat: TilikaudenSummat[]
  ) {

    const lajitellutTilisummat: VeroilmoituksenKaikkiTilisummat = {
      edellinen: null,
      nykyinen: null
    }

    if (!asiakas || !tilikausi || !summat) {
      return lajitellutTilisummat
    }

    for (const tilienSummat of summat) {
      if (tilikausi?.avain === tilienSummat.tilikausiAvain) {
        lajitellutTilisummat.nykyinen = tilienSummat
      } else if (edellinenTilikausi?.avain === tilienSummat.tilikausiAvain) {
        lajitellutTilisummat.edellinen = tilienSummat
      }
      //  else {
      //   const kausi = asiakas.tilikaudet.find(k => k.avain === tilienSummat.tilikausiAvain)
      //   if (kausi && edellinenTilikausi && this._dateService.compareLocalDates(kausi.loppuu, '<', edellinenTilikausi.alkaa)) {
      //     lajitellutTilisummat.aikaisemmat.push(tilienSummat)
      //   }
      // }
    }

    // const tilikaudenSummat = lajitellutTilisummat.nykyinen?.tilikaudenSummat ?? {}
    // const edellisenTilikaudenSummat = lajitellutTilisummat.edellinen?.tilikaudenSummat ?? {}
    // const aikaisemmat: TilienSummat[] = lajitellutTilisummat.aikaisemmat ? lajitellutTilisummat.aikaisemmat.map(a => a.tilikaudenSummat) : []
    // aikaisemmat.push(edellisenTilikaudenSummat)
    // lajitellutTilisummat.edellinenKumulatiivinen = this.summaTilisummat(aikaisemmat)
    // lajitellutTilisummat.nykyinenKumulatiivinen = this.summaTilisummat([tilikaudenSummat, lajitellutTilisummat.edellinenKumulatiivinen])

    return lajitellutTilisummat
  }

  public veroilmoitusVoidaanLaskea(yritysmuoto: Yritysmuoto, vuosi: number): boolean {
    return !!this._annaLaskija(yritysmuoto, vuosi)
  }

  private _annaLaskija(yritysmuoto: Yritysmuoto, vuosi: number): VeroilmoituksenLaskija {
    if (yritysmuoto === Yritysmuoto.TOIMINIMI) {
      if (vuosi === 2020) {
        return new VeroilmoitusLaskenta2020TmiService(this._dateService, this._currencyService, this._codeCheckService, this._asiakasJaettuService)
      } else if (vuosi === 2021) {
        return new VeroilmoitusLaskenta2021TmiService(this._dateService, this._currencyService, this._codeCheckService, this._asiakasJaettuService)
      } else if (vuosi === 2022) {
        return new VeroilmoitusLaskenta2022TmiService(this._dateService, this._currencyService, this._codeCheckService, this._asiakasJaettuService)
      } else if (vuosi === 2023) {
        return new VeroilmoitusLaskenta2023TmiService(this._dateService, this._currencyService, this._codeCheckService, this._asiakasJaettuService)
      } else if (vuosi === 2024) {
        return new VeroilmoitusLaskenta2024TmiService(this._dateService, this._currencyService, this._codeCheckService, this._asiakasJaettuService)
      }
    } else if (yritysmuoto === Yritysmuoto.OSAKEYHTIO) {
      if (vuosi === 2020) {
        return new VeroilmoitusLaskenta2020OyService(this._dateService, this._currencyService, this._codeCheckService, this._asiakasJaettuService)
      } else if (vuosi === 2021) {
        return new VeroilmoitusLaskenta2021OyService(this._dateService, this._currencyService, this._codeCheckService, this._asiakasJaettuService)
      } else if (vuosi === 2022) {
        return new VeroilmoitusLaskenta2022OyService(this._dateService, this._currencyService, this._codeCheckService, this._asiakasJaettuService)
      } else if (vuosi === 2023) {
        return new VeroilmoitusLaskenta2023OyService(this._dateService, this._currencyService, this._codeCheckService, this._asiakasJaettuService)
      } else if (vuosi === 2024) {
        return new VeroilmoitusLaskenta2024OyService(this._dateService, this._currencyService, this._codeCheckService, this._asiakasJaettuService)
      }
    }
    return null
  }

  public muodostaVeroilmoituksenLopullisetArvot(
    asiakas: Asiakas,
    kirjanpitaja: KirjanpitajanNimitiedot,
    tilikausi: Tilikausi,
    tietokannasta: VeroilmoitusDraft,
    kayttajanMuokkaamatEiTallennetut: VeroilmoituksenAvainluvut,
    kaikkiTilisummat: VeroilmoituksenKaikkiTilisummat,
    summatService: VeroilmoitusSummatService,
    kirjanpidonUlkopuolisetKulut: KirjanpidonUlkopuolisetKulut,
    muodostettu: Timestamp, // Must be same as created to the nanosecond.
    peruskorko: Korko
  ): VeroilmoituksenPaivityksenTulos {
    const laskija = this._annaLaskija(asiakas.yritysmuoto, tilikausi.loppuu.year)
    if (laskija) {
      const result = laskija.muodostaVeroilmoituksenLopullisetArvot(
        tietokannasta,
        kayttajanMuokkaamatEiTallennetut,
        kaikkiTilisummat,
        summatService,
        asiakas,
        kirjanpitaja,
        tilikausi,
        kirjanpidonUlkopuolisetKulut,
        muodostettu,
        peruskorko
      )
      return result
    }

    const tulos: VeroilmoituksenPaivityksenTulos = {
      arvot: [],
      oletukset: {},
      erottavatTilit: [],
      virheet: null
    }
    return tulos

  }

  public muodostaLahetettavaVeroilmoitus(
    asiakas: Asiakas,
    kirjanpitaja: KirjanpitajanNimitiedot,
    tilikausi: Tilikausi,
    tietokannasta: VeroilmoitusDraft,
    kayttajanMuokkaamatEiTallennetut: VeroilmoituksenAvainluvut,
    kaikkiTilisummat: VeroilmoituksenKaikkiTilisummat,
    summatService: VeroilmoitusSummatService,
    kirjanpidonUlkopuolisetKulut: KirjanpidonUlkopuolisetKulut,
    muodostettu: Timestamp, // Must be same as created to the nanosecond.
    peruskorko: Korko
  ): VeroilmoitusFinal {
    const laskettu = this.muodostaVeroilmoituksenLopullisetArvot(
      asiakas,
      kirjanpitaja,
      tilikausi,
      tietokannasta,
      kayttajanMuokkaamatEiTallennetut,
      kaikkiTilisummat,
      summatService,
      kirjanpidonUlkopuolisetKulut,
      muodostettu, // Must be same as created to the nanosecond.
      peruskorko
    )
    const result: VeroilmoitusFinal = {
      avain: null,
      asiakasAvain: asiakas.avain,
      arvot: laskettu.arvot,
      arvotEditable: tietokannasta.arvotEditable,
      created: muodostettu,
      creator: kirjanpitaja.avain,
      lahetetty: null,
      tilikausiAvain: tilikausi.avain,
      year: tilikausi.loppuu.year,
      punakynaNumbers: tietokannasta.punakynaNumbers,
      osakkaat: tietokannasta.osakkaat
    }
    return result
  }

  convertKycToVeroilmoitusOsakas(kycOsakas: CustomerOsakas): VeroilmoitusOsakas {
    if (!kycOsakas) {
      return null
    }
    return {
      nimi: kycOsakas.nimi,
      hetuTaiYtunnus: this._formatHetu(kycOsakas),
      osakkeita: kycOsakas.osakkeita
    }
  }

  private _formatHetu(kycOsakas: CustomerOsakas) {
    if (!kycOsakas.noFinnishId) {
      return kycOsakas.hetuTaiYtunnus
    }
    if (kycOsakas.onkoYritys) {
      return '0000000-0'
    }
    if (!kycOsakas.birthDate) {
      return '010101-UUUU'
    }
    return this._dateService.muotoilePaikallinen(kycOsakas.birthDate, 'ddMMyy') + '-UUUU'
  }
}
