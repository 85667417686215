import { KopioijaPalvelu } from '../../_jaettu/service/kopioija.service'
import { TuettuKieli } from '../../_shared-core/model/common'
import { Paatilikartta, Kirjanpitotili, AsiakkaanTilikartta, PaatilikartanProfiili } from '../model/kirjanpito'

export interface HierarkiaKirjanpitotili {
  kirjanpitotili: Kirjanpitotili
  lapset: HierarkiaKirjanpitotili[]
}

interface AsiakkaanTilikarttaJaPuretutTilit {
  tilikartta: AsiakkaanTilikartta
  tilit: Kirjanpitotili[]
}

export class TilikarttaJaettuService {

  constructor(
    private _kopioijaPalvelu: KopioijaPalvelu
  ) { }

  annaKirjanpitotilinNimi(tili: Kirjanpitotili, kieli: TuettuKieli): string {
    if (tili?.localisedName && tili.localisedName[kieli]) {
      return tili.localisedName[kieli]
    }
    return tili?.nimi ?? ''
  }

  annaHierarkiaTili(tili: Kirjanpitotili, tilit: Kirjanpitotili[]): HierarkiaKirjanpitotili {
    const hierarkia: HierarkiaKirjanpitotili = {
      kirjanpitotili: tili,
      lapset: []
    }
    const lapset = this.annaTilinLapset(tili, tilit)
    for (const lapsi of lapset) {
      hierarkia.lapset.push(this.annaHierarkiaTili(lapsi, tilit))
    }
    hierarkia.lapset.sort((a, b) => {
      return Number(a.kirjanpitotili.numero) - Number(b.kirjanpitotili.numero)
    })
    return hierarkia
  }

  private annaTilinLapset(tili: Kirjanpitotili, tilit: Kirjanpitotili[]): Kirjanpitotili[] {
    return tilit.filter(a => a.vanhempi === tili.numero)
  }

  muutaTililistausHierarkiaksi(tilit: Kirjanpitotili[]): HierarkiaKirjanpitotili[] {
    const p: HierarkiaKirjanpitotili[] = []
    for (const tili of tilit) {
      if (!tili.vanhempi) {
        p.push(this.annaHierarkiaTili(tili, tilit))
      }
    }
    return p.sort((a, b) => {
      return Number(a.kirjanpitotili.numero) - Number(b.kirjanpitotili.numero)
    })
  }

  private _lisaaLapsenKirjanpitoTilitHierarkianMukaisessaJarjestyksessa(lapsi: HierarkiaKirjanpitotili, tilit: Kirjanpitotili[]) {
    tilit.push(lapsi.kirjanpitotili)
    if (lapsi.lapset) {
      for (const lapsenlapsi of lapsi.lapset) {
        this._lisaaLapsenKirjanpitoTilitHierarkianMukaisessaJarjestyksessa(lapsenlapsi, tilit)
      }
    }
  }

  annaKirjanpitoTilitHierarkianMukaisessaJarjestyksessa(kaikki: HierarkiaKirjanpitotili[]): Kirjanpitotili[] {
    const tilit: Kirjanpitotili[] = []
    for (const hierarkia of kaikki) {
      tilit.push(hierarkia.kirjanpitotili)
      if (hierarkia.lapset) {
        for (const lapsi of hierarkia.lapset) {
          this._lisaaLapsenKirjanpitoTilitHierarkianMukaisessaJarjestyksessa(lapsi, tilit)
        }
      }
    }
    return tilit
  }

  private _kopioiTiliJaKayttaenPaatilikartanProfiilia(tili: Kirjanpitotili, profiili: PaatilikartanProfiili | null): Kirjanpitotili {
    const copy = this._kopioiTili(tili)

    // Jos ei profiilia, palauta copy
    if (!profiili?.t) {
      return copy
    }

    const p = profiili.t[copy.numero]

    // Jos profiilissa ei ole tälle numerolle tietoja, palauta copy
    if (!p) {
      return copy
    }

    // Hoida nimi
    if (p.nimi) {
      if (!copy.localisedName) { copy.localisedName = { fi: null, en: null } }
      for (const key of Object.keys(p.nimi)) {
        if (p.nimi[key]) {
          copy.localisedName[key] = p.nimi[key]
        }
      }
    }

    // Hoida aktiivisuus
    if (p.piilota) {
      copy.aktiivinen = false
      copy.piilotettuProfiilissa = true
    }

    return copy
  }

  private _kopioiTili(tili: Kirjanpitotili): Kirjanpitotili {
    const t: Kirjanpitotili = {
      vanhempi: tili.vanhempi,
      numero: tili.numero,
      nimi: tili.nimi,
      oletusAlvKasittely: tili.oletusAlvKasittely,
      aktiivinen: tili.aktiivinen,
      alvTyyppi: tili.alvTyyppi
    }
    if (tili.reskontraActive !== undefined) {
      t.reskontraActive = !!tili.reskontraActive
    }
    if (tili.localisedName) {
      t.localisedName = this._kopioijaPalvelu.cloneObjectDeep(tili.localisedName)
    }
    return t
  }

  /**
   * Yhdistää päätilikartan asiakkaan tilimodifikaatioihin. Ei muokkaa päätilikarttaa
   * @param paatilikartta
   * @param asiakkaanTilikartta
   * @returns
   */
  yhdistaAsiakkaantilikarttaPaatilikarttaan(paatilikartta: Paatilikartta, asiakkaanTilikartta: AsiakkaanTilikartta): Kirjanpitotili[] {
    const paatilikartanTilitAlkuperaiset: Kirjanpitotili[] = paatilikartta && paatilikartta.tilit ? Object.keys(paatilikartta.tilit).map(key => paatilikartta.tilit[key]) : []
    const profiili = this._annaProfiili(paatilikartta, asiakkaanTilikartta)
    const paatilikartanTilit: Kirjanpitotili[] = paatilikartanTilitAlkuperaiset.map(tili => this._kopioiTiliJaKayttaenPaatilikartanProfiilia(tili, profiili))
    const asiakkanTilikarttaJaPuretutTilit: AsiakkaanTilikarttaJaPuretutTilit = {
      tilikartta: asiakkaanTilikartta,
      tilit: asiakkaanTilikartta?.tilit ? Object.keys(asiakkaanTilikartta.tilit).map(key => asiakkaanTilikartta.tilit[key]) : []
    }

    const kaikkiTilit = paatilikartanTilit.concat(asiakkanTilikarttaJaPuretutTilit.tilit)
    const yliajotilit = asiakkaanTilikartta?.yliajotilit

    if (!yliajotilit) {
      // Nothing to customize, return
      return kaikkiTilit
    }

    // Process customizations
    for (const tili of kaikkiTilit) {
      if (yliajotilit && yliajotilit[tili.numero]) {
        const yliajotili = yliajotilit[tili.numero]
        tili.nimi = yliajotili.nimi

        tili.localisedName = yliajotili.localisedName ?? { fi: null, en: null }

        if (yliajotili.alvTyyppi !== undefined) {
          tili.alvTyyppi = yliajotili.alvTyyppi
        }
        if (yliajotili.oletusAlvKasittely !== undefined) {
          tili.oletusAlvKasittely = yliajotili.oletusAlvKasittely
        }
        if (yliajotili.reskontraActive !== undefined) {
          tili.reskontraActive = yliajotili.reskontraActive
        }
      }
    }

    return kaikkiTilit
  }

  private _annaProfiili(paatilikartta: Paatilikartta, asiakkaanTilikartta: AsiakkaanTilikartta): PaatilikartanProfiili | null {
    if (!paatilikartta?.profiilit) {
      return null
    }
    if (asiakkaanTilikartta?.profiili) {
      const profiili = paatilikartta.profiilit[asiakkaanTilikartta.profiili]
      if (!profiili) { throw new Error('Profiilia ' + asiakkaanTilikartta.profiili + ' ei löydy päätilikartasta.') }
      return profiili
    }
    return paatilikartta.profiilit['maksuperusteinen'] ?? null
  }

  etsiTiliHierarkiasta(numero: string, kaikki: HierarkiaKirjanpitotili[]): HierarkiaKirjanpitotili | null {
    for (const hierarkia of kaikki) {
      if (hierarkia.kirjanpitotili.numero === numero) {
        return hierarkia
      }
      if (hierarkia.lapset) {
        const loytynyt = this.etsiTiliHierarkiasta(numero, hierarkia.lapset)
        if (loytynyt) {
          return loytynyt
        }
      }
    }
    return null
  }

  /** Palauttaa tilin 1777 ja kaikki lapset */
  annaSelvitettavatOstotTilit(kaikki: HierarkiaKirjanpitotili[]): Kirjanpitotili[] {
    const flatatty: Kirjanpitotili[] = []
    if (kaikki) {
      const selvitettavatOstot = this.etsiTiliHierarkiasta('1777', kaikki)
      if (selvitettavatOstot) {
        this.flattaa(selvitettavatOstot, flatatty)
      }
    }
    return flatatty
  }

  /** Palauttaa tilin 1755 ja kaikki lapset */
  annaSaamisetOsakkailtaJaOmaisiltaTilit(kaikki: HierarkiaKirjanpitotili[]): Kirjanpitotili[] {
    const flatatty: Kirjanpitotili[] = []
    if (kaikki) {
      const saamisetOsakkailtaJaOmaisilta = this.etsiTiliHierarkiasta('1755', kaikki)
      if (saamisetOsakkailtaJaOmaisilta) {
        this.flattaa(saamisetOsakkailtaJaOmaisilta, flatatty)
      }
    }
    return flatatty
  }

  /** Palauttaa tilin 3099 ja kaikki lapset */
  annaSelvitettavatMyynnitTilit(kaikki: HierarkiaKirjanpitotili[]): Kirjanpitotili[] {
    const flatatty: Kirjanpitotili[] = []
    if (kaikki) {
      const selvitettavatMyynnit = this.etsiTiliHierarkiasta('3099', kaikki)
      if (selvitettavatMyynnit) {
        this.flattaa(selvitettavatMyynnit, flatatty)
      }
    }
    return flatatty
  }

  /** Palauttaa tilin 190 kaikki lapset */
  annaRahaJaPankkisaatavatTilit(kaikki: HierarkiaKirjanpitotili[]): Kirjanpitotili[] {
    const flatatty: Kirjanpitotili[] = []
    if (kaikki) {
      const rahaJaPankkisaamistili = this.etsiTiliHierarkiasta('190', kaikki)
      if (rahaJaPankkisaamistili?.lapset) {
        for (const hierarkiatili of rahaJaPankkisaamistili?.lapset) {
          this.flattaa(hierarkiatili, flatatty)
        }
      }
    }
    return flatatty
  }

  /** 40-99 */
  annaKulutilitMap(kaikki: HierarkiaKirjanpitotili[]): Map<string, Kirjanpitotili> {
    if (!kaikki) { return new Map() }
    const flatatty: Kirjanpitotili[] = []

    const start = 40
    const end = 99

    for (let i = start; i <= end; i++) {
      const tili = this.etsiTiliHierarkiasta('' + i, kaikki)
      if (!tili) { continue }
      this.flattaa(tili, flatatty)

      for (const hierarkiatili of (tili?.lapset || [])) {
        this.flattaa(hierarkiatili, flatatty)
      }
    }

    const output: Map<string, Kirjanpitotili> = new Map()

    for (const tili of flatatty) {
      output.set(tili.numero, tili)
    }

    return output
  }

  flattaa(hierarkiaTili: HierarkiaKirjanpitotili, flatatyt: Kirjanpitotili[]) {
    flatatyt.push(hierarkiaTili.kirjanpitotili)
    if (hierarkiaTili.lapset) {
      for (const h of hierarkiaTili.lapset) {
        this.flattaa(h, flatatyt)
      }
    }
  }

}
