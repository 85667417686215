<div style="display: flex; align-items: baseline;">
  <mat-form-field style="width: 300px; margin-right: 30px;">
    <mat-label>Profiili</mat-label>
    <mat-select [(value)]="valittuProfiiliAvainSignal">
      <mat-option [value]="null">
        Ei profiilia
      </mat-option>
      <mat-option *ngFor="let profiili of profiilitSignal()" [value]="profiili.avain">
        {{ profiili.nimi }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div *ngIf="valittuProfiiliPuuttuuSignal() let errorText" class="error-text">{{ errorText }}</div>
</div>
<div *ngIf="commonErrorSignal() let errorText" class="error-text">{{ errorText }}</div>

<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
<div class="example-list" [class.hidden]="loading">
  <div (click)="muokkaa(node.kirjanpitotili, node.profiilinOsa)" class="example-box" *ngFor="let node of flatatytSignal()" [style.padding-left]="(node.level * 15) + 'px'">
    {{node.name}} <span style="color: gray;" *ngIf="node.profiilissaMuutetutTiedot">{{node.profiilissaMuutetutTiedot}}</span>
  </div>
</div>