import { Component, OnInit, ErrorHandler, ViewChild, ElementRef } from '@angular/core'
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, ValidatorFn, ValidationErrors, FormControl, FormGroup } from '@angular/forms'

import { MatSnackBar } from '@angular/material/snack-bar'

import { FirebaseLemonator, FirebaseLemonaid } from 'app/_angular/service/firebase-lemonator.service'

import { AsiakasService } from '../../_angular/service/asiakas/asiakas.service'
import { KirjanpitoService } from 'app/_angular/service/kirjanpito/kirjanpito.service'

import { DragAndDropService } from '../../_jaettu-angular/service/drag-and-drop.service'
import { TiedostojenLataamisService } from '../../_jaettu-angular/service/tiedostojen-lataamis.service'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'

import { HolviImportVastaus, HolviImportFromTempFilePyynto, KirjauksienLuontityyppi } from 'app/_jaettu-lemonator/model/kirjanpito'
import { AsiakkaanMaksutapa, Asiakas } from '../../_jaettu-lemonator/model/asiakas'

import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop'

import { takeUntil, map, switchMap } from 'rxjs/operators'
import { Subject, Observable, firstValueFrom, of as observableOf, combineLatest } from 'rxjs'
import { DateService } from 'app/_shared-core/service/date.service'
import { HolviSharedUriService } from 'app/_jaettu-lemonator/service/holvi-shared-uri.service'
import { HolviApiManualFetchLog, HolviStartTransactionFetchTyojono } from '../../_jaettu-sans-lemonaid-angular/model/holvi-backend'
import { KirjanpitajaService } from 'app/_angular/service/kirjanpitaja/kirjanpitaja.service'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { SahkoisenTiliotteenAlatyyppi } from 'app/_jaettu/model/tiliote'

interface DisplayedHolviApiManualFetchLog extends Pick<HolviApiManualFetchLog, 'created' | 'result' | 'extraInfo'> {
  kirjanpitajanNimi: string
}

@Component({
  templateUrl: './holvi-import.component.html',
  styleUrls: ['./holvi-import.component.css'],
})
export class HolviImportComponent implements OnInit {

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef

  apiLoadRunning: boolean = false
  apiLoadWasRunning: boolean = false
  loading: boolean = false
  isDragging: boolean = false
  maksutavatLoaded: boolean = false
  maksutavatForm: FormGroup<{ valittu: FormControl<AsiakkaanMaksutapa> }>
  holviApiForm: UntypedFormGroup
  holviTransactionSyncForm: UntypedFormGroup

  valittuMaksutapaFromSelect: AsiakkaanMaksutapa
  latausvirhe = ''
  holviMaksutavatObservable: Observable<AsiakkaanMaksutapa[]>
  asiakasObservable: Observable<Asiakas>
  donePercentageObservable: Observable<number>
  kasittelemattomiaObservable: Observable<number>
  driverObservable: Observable<boolean>

  tiedostoaLadataan: boolean = false
  tiedostoaKasitellaan: boolean = false

  displayedFetchLogsObservable: Observable<DisplayedHolviApiManualFetchLog[]>
  fetchError: string

  holviApiMaksutapaActiveObservable: Observable<boolean>

  holviApiLoadRunningObservable: Observable<{ running: boolean, transactionsLeft: number, holviTransactionsLeft: number }>

  private _ngUnsubscribe = new Subject<void>()

  constructor(
    private _errorHandler: ErrorHandler,
    private _dndService: DragAndDropService,
    private _asiakasService: AsiakasService,
    private _tiedostojenLataamisService: TiedostojenLataamisService,
    private _firebase: FirebaseLemonator,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _formValidationService: FormValidationService,
    private _kirjanpitoService: KirjanpitoService,
    private _ladataanService: LadataanService,
    private _snackbar: MatSnackBar,
    private _dateService: DateService,
    private _holviSharedUriService: HolviSharedUriService,
    private _kirjanpitajaService: KirjanpitajaService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService
  ) { }

  ngOnInit() {

    this.asiakasObservable = this._asiakasService.nykyinenAsiakasObservable

    this.maksutavatForm = new UntypedFormGroup({
      valittu: new UntypedFormControl('', [Validators.required])
    })

    const valittuFormControl = new FormControl<AsiakkaanMaksutapa>(null, [Validators.required])
    this.maksutavatForm = new FormGroup<{ valittu: FormControl<AsiakkaanMaksutapa> }>({
      valittu: valittuFormControl
    })

    this.holviApiForm = new UntypedFormGroup({
      holviApiStartDate: new UntypedFormControl(null, [Validators.required, this._holviApiStartValidator(valittuFormControl)])
    })

    this.holviTransactionSyncForm = new UntypedFormGroup({
      start: new UntypedFormControl(null, [Validators.required, this._endBeforeStartValidator]),
      end: new UntypedFormControl(new Date(), [Validators.required, this._endBeforeStartValidator])
    })

    this.maksutavatForm.get('valittu').valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(valittuMaksutapa => {
      this.valittuMaksutapaFromSelect = valittuMaksutapa
    })

    this._dndService.isDraggingInWindowObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(dragging => {
      this.isDragging = dragging
    })

    this.holviMaksutavatObservable = this._asiakasService.nykyisenAsiakkaanJaKayttajienRoolienJaLaskunMaksutavatObservable.pipe(
      map(maksutavat => {
        if (maksutavat) {
          return maksutavat.filter(m => m.kirjauksienLuontityyppi === KirjauksienLuontityyppi.HOLVI_SAHKOINEN_TILIOTE ||
            (m.kirjauksienLuontityyppi === KirjauksienLuontityyppi.SAHKOINEN_TILIOTE && m.sahkoisenTiliotteenAlatyyppi === SahkoisenTiliotteenAlatyyppi.HOLVI_API))
        }
        return null
      })
    )

    this.holviApiMaksutapaActiveObservable = this.holviMaksutavatObservable.pipe(
      map(holviMaksutavat => {
        return holviMaksutavat.findIndex(mt => mt.sahkoisenTiliotteenAlatyyppi === SahkoisenTiliotteenAlatyyppi.HOLVI_API && mt.aktiivinen) > -1
      })
    )

    this.holviMaksutavatObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(maksutavat => {
      this.maksutavatLoaded = true
      if (maksutavat && !this.maksutavatForm.get('valittu').value) {
        const newMaksutapa = maksutavat.filter(m => m.kirjauksienLuontityyppi === KirjauksienLuontityyppi.SAHKOINEN_TILIOTE && m.sahkoisenTiliotteenAlatyyppi === SahkoisenTiliotteenAlatyyppi.HOLVI_API)
        if (newMaksutapa.length > 0) {
          this.maksutavatForm.get('valittu').setValue(newMaksutapa[0])
        } else {
          const oldMaksutavat = maksutavat.filter(m => m.kirjauksienLuontityyppi === KirjauksienLuontityyppi.HOLVI_SAHKOINEN_TILIOTE)
          if (oldMaksutavat.length > 0) {
            this.maksutavatForm.get('valittu').setValue(oldMaksutavat[0])
          }
        }
      }
    })

    this.kasittelemattomiaObservable = this._kirjanpitoService.holviZipKasittelemattaCountObservable
    this.driverObservable = this._kirjanpitoService.holviZipDriverExistsObservable

    this.holviApiLoadRunningObservable = combineLatest([this._kirjanpitoService.tilitapahtumiaKasittelemattaCountObservable, this._kirjanpitoService.holviApiKasittelemattaCountObservable, this._kirjanpitoService.holviApiDriverExistsObservable]).pipe(
      map(([transactions, holviTransactions, holviRunning]) => {
        const running = holviRunning || transactions > 0 || holviTransactions > 0
        if (!running && this.apiLoadWasRunning) {
          this.apiLoadRunning = false
          this.apiLoadWasRunning = false
        } else if (holviRunning || transactions > 0) {
          this.apiLoadRunning = true
          this.apiLoadWasRunning = true
        }
        return { running: running, transactionsLeft: transactions, holviTransactionsLeft: holviTransactions }
      })
    )

    const fetchLogsObservable = this.asiakasObservable.pipe(
      switchMap(asiakas => {
        if (!asiakas) {
          return observableOf<HolviApiManualFetchLog[]>([])
        }
        return this._firebase.firestoreCollection<HolviApiManualFetchLog>(this._holviSharedUriService.getManualTransactionFetchLogCollection(asiakas.avain)).listen().pipe(
          map(logs => {
            return logs.sort((a, b) => b.created.toMillis() - a.created.toMillis())
          })
        )
      })
    )

    this.displayedFetchLogsObservable = combineLatest([fetchLogsObservable, this._kirjanpitajaService.kirjanpitajienNimitiedotMapObservable]).pipe(
      map(([logs, kirjanpitajatMap]) => {
        if (!logs?.length || !kirjanpitajatMap) {
          return []
        }
        const displayedLogs: DisplayedHolviApiManualFetchLog[] = []
        for (const log of logs) {
          const kirjanpitaja = kirjanpitajatMap.get(log.createdBy)
          displayedLogs.push({
            kirjanpitajanNimi: kirjanpitaja ? kirjanpitaja.etunimi + ' ' + kirjanpitaja.sukunimi : log.createdBy,
            created: log.created,
            result: log.result,
            extraInfo: log.extraInfo
          })
        }

        return displayedLogs
      })
    )
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  public get valittuFormControl(): AbstractControl {
    return this.maksutavatForm.get('valittu')
  }

  public fileOver() {
    this._dndService.setDragging(true)
  }

  public fileLeave() {
    this._dndService.setDragging(true)
  }

  // Load start when drag 'n dropped
  public async fileDrop(entries: NgxFileDropEntry[]): Promise<void> {
    this._dndService.setDragging(false)
    this._lataaTiedosto(entries)
  }

  // Load start when clicked
  public lataa(event) {
    if (event.target.files) {
      const list: FileList = event.target.files
      const tiedostot: NgxFileDropEntry[] = this._tiedostojenLataamisService.fileListToNgxFileDropEntries(list)
      this._lataaTiedosto(tiedostot)
    }
  }

  public async startHolviTransactionSync() {

    this.fetchError = null

    if (!this.holviTransactionSyncForm.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.holviTransactionSyncForm)
      return
    }

    this.apiLoadRunning = true
    this._ladataanService.aloitaLataaminen()

    try {
      const asiakasPromise = firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
      const kirjanpitajanTiedotPromise = this._kirjautunutKayttajaService.getKirjanpitajanTiedot()

      const [asiakas, kirjanpitajanTiedot] = await Promise.all([asiakasPromise, kirjanpitajanTiedotPromise])

      const tyojonoUri = this._holviSharedUriService.startHolviTransactionFetchTyojonoUri(asiakas.avain, this._firebase.firestoreCreateId())

      const startDate = this.holviTransactionSyncForm.get('start').value as Date
      const endDate = this.holviTransactionSyncForm.get('end').value as Date

      const tyojono: HolviStartTransactionFetchTyojono = {
        aloitettu: this._firebase.firestoreTimestampFromDate(new Date()),
        asiakasAvain: asiakas.avain,
        start: this._dateService.dateToNumber(startDate),
        end: this._dateService.dateToNumber(endDate),
        createdByAccountant: kirjanpitajanTiedot.uid
      }
      await this._firebase.firestoreSetData(tyojonoUri, tyojono)
    } catch (err: any) {
      this.apiLoadRunning = false
      this._errorHandler.handleError(err)
      this.fetchError = 'Holvi-tilitapahtumien lataamisen aloittaminen epäonnistui!'
    } finally {
      this._ladataanService.lopetaLataaminen()
    }

  }

  private async _lataaTiedosto(tiedostot: NgxFileDropEntry[]): Promise<any> {

    const voidaanLadata = await this.tiedostoVoidaanLadata(tiedostot)
    if (!voidaanLadata) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.maksutavatForm)
      return
    }

    const asiakas = await firstValueFrom(this.asiakasObservable)

    this.tiedostoaLadataan = true

    const tiedosto = tiedostot[0]
    const tiedostonAvain = this._firebase.firestoreCreateId()
    const file = await this._tiedostojenLataamisService.getFile(tiedosto.fileEntry as FileSystemFileEntry)

    const uploadUri = 'holvi_import/' + asiakas.avain + '/' + tiedostonAvain
    const uploadStatus = this._tiedostojenLataamisService.upload(this._firebase, uploadUri, file, 'holvi-temp-eu')

    this.donePercentageObservable = uploadStatus.donePercentage

    return uploadStatus.doneObservable.subscribe({
      next: done => {
        if (!done) {
          return
        }

        this.tiedostoaKasitellaan = true
        const maksutapa = this.valittuMaksutapaFromSelect
        const pyynto: HolviImportFromTempFilePyynto = {
          asiakasAvain: asiakas.avain,
          maksutapa: maksutapa.tunniste,
          asiakasId: asiakas.asiakasId + '',
          tiedostonAvain: tiedostonAvain
        }

        return this._firebase.functionsCall<HolviImportFromTempFilePyynto, HolviImportVastaus>('kirjanpitoHolviDistributeWorkFromZip', pyynto, { timeout: 540 * 1000 }).then(omatulos => {
          if (!omatulos.onnistui) {
            this.latausvirhe = 'Tiedoston lataamisessa oli virhe. Ota yhteys ylläpitoon. 1'
          } else {
            this._snackbar.open('Lataus onnistui', 'OK', { duration: 5000, verticalPosition: 'top' })
          }
          const htmlInput = this.fileInput.nativeElement as HTMLInputElement
          htmlInput.value = ''
          this.donePercentageObservable = null
          this.tiedostoaKasitellaan = false
          this.tiedostoaLadataan = false
        }).catch(error => {
          this.latausvirhe = 'Tiedoston lataamisessa oli virhe. Ota yhteys ylläpitoon. 2'
          this._errorHandler.handleError(error)
          this.donePercentageObservable = null
          this.tiedostoaKasitellaan = false
          this.tiedostoaLadataan = false
          const htmlInput = this.fileInput.nativeElement as HTMLInputElement
          htmlInput.value = ''
        })
      },
      error: error => {
        this.latausvirhe = 'Tiedoston lataamisessa oli virhe. Ota yhteys ylläpitoon. 3'
        this._errorHandler.handleError(error)
        this.donePercentageObservable = null
        this.tiedostoaKasitellaan = false
        this.tiedostoaLadataan = false
        const htmlInput = this.fileInput.nativeElement as HTMLInputElement
        htmlInput.value = ''
      }
    })

  }

  private async tiedostoVoidaanLadata(tiedostot: NgxFileDropEntry[]): Promise<boolean> {
    this.latausvirhe = ''
    if (!this.maksutavatForm.valid) {
      return false
    }
    if (tiedostot.length < 1) {
      this.latausvirhe = 'Annettu syöte ei ole kelvollinen tiedosto.'
      return false
    }

    if (tiedostot.length > 1) {
      this.latausvirhe = 'Voit ladata vain yhden tiedoston kerrallaan.'
      return false
    }

    const supportedFileTypes = ['zip']
    for (const tiedosto of tiedostot) {

      const file = tiedosto.fileEntry as FileSystemFileEntry

      const fileEnding = this._tiedostojenLataamisService.getFileEndingFromFileName(file.name)
      // const fileSize = file ? await this._tiedostojenLataamisService.getFileSize(file) : -1

      if (!fileEnding || supportedFileTypes.indexOf(fileEnding.toLowerCase()) < 0) {
        this.latausvirhe = 'Tiedoston tyyppi ei ole tuettu. Tuetut tyypit ovat: ' + supportedFileTypes.join(', ') + '.'
        return false
      }

      // const maxKoko = 10 * 1024 * 1024
      // if (fileSize > maxKoko) {
      //   const kokoMegatavuissaLokalisoitu = this._currencyService.formatoiDesimaali((fileSize / 1024 / 1024), 2, this._translationService.nykyinenKieli)
      //   const maxKokoLokalisoitu = this._currencyService.formatoiDesimaali((maxKoko / 1024 / 1024), 2, this._translationService.nykyinenKieli)
      //   this.latausvirhe = 'Ladattava tiedosto voi olla maksimissaan ' + maxKokoLokalisoitu + ' megatavua. (Tiedoston koko oli nyt ' + kokoMegatavuissaLokalisoitu + ' megatavua.)'
      //   return false
      // }
    }

    return true

  }

  async restart() {
    this._ladataanService.aloitaLataaminen()

    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)

    const pyynto: { asiakasId: number, asiakasAvain: string } = {
      asiakasAvain: asiakas.avain,
      asiakasId: asiakas.asiakasId
    }
    return this._firebase.functionsCall<{ asiakasId: number, asiakasAvain: string }, void>('kirjanpitoRestartDistributeHolviImportWork', pyynto, { timeout: 540 * 1000 }).then(() => {
      this._ladataanService.lopetaLataaminen()
    }).catch(error => {
      this._errorHandler.handleError(error)
      this._ladataanService.lopetaLataaminen()
    })
  }

  private _endBeforeStartValidator: ValidatorFn = (control: AbstractControl): ValidationErrors => {
    const start = this.holviTransactionSyncForm?.get('start')?.value
    const end = this.holviTransactionSyncForm?.get('end')?.value

    if (!start && !end) {
      return null
    }

    if (start > end) {
      return { 'endBeforeStart': true }
    }
    return null
  }

  private _holviApiStartValidator(maksutavatFormControl: FormControl<AsiakkaanMaksutapa>): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value || !maksutavatFormControl.value) {
        return null
      }

      const maksutapaStart = maksutavatFormControl.value.tilitapahtumatSynkronoidaanAlkaen
      const valittuLocal = this._dateService.dateToLocalDate(control.value)
      if (maksutapaStart && this._dateService.compareLocalDates(valittuLocal, '>', maksutapaStart)) {
        return { 'startBeforeMaksutapa': true }
      }

      return null
    }
  }
}
