import { Component, OnInit, ErrorHandler } from '@angular/core'

import { AsiakasService } from '../../_angular/service/asiakas/asiakas.service'

import { Laskuasetukset } from '../../_jaettu/model/lasku'

import { LadataanService } from 'app/_jaettu-angular/service/ladataan.service'
import { SafeUrl, DomSanitizer } from '@angular/platform-browser'

import { firstValueFrom, Observable, of } from 'rxjs'
import { tap, switchMap, map, startWith } from 'rxjs/operators'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonator.service'
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator'
import { LaskuUriService } from 'app/_jaettu/service/lasku/lasku-uri.service'


@Component({
  templateUrl: './lemonaid-laskuasetukset.component.html',
  styleUrls: ['./lemonaid-laskuasetukset.component.css']
})
export class AsiakkaanLemonaidLaskuasetuksetComponent implements OnInit {

  asetuksetObservable: Observable<Laskuasetukset>
  logoObservable: Observable<SafeUrl>
  ladattu: boolean = false

  constructor(
    private _errorHandler: ErrorHandler,
    private _asiakasService: AsiakasService,
    private _ladataanService: LadataanService,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _sanitizer: DomSanitizer,
    private _laskuUriService: LaskuUriService
  ) {

  }

  ngOnInit() {

    this.asetuksetObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      tap(() => {
        this.ladattu = false
      }),
      switchMap(asiakas => {
        if (asiakas) {
          const asetuksetUri = this._laskuUriService.getLaskuasetuksetUri(asiakas.avain, asiakas.avain)
          return this._firebaseLemonaid.firestoreDoc<Laskuasetukset>(asetuksetUri).listen()
        }
        return of<Laskuasetukset>(null)
      }),
      tap(() => {
        this.ladattu = true
      }),
      lemonShare()
    )

    this.logoObservable = this.asetuksetObservable.pipe(
      map(asetukset => {
        if (asetukset && asetukset.base64Logo) {
          return this._sanitizer.bypassSecurityTrustUrl('data:;base64,' + asetukset.base64Logo)
        }
        return '/assets/noimage.png'
      }),
      startWith('/assets/noimage.png'),
      lemonShare()
    )

  }

  async asetaTervetuloaDialoginNakyvyys() {
    this._ladataanService.aloitaLataaminen()
    const asiakas = await firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)

    const asetuksetUri = this._laskuUriService.getLaskuasetuksetUri(asiakas.avain, asiakas.avain)
    const doc = this._firebaseLemonaid.firestoreDocRef<Laskuasetukset>(asetuksetUri)
    this._firebaseLemonaid.firestoreRunTransaction(t => {
      return t.get<Laskuasetukset, Laskuasetukset>(doc).then(snapshot => {
        if (snapshot.exists) {
          const asetukset = snapshot.data()
          if (asetukset) {
            t.update(doc, { tervetuloaNahty: !asetukset.tervetuloaNahty })
          }
        }
      })
    }).then(() => {
      this._ladataanService.lopetaLataaminen()
    }).catch(error => {
      this._errorHandler.handleError(error)
    })

  }

}
