import { Component, computed, ErrorHandler, signal, model, WritableSignal, ModelSignal, Signal } from '@angular/core'
import { toObservable, toSignal } from '@angular/core/rxjs-interop'

import { MatDialog } from '@angular/material/dialog'

import { BehaviorSubject, Observable, combineLatest } from 'rxjs'
import { map, take, tap } from 'rxjs/operators'

import { TilikarttaService } from 'app/_angular/service/tilikartta.service'
import { AsiakkaanTilikartta, Kirjanpitotili, PaatilikartanProfiili, PaatilikartanProfiilinOsa, Paatilikartta } from 'app/_jaettu-lemonator/model/kirjanpito'
import { HierarkiaKirjanpitotili, TilikarttaJaettuService } from 'app/_jaettu-lemonator/service/tilikartta-jaettu.service'
import { KirjanpitotiliProfiiliMuokkaaDialog, KirjanpitotiliProfiiliMuokkaaDialogData } from './profiili-kirjanpitotili-muokkaa.dialog'

/** Flat node with expandable and level information */
interface FlatKirjanpitotili {
  expandable: boolean
  name: string
  level: number
  kirjanpitotili: Kirjanpitotili
  profiilinOsa: PaatilikartanProfiilinOsa
  kirjanpitotiliAlkuperainen: Kirjanpitotili
  profiilissaMuutetutTiedot: string
}

@Component({
  selector: '[app-profiili-paatilikartta]',
  templateUrl: './profiili-paatilikartta.component.html',
  styleUrls: ['./profiili-paatilikartta.component.css']
})
export class PaatilikarttaProfiiliComponent {

  loading = true
  flatatyt: Observable<FlatKirjanpitotili[]>
  flatatytSignal: Signal<FlatKirjanpitotili[]>


  Subject = new BehaviorSubject<PaatilikartanProfiili>(null)

  commonErrorSignal: WritableSignal<string> = signal('')
  valittuProfiiliPuuttuuSignal: Signal<string> = signal('')
  valittuProfiiliAvainSignal: ModelSignal<string> = model(null)
  profiilitSignal: Signal<PaatilikartanProfiili[]> = signal([])
  private _valittuProfiiliSignal: Signal<PaatilikartanProfiili> = signal(null)

  constructor(
    private errorHandler: ErrorHandler,
    private dialog: MatDialog,
    private tilikarttaService: TilikarttaService,
    private tilikarttaJaettuService: TilikarttaJaettuService
  ) {

    this.valittuProfiiliPuuttuuSignal = computed(() => this.valittuProfiiliAvainSignal() ? '' : 'Valitse profiili')

    const paatilikarttaSignal = toSignal(this.tilikarttaService.paatilikarttaObservable)
    this.profiilitSignal = computed(() => Object.values(paatilikarttaSignal()?.profiilit ?? {}))
    this._valittuProfiiliSignal = computed(() => this._annaPaatilikartanProfiili(this.valittuProfiiliAvainSignal(), paatilikarttaSignal()))

    this.flatatytSignal = computed(() => {
      const profiiliAvain = this.valittuProfiiliAvainSignal()
      const paatilikartta = paatilikarttaSignal()

      const profiili = this._annaPaatilikartanProfiili(profiiliAvain, paatilikartta)

      // Jos profiili on valittu, yhdistä ne muutokset päätilikarttaan.
      // Tämä onnistuu tyhjällä asiakaskohtaisella tilikartalla, johon
      // merkitään valittu profiili.
      const alkuperaisetTilit = paatilikartta?.tilit ? Object.keys(paatilikartta.tilit).map(key => paatilikartta.tilit[key]) : []
      let tilit: Kirjanpitotili[] = null
      if (profiili?.avain) {
        const asiakkaanTilikartta: AsiakkaanTilikartta = {
          tilit: {},
          yliajotilit: {},
          profiili: profiili.avain
        }
        tilit = this.tilikarttaJaettuService.yhdistaAsiakkaantilikarttaPaatilikarttaan(paatilikartta, asiakkaanTilikartta)
      } else {
        tilit = alkuperaisetTilit
      }

      // Make hierarchy flat
      const hierarkia = this.tilikarttaJaettuService.muutaTililistausHierarkiaksi(tilit)
      const kaikki: FlatKirjanpitotili[] = []
      for (const juuri of hierarkia) {
        this.flattaa(juuri, 1, kaikki)
      }

      // Populoi profiilin osat
      if (profiili?.t) {
        for (const t of kaikki) {
          if (profiili.t[t.kirjanpitotili.numero]) {
            t.profiilinOsa = profiili.t[t.kirjanpitotili.numero]
            let lisaa = false
            let explanation = 'Profiilissa '
            if (t.profiilinOsa.nimi?.fi) {
              if (lisaa) { explanation += ',' }
              explanation += t.profiilinOsa.nimi.fi
              lisaa = true
            }
            if (t.profiilinOsa.nimi?.en) {
              if (lisaa) { explanation += ',' }
              explanation += t.profiilinOsa.nimi.en
              lisaa = true
            }
            if (t.profiilinOsa.piilota) {
              if (lisaa) { explanation += ',' }
              explanation += ' piilotettu'
              lisaa = true
            }
            if (lisaa) {
              t.profiilissaMuutetutTiedot = explanation
            }
          }
        }
      }

      // Täytä alkuperäiset tilit
      for (const alk of alkuperaisetTilit) {
        for (const mod of kaikki) {
          if (alk.numero === mod.kirjanpitotili.numero) {
            mod.kirjanpitotiliAlkuperainen = alk
            break
          }
        }
      }

      this.loading = false

      return kaikki

    })

    this.flatatyt = combineLatest([
      toObservable(this.valittuProfiiliAvainSignal),
      this.tilikarttaService.paatilikarttaObservable
    ]).pipe(
      map(([profiiliAvain, paatilikartta]) => {

        const profiili = this._annaPaatilikartanProfiili(profiiliAvain, paatilikartta)

        // Jos profiili on valittu, yhdistä ne muutokset päätilikarttaan.
        // Tämä onnistuu tyhjällä asiakaskohtaisella tilikartalla, johon
        // merkitään valittu profiili.
        const alkuperaisetTilit = paatilikartta?.tilit ? Object.keys(paatilikartta.tilit).map(key => paatilikartta.tilit[key]) : []
        let tilit: Kirjanpitotili[] = null
        if (profiili?.avain) {
          const asiakkaanTilikartta: AsiakkaanTilikartta = {
            tilit: {},
            yliajotilit: {},
            profiili: profiili.avain
          }
          tilit = this.tilikarttaJaettuService.yhdistaAsiakkaantilikarttaPaatilikarttaan(paatilikartta, asiakkaanTilikartta)
        } else {
          tilit = alkuperaisetTilit
        }

        // Make hierarchy flat
        const hierarkia = this.tilikarttaJaettuService.muutaTililistausHierarkiaksi(tilit)
        const kaikki: FlatKirjanpitotili[] = []
        for (const juuri of hierarkia) {
          this.flattaa(juuri, 1, kaikki)
        }

        // Populoi profiilin osat
        if (profiili?.t) {
          for (const t of kaikki) {
            if (profiili.t[t.kirjanpitotili.numero]) {
              t.profiilinOsa = profiili.t[t.kirjanpitotili.numero]
              let lisaa = false
              let explanation = 'Profiilissa'
              if (t.profiilinOsa.nimi?.fi) {
                if (lisaa) { explanation += ',' }
                explanation += ' nimi-fi: ' + t.profiilinOsa.nimi.fi
                lisaa = true
              }
              if (t.profiilinOsa.nimi?.en) {
                if (lisaa) { explanation += ',' }
                explanation += ' nimi-en ' + t.profiilinOsa.nimi.en
                lisaa = true
              }
              if (t.profiilinOsa.piilota) {
                if (lisaa) { explanation += ',' }
                explanation += ' piilotettu: ' + t.profiilinOsa.piilota
                lisaa = true
              }
            }
          }
        }

        // Täytä alkuperäiset tilit
        for (const alk of alkuperaisetTilit) {
          for (const mod of kaikki) {
            if (alk.numero === mod.kirjanpitotili.numero) {
              mod.kirjanpitotiliAlkuperainen = alk
              break
            }
          }
        }

        return kaikki
      }),
      tap(() => {
        this.loading = false
      })
    )

  }

  private _annaPaatilikartanProfiili(profiilinAvain: string, paatilikartta: Paatilikartta): PaatilikartanProfiili | null {
    if (
      profiilinAvain &&
      paatilikartta &&
      paatilikartta.profiilit &&
      paatilikartta.profiilit[profiilinAvain]
    ) {
      return paatilikartta.profiilit[profiilinAvain]
    }
    return null
  }

  compareProfiles(o1: PaatilikartanProfiili, o2: PaatilikartanProfiili): boolean {
    if (o1 && o2) {
      // console.log(o1, o2)
      return o1.avain === o2.avain
    }
    if (o1 === null && o2 === null) {
      return true
    }
    return false
  }

  private flattaa(hierarkia: HierarkiaKirjanpitotili, level: number, kaikki: FlatKirjanpitotili[]) {
    kaikki.push(this.makeFlat(hierarkia, level))
    if (hierarkia.lapset) {
      for (const lapsi of hierarkia.lapset) {
        this.flattaa(lapsi, level + 1, kaikki)
      }
    }
  }

  private makeFlat(node: HierarkiaKirjanpitotili, level: number): FlatKirjanpitotili {
    return {
      expandable: !!node.lapset && node.lapset.length > 0,
      name: node.kirjanpitotili.numero + ' ' + node.kirjanpitotili.nimi,
      level: level,
      kirjanpitotili: node.kirjanpitotili,
      kirjanpitotiliAlkuperainen: null,
      profiilinOsa: null,
      profiilissaMuutetutTiedot: null
    }
  }

  muokkaa(tili: Kirjanpitotili, profiilinOsa: PaatilikartanProfiilinOsa) {
    const profiili = this._valittuProfiiliSignal()
    if (!profiili) {
      return
    }
    const data: KirjanpitotiliProfiiliMuokkaaDialogData = {
      tili: tili,
      profiilinOsa: profiilinOsa,
      profiili: profiili
    }
    this.dialog.open(KirjanpitotiliProfiiliMuokkaaDialog, { data: data, autoFocus: false })
  }

}
