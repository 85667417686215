import { FirebaseLemonator } from './firebase-lemonator.service'

import { Observable, of, combineLatest } from 'rxjs'

import { Paatilikartta, Kirjanpitotili, AsiakkaanTilikartta, PaatilikartanProfiilinOsa, PaatilikartanProfiili } from 'app/_jaettu-lemonator/model/kirjanpito'
import { TilikarttaJaettuService, HierarkiaKirjanpitotili } from 'app/_jaettu-lemonator/service/tilikartta-jaettu.service'

import { KirjautunutKayttajaService } from './kirjautunut-kayttaja.service'
import { AsiakasService, AsiakkaanAvainTiedot } from './asiakas/asiakas.service'

import { map, switchMap } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { KirjanpitoUriService } from 'app/_jaettu-lemonator/service/kirjanpito-uri.service'

@Injectable()
export class TilikarttaService {

  nykyisenAsiakkaanTilikarttaObservable: Observable<AsiakkaanTilikartta>
  // nykyisenAsiakkaanTilikartanTilitObservable: Observable<AsiakkaanTilikarttaJaPuretutTilit>
  nykyisenAsiakkaanTilikartanJaPaatilikartanTilitObservable: Observable<Kirjanpitotili[]>
  nykyisenAsiakkaanTilikartanTilihierarkiaObservable: Observable<HierarkiaKirjanpitotili[]>

  paatilikarttaObservable: Observable<Paatilikartta>
  paatilikartanTilitObservable: Observable<Kirjanpitotili[]>
  paatilikartanTilihierarkiaObservable: Observable<HierarkiaKirjanpitotili[]>

  nykyisenAsiakkaanSelvitettavatOstotTilitObservable: Observable<Kirjanpitotili[]>
  nykyisenAsiakkaanSelvitettavatMyynnitTilitObservable: Observable<Kirjanpitotili[]>


  constructor(
    private _firebase: FirebaseLemonator,
    private kayttajaService: KirjautunutKayttajaService,
    private asiakasService: AsiakasService,
    private tilikarttaJaettuService: TilikarttaJaettuService,
    private _kirjanpitoUriService: KirjanpitoUriService
  ) {

    this.nykyisenAsiakkaanTilikarttaObservable = this.asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (asiakas) {
          const uri = this._kirjanpitoUriService.annaAsiakkaanTilikartanUri(asiakas.avain)
          return this._firebase.firestoreDoc<AsiakkaanTilikartta>(uri).listen()
        }
        return of<AsiakkaanTilikartta>(null)
      })
    )
    // this.nykyisenAsiakkaanTilikartanTilitObservable = this.nykyisenAsiakkaanTilikarttaObservable.pipe(
    //   map(tilikartta => {
    //     return {
    //       tilikartta: tilikartta,
    //       tilit: tilikartta && tilikartta.tilit ? Object.keys(tilikartta.tilit).map(key => tilikartta.tilit[key]) : []
    //     }
    //   }),
    //   lemonShare()
    // )

    this.paatilikarttaObservable = this.kayttajaService.kirjanpitajanTiedotObservable.pipe(
      switchMap(kayttaja => {
        if (kayttaja) {
          return this._firebase.firestoreDoc<Paatilikartta>('admin/paatilikartta').listen()
        }
        return of<Paatilikartta>(null)
      })
    )
    this.paatilikartanTilitObservable = this.paatilikarttaObservable.pipe(
      map(tilikartta => {
        return tilikartta && tilikartta.tilit ? Object.keys(tilikartta.tilit).map(key => tilikartta.tilit[key]) : []
      })
    )

    this.nykyisenAsiakkaanTilikartanJaPaatilikartanTilitObservable = combineLatest([this.paatilikarttaObservable, this.nykyisenAsiakkaanTilikarttaObservable]).pipe(
      map(([paatilikartta, asiakkaanTilikartta]) => {
        return this.tilikarttaJaettuService.yhdistaAsiakkaantilikarttaPaatilikarttaan(paatilikartta, asiakkaanTilikartta)
      })
    )

    this.nykyisenAsiakkaanTilikartanTilihierarkiaObservable = this.nykyisenAsiakkaanTilikartanJaPaatilikartanTilitObservable.pipe(
      map(tilit => {
        return this.tilikarttaJaettuService.muutaTililistausHierarkiaksi(tilit)
      })
    )

    this.paatilikartanTilihierarkiaObservable = this.paatilikartanTilitObservable.pipe(
      map(tilit => {
        return this.tilikarttaJaettuService.muutaTililistausHierarkiaksi(tilit)
      })
    )

    this.nykyisenAsiakkaanSelvitettavatOstotTilitObservable = this.nykyisenAsiakkaanTilikartanTilihierarkiaObservable.pipe(
      map(hierarkiat => {
        return this.tilikarttaJaettuService.annaSelvitettavatOstotTilit(hierarkiat)
      })
    )
    this.nykyisenAsiakkaanSelvitettavatMyynnitTilitObservable = this.nykyisenAsiakkaanTilikartanTilihierarkiaObservable.pipe(
      map(hierarkiat => {
        return this.tilikarttaJaettuService.annaSelvitettavatMyynnitTilit(hierarkiat)
      })
    )

  }

  tallennaPaatilikartanTili(tili: Kirjanpitotili): Promise<void> {
    const propertyPath = this._firebase.firestoreFieldPath('tilit', tili.numero)
    return this._firebase.firestoreUpdateFields('admin/paatilikartta', { data: tili, fieldPath: propertyPath })
  }

  tallennaPaatilikartanProfiilinOsa(profiili: PaatilikartanProfiili, tili: Kirjanpitotili, osa: PaatilikartanProfiilinOsa): Promise<void> {

    const docPath = 'admin/paatilikartta'

    if (!osa) {
      const propertyPath = this._firebase.firestoreFieldPath('profiilit', profiili.avain, 't', tili.numero + '')
      return this._firebase.firestoreUpdateFields(docPath, { data: this._firebase.firestoreDeleteMarker(), fieldPath: propertyPath })
    }

    const nimiFiPropertyPath = this._firebase.firestoreFieldPath('profiilit', profiili.avain, 't', tili.numero + '', 'nimi', 'fi')
    const nimiEnPropertyPath = this._firebase.firestoreFieldPath('profiilit', profiili.avain, 't', tili.numero + '', 'nimi', 'en')
    const piilotaPropertyPath = this._firebase.firestoreFieldPath('profiilit', profiili.avain, 't', tili.numero + '', 'piilota')
    return this._firebase.firestoreUpdateFields(docPath,
      { data: osa.nimi?.fi ? osa.nimi.fi : this._firebase.firestoreDeleteMarker(), fieldPath: nimiFiPropertyPath },
      { data: osa.nimi?.en ? osa.nimi.en : this._firebase.firestoreDeleteMarker(), fieldPath: nimiEnPropertyPath },
      { data: osa.piilota ? 1 : this._firebase.firestoreDeleteMarker(), fieldPath: piilotaPropertyPath }
    )

  }

  etsiTiliHierarkiasta(numero: string, kaikki: HierarkiaKirjanpitotili[]): HierarkiaKirjanpitotili | null {
    return this.tilikarttaJaettuService.etsiTiliHierarkiasta(numero, kaikki)
  }

  tallennaAsiakkaanTilikartanTili(asiakas: AsiakkaanAvainTiedot, tili: Kirjanpitotili): Promise<void> {
    const partialTilikartta: Partial<AsiakkaanTilikartta> = { tilit: {} }
    partialTilikartta.tilit[tili.numero] = tili
    const propertyPath = this._firebase.firestoreFieldPath('tilit', tili.numero)
    const documentUri = this._kirjanpitoUriService.annaAsiakkaanTilikartanUri(asiakas.avain)
    return this._firebase.firestoreSetData(documentUri, partialTilikartta, { mergeFields: [propertyPath] })
  }

  tallennaAsiakkaanPaatilikartanYliajoTili(asiakas: AsiakkaanAvainTiedot, tili: Kirjanpitotili): Promise<void> {
    const partialTilikartta: Partial<AsiakkaanTilikartta> = { yliajotilit: {} }
    partialTilikartta.yliajotilit[tili.numero] = tili
    const propertyPath = this._firebase.firestoreFieldPath('yliajotilit', tili.numero)
    const documentUri = this._kirjanpitoUriService.annaAsiakkaanTilikartanUri(asiakas.avain)
    return this._firebase.firestoreSetData<Partial<AsiakkaanTilikartta>>(documentUri, partialTilikartta, { mergeFields: [propertyPath] })
  }

  poistaAsiakkaanPaatilikartanYliajoTili(asiakas: AsiakkaanAvainTiedot, tili: Kirjanpitotili): Promise<void> {
    const partialTilikartta: Partial<AsiakkaanTilikartta> = { yliajotilit: {} }
    partialTilikartta.yliajotilit[tili.numero] = this._firebase.firestoreDeleteMarker()
    const propertyPath = this._firebase.firestoreFieldPath('yliajotilit', tili.numero)
    const documentUri = this._kirjanpitoUriService.annaAsiakkaanTilikartanUri(asiakas.avain)
    return this._firebase.firestoreSetData<Partial<AsiakkaanTilikartta>>(documentUri, partialTilikartta, { mergeFields: [propertyPath] })
  }

}
